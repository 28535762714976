import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
// import {UploadComponent} from './components/upload/upload.component';
import {SystemsComponent} from './components/systems/systems.component';
import {ListasControlWhiteComponent} from './components/listas-control-white/listas-control-white.component';
import {ListasControlBlackComponent} from './components/listas-control-black/listas-control-black.component';
import {ListasControlBLOQComponent} from './components/listas-control-bloq/listas-control-bloq.component';
import {ListasControlGRISComponent} from './components/listas-control-gris/listas-control-gris.component';
import {ListasControlOFACComponent} from './components/listas-control-ofac/listas-control-ofac.component';
import {ListasControlPEPComponent} from './components/listas-control-pep/listas-control-pep.component';
import {ListasControlPLDYFComponent} from './components/listas-control-pldyf/listas-control-pldyf.component';
import {
  ListasControlAutorizacionComponent
} from './components/listas-control-autorizacion/listas-control-autorizacion.component';
import {AdminListasControlComponent} from './components/admin-listas-control/admin-listas-control.component';
import {
  ListasControlConsultaHitsComponent
} from './components/listas-control-consulta-hits/listas-control-consulta-hits.component';
import {BitacoraConsultasDevComponent} from './components/bitacora-consultas-dev/bitacora-consultas-dev.component';
import {ListasControlGiroActiComponent} from './components/listas-control-giro-acti/listas-control-giro-acti.component';
import {
  ListasControlSectorActiComponent
} from './components/listas-control-sector-acti/listas-control-sector-acti.component';
import {ProductosServiciosComponent} from './components/productos-servicios/productos-servicios.component';
import {OrigDestRecursosComponent} from './components/orig-dest-recursos/orig-dest-recursos.component';
import {UbicacionGeograficaComponent} from './components/ubicacion-geografica/ubicacion-geografica.component';
import {ActividadEconomicaComponent} from './components/actividad-economica/actividad-economica.component';
import {VolumenComponent} from './components/volumen/volumen.component';
import {TipoPersonaComponent} from './components/tipo-persona/tipo-persona.component';
import {GradoRiesgoComponent} from './components/grado-riesgo/grado-riesgo.component';
import {ConfigNivelComponent} from './components/config-nivel/config-nivel.component';
import {ConfiguracionListasComponent} from './components/configuracion-listas/configuracion-listas.component';
import {LogListComponent} from './components/log-list/log-list.component';
import {AutorizaGrVentana2Component} from './components/autoriza-gr-ventana2/autoriza-gr-ventana2.component';
import {LcConsultaHitComponent} from './components/lc-consulta-hit/lc-consulta-hit.component';
import {LcConsultaGrComponent} from './components/lc-consulta-gr/lc-consulta-gr.component';
import {MotivosIngresoComponent} from './components/motivos-ingreso/motivos-ingreso.component';
import {TypeYastasGuard} from './guard/type-yastas-guard';
import {TypeGeneralGuard} from './guard/type-general.guard';
import {
  CambioEstatusGradoRiesgoComponent
} from './components/cambio-estatus-grado-riesgo/cambio-estatus-grado-riesgo.component';
import {
  ConsultaHitGeolocalizacionComponent
} from './components/consulta-hit-geolocalizacion/consulta-hit-geolocalizacion.component';
import {CrudTestComponent} from './components/custom/crud-test/crud-test.component';
import {InicioComponent} from './components/inicio/inicio.component';
import {AuthGuard} from './guard/auth.guard';
import {LayoutComponent} from './components/ui/layout/layout.component';
import { LcConsultaHitRespComponent } from './components/lc-consulta-hit-resp/lc-consulta-hit-resp.component';
import { LcConsultaGrRespComponent } from './components/lc-consulta-gr-resp/lc-consulta-gr-resp.component';

const routesNew: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'app',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        redirectTo: 'home'
      },
      {
        path: 'home',
        component: InicioComponent
      },
      {
        path: 'listas-control',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'prefix',
            redirectTo: 'consultar-hits'
          },
          {
            path: 'consultar-hits-original',
            component: LcConsultaHitComponent
          },
          {
            path: 'consultar-hits',
            component: LcConsultaHitRespComponent
          },
          {
            path: 'actualizacion',
            component: ListasControlBLOQComponent
          },
          {
            path: 'validacion-cargas',
            component: LogListComponent
          },
          {
            path: 'autorizaciones',
            component: AdminListasControlComponent
          },
          {
            path: 'razones',
            component: MotivosIngresoComponent
          }
        ]
      },
      {
        path: 'factores',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'prefix',
            redirectTo: 'sector'
          },
          {
            path: 'sector',
            component: ListasControlSectorActiComponent
          },
          {
            path: 'producto',
            component: ProductosServiciosComponent
          },
          {
            path: 'origen',
            component: OrigDestRecursosComponent
          },
          {
            path: 'ubicacion',
            component: UbicacionGeograficaComponent
          },
          {
            path: 'actividad',
            component: ActividadEconomicaComponent
          },
          {
            path: 'volumen',
            component: VolumenComponent
          },
          {
            path: 'tipo-persona',
            component: TipoPersonaComponent
          },
        ]
      },
      {
        path: 'grado-riesgo',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'prefix',
            redirectTo: 'consultar'
          },
          {
            path: 'consultar',
            component: LcConsultaGrRespComponent
          },
          {
            path: 'consultar-original',
            component: LcConsultaGrComponent
          },
          {
            path: 'autorizaciones',
            component: BitacoraConsultasDevComponent
          },
          {
            path: 'autorizaciones-ventas',
            component: AutorizaGrVentana2Component
          },
          {
            path: 'cambio',
            component: CambioEstatusGradoRiesgoComponent
          },
          {
            path: 'hits-geolocalizacion',
            component: ConsultaHitGeolocalizacionComponent
          }
        ]
      },
      {
        path: 'configuracion',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'prefix',
            redirectTo: 'factores'
          },
          {
            path: 'factores',
            component: GradoRiesgoComponent
          },
          {
            path: 'grado',
            component: ConfigNivelComponent
          },
          {
            path: 'listas',
            component: ConfiguracionListasComponent
          },
        ]
      }
    ]
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routesNew)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
