import { Component, OnInit } from "@angular/core";
import { DateAdapter, MatSnackBar } from "@angular/material";
import { UserService } from "@shared/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { CargaMasivaService } from "@shared/carga-masiva.service";
import {
  IColumns,
  IExportExcel,
  IScroll,
  Toolbar,
  IColumnHiding,
  ICheckbox,
  IEditing,
  IColumnchooser,
} from "@models/grid.interfaces";
import { ListasControlBLOQ } from "@shared/listas-control-bloq.service";
import { ListasControlGRIS } from "@shared/listas-control-gris.service";
import { ListasControlOFAC } from "@shared/listas-control-ofac.service";
import { ListasControlPEP } from "@shared/listas-control-pep.service";
import { ListasControlPLDYF } from "@shared/listas-control-pldyf.service";
import { AdminListas } from "@shared/admin-listas.service";
import { ListasControlAutReg } from "@shared/listas-control-autorizacion_reg.service";
import { SecureStorage } from "@utils/secure-storage";
import { allHasValue, anyHasValue } from "../../shared/utils/hasValue";
import { COLUMNS, COLUMNS_HITS, TOOLBAR_HITS } from "./COLUMNS";
import { formatDateLC } from "src/app/core/utils/dateConvert";

@Component({
  selector: "app-lc-consulta-hit",
  templateUrl: "./lc-consulta-hit.component.html",
  styleUrls: ["./lc-consulta-hit.component.scss"],
})
export class LcConsultaHitComponent implements OnInit {
  flaglista: any;
  wait: boolean = true;
  wait2: boolean = true;
  waitFind: boolean = false;
  waitFile: boolean = false;
  maxDate = new Date();
  datosFind: any;
  datosTable: any;
  datosTableHits: any;
  datoseventFind: any;
  con_lista: string = "";
  datosStatus: any;
  tipoBusq = 0;

  coincidencia_tabla: string = "";
  inicio: string = "";
  fin: string = "";
  estatus: string = "";
  nombre_completo: string = "";
  oficina: string = "";
  oficinas: any;

  usuario: any;
  nomina: any;

  nombresInput: string = "";
  idT: string = "";

  /*Datos de busqueda */
  find_nombre: string = "";
  find_rfc: string = "";
  find_fechaNac: string = "";
  find_dni: string = "";

  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  columnsHits: IColumns[];
  toolbarHits: Toolbar[];
  exportExcelHits: IExportExcel;
  columnHidingHits: IColumnHiding;
  CheckboxHits: ICheckbox;
  EditingHits: IEditing;
  ColumnchooserHits: IColumnchooser;
  scrollHits: IScroll;
  datoseventHits: any;
  onExportingFunHits: Function;
  onExportedFunHits: Function;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  color = "primary";
  mode = "indeterminate";
  value = 50;
  displayProgressSpinner = false;
  url: any;
  listasNegras: [];
  formControl = new FormControl("", [Validators.required]);

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,
    public cargaMasiva: CargaMasivaService,
    public dataServiceBLOQ: ListasControlBLOQ,
    public dataServiceGRIS: ListasControlGRIS,
    public dataServiceOFAC: ListasControlOFAC,
    public dataServicePEP: ListasControlPEP,
    public dataServicePLDYF: ListasControlPLDYF,
    public dataServiceAdminListas: AdminListas,
    public dataServiceAut: ListasControlAutReg,
    private readonly adapter: DateAdapter<Date>
  ) {
    this.adapter.setLocale("es-MX");
    if (!userService.getUserLogIn()) this.route.navigate(["./login"]);
    this.datosTable = [];
    this.datosTableHits = [];
    this.datosFind = [];
    this.nombresInput = "";
    this.idT = "";
    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: "Listado de Información" };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: "multiple" }; // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: true }; // parametros de para seleccion de columnas

    this.scroll = { mode: "standard" }; // Parametros de Scroll
    this.columns = COLUMNS;
    this.toolbar = [
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          width: 90,
          text: "Agregar",
          onClick: this.receiveMessageDoc.bind(this, "add"),
        },
        visible: true,
      },
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          width: 90,
          text: "Editar",
          onClick: this.receiveMessageDoc.bind(this, "edt"),
        },
        visible: false,
        name: "simple",
      },
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          width: 90,
          text: "Eliminar",
          onClick: this.receiveMessageDoc.bind(this, "del"),
        },
        visible: false,
        name: "simple",
      },
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          width: 110,
          text: "Actualizar",
          onClick: this.receiveMessageDoc.bind(this, "loadD"),
        },
        visible: true,
      },
    ];
    //Grid de Datos para mostrar Hits
    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcelHits = {
      enabled: true,
      fileName: "Listado de Información",
    };
    this.columnHidingHits = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.CheckboxHits = { checkboxmode: "multiple" }; // *Activar con multiple*/parametros de para checkbox
    this.EditingHits = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.ColumnchooserHits = { columnchooser: true }; // parametros de para seleccion de columnas

    this.scrollHits = { mode: "standard" }; // Parametros de Scroll
    this.columnsHits = COLUMNS_HITS;
    this.toolbarHits = TOOLBAR_HITS;
  }

  //Grid de datos para mostrar Hits

  ngOnInit() {
    this.loadData();
    this.getAllNameLC();
    this.getSistemas();
    this.datosTableHits = null;
    this.datosTable = null;
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + "\n" + err.error.text;
      } catch {}
      this.snackBar.open(msj, "close");
    }
  }

  limpiarFiltros() {
    this.con_lista = "";
    this.coincidencia_tabla = "";
    this.inicio = "";
    this.fin = "";
    this.estatus = "";
    this.nombre_completo = "";
    this.oficina = "";
    this.datosTable = null;
    this.datosTableHits = null;
  }

  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case "add":
          //this.add();
          break;
        case "edt":
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            //this.upd(d)
          }
          break;
        case "del":
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            //this.del(d)
          }
          break;
        case "loadD":
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + "\n" + err.error.text;
      } catch {}
      this.snackBar.open(msj, "close");
    }
  }

  receiveMessageDocHits($event) {
    try {
      switch ($event) {
        case "change_status":
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
          }
          break;
        case "loadD":
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + "\n" + err.error.text;
      } catch {}
      this.snackBar.open(msj, "close");
    }
  }

  loadData() {
    this.dataServiceAdminListas
      .getStatus()
      .toPromise()
      .then((res) => {
        this.datosStatus = res;
        if (
          this.coincidencia_tabla != "LPEP" &&
          this.datosStatus &&
          this.datosStatus[2]
        ) {
          this.datosStatus[2].statusFront = "PENDIENTE POR AUTORIZAR";
        } else if (
          this.coincidencia_tabla == "LPEP" &&
          this.datosStatus &&
          this.datosStatus[2]
        ) {
          this.datosStatus[2].statusFront = "EN ANALISIS";
        }
        if (this.datosStatus["Code"] == "404") {
          this.datosStatus = null;
        }
      });
  }

  cambiarEtiquetas(data) {
    if (data.value != "LPEP" && this.datosStatus && this.datosStatus[2]) {
      this.datosStatus[2].statusFront = "PENDIENTE POR AUTORIZAR";
    } else if (
      data.value == "LPEP" &&
      this.datosStatus &&
      this.datosStatus[2]
    ) {
      this.datosStatus[2].statusFront = "EN ANALISIS";
    }
  }

  loadHitsByParams() {
    this.displayProgressSpinner = true;
    let body = {
      coincidencia_tabla: this.coincidencia_tabla,
      inicio: this.inicio,
      fin: this.fin,
      estatus: this.estatus,
      sistema: this.oficina,
      nombre_completo: this.nombre_completo,
    };
    this.dataServiceAdminListas
      .getHitsByParams(body)
      .toPromise()
      .then((res) => {
        this.wait2 = false;
        if (res.datos.length <= 0) {
          this.snackBar.open(
            "No existen hits con estos filtros, no se encontraron registros",
            "close"
          );
        }
        this.datosTable = formatDateLC(res.datos || []);
        this.displayProgressSpinner = false;
      })
      .catch((err) => {
        this.wait2 = false;
        this.displayProgressSpinner = false;
        let msj = err.message;
        try {
          msj = err.error.error.message + "\n" + err.error.text;
        } catch {}
        this.snackBar.open(msj, "close");
      });
  }

  getSistemas() {
    this.dataServiceAdminListas
      .getOficinas()
      .toPromise()
      .then((res) => {
        this.oficinas = res.datos.sort((x, y) =>
          x.nombreOficina.localeCompare(y.nombreOficina)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  setWrite(name) {
    let user = JSON.parse(
      SecureStorage.decrypt(localStorage.getItem("currentUser"))
    );
    let permissions = user.permissions;
    if (permissions.length > 0) {
      let element = permissions.filter((d) => d.name == name);
      if (element.length > 0) {
        return element[0].write;
      }
      return true;
    }
    return true;
  }

  loadListaHits(lista: string) {
    let body = {
      coincidencia_tabla: this.con_lista,
      inicio: this.inicio,
      fin: this.fin,
      estatus: this.estatus,
      sistema: "",
      nombre_completo: "",
    };
    this.wait = true;
    this.toolbarHits = this.setWrite(lista) ? TOOLBAR_HITS : [];
    this.displayProgressSpinner = true;
    this.dataServiceAdminListas
      .getHitsByParams(body)
      .toPromise()
      .then((res) => {
        this.displayProgressSpinner = false;
        this.wait = false;
        if (res.datos.length <= 0) {
          this.snackBar.open(
            "No existen hits con estos filtros, no se encontraron registros",
            "close"
          );
        }
        this.datosTableHits = res.datos;
        if (this.datosTableHits["Code"] == "404") {
          this.datosTableHits = null;
        }
      })
      .catch((err) => {
        this.displayProgressSpinner = false;
        this.wait2 = false;
        let msj = err.message;
        try {
          msj = err.error.error.message + "\n" + err.error.text;
        } catch {}
        this.snackBar.open(msj, "close");
      });
  }

  getAllNameLC() {
    this.listasNegras = null;
    this.dataServiceAdminListas
      .getAllNameLC()
      .toPromise()
      .then((data: any) => {
        this.listasNegras = data;
      })
      .catch((err) => {
        this.listasNegras = null;
      });
  }

  getErrorMessage() {
    return this.formControl.hasError("required") ? "Required field" : "";
  }

  getCanSearchByPerson() {
    return (
      anyHasValue([
        this.coincidencia_tabla,
        this.estatus,
        this.oficina,
        this.nombre_completo,
      ]) || allHasValue([this.inicio, this.fin])
    );
  }

  getCanSearchGeneral() {
    return (
      anyHasValue([this.estatus, this.con_lista]) ||
      allHasValue([this.inicio, this.fin])
    );
  }
}
