import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA} from '@angular/material';
import { KEYS_STORAGE } from '@constants/KeysStorage';
import {
  IColumns,
  IExportExcel,
  IColumnHiding,
  ICheckbox,
  IEditing,
  IColumnchooser,
  IScroll,
  Toolbar
} from '@models/grid.interfaces';
import { LocalStorageService } from '@services/local-storage.service';
import {CambioEstatusGradoRiesgoService} from '@shared/cambio-estatus-grado-riesgo';
import {SecureStorage} from '@utils/secure-storage';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-cambio-estatus-grado-riesgo',
  templateUrl: './cambio-estatus-grado-riesgo.component.html',
  styleUrls: ['./cambio-estatus-grado-riesgo.component.scss']
})
export class CambioEstatusGradoRiesgoComponent implements OnInit {
  loaded = 1;
  oficinas: any;
  encarga = false;
  datosevent: any;
  datosTableCambio: any;
  columnasCambio: IColumns[];
  datosPorActualizar = [];
  maxDate = new Date();
  toolbar: Toolbar[];
  exportExcelCambio: IExportExcel;
  columnHidingCambio: IColumnHiding;
  CheckboxCambio: ICheckbox;
  EditingCambio: IEditing;
  ColumnchooserCambio: IColumnchooser;
  scrollCambio: IScroll;
  onExportingFun: Function;
  onExportedFun: Function;
  onRowUpdated: any;
  editevent: any;
  cargado = true;
  color = 'primary';
  mode = 'indeterminate';
  guardavars = {};
  dataGeneral = [];
  dataCliente = [];
  value = 50;
  valid = false;
  url: any;
  data2: any = {
    grado: '',
    oficina: '',
    bp: '',
    nombre: ''
  };
  data: any = {
    grado: '',
    oficina: '',
    fecha_inicio: '',
    fecha_fin: '',
    tipo_eval: '',
    tipo_alerta: ''
  };
  // Config tabla
  grados = [
    {id: 1, name: 'Bajo'},
    {id: 2, name: 'Medio'},
    {id: 3, name: 'Alto'},
    {id: 4, name: 'Inaceptable'}
  ];

  tiposEvaluacion = [
    'RGI', 'RGS', 'RGPLD'
  ];

  tiposAlerta = [
    {
      name: 'Geolocalización',
      value: 'geolocalizacion'
    },{
      name: 'Frecuencia de la operación',
      value: 'frecuencia_de_la_operacion'
    },{
      name: 'Número de contrapartes',
      value: 'numero_de_contrapartes'
    },{
      name: 'Manejo de efectivo',
      value: 'manejo_de_efectivo'
    },
  ];

  constructor(public snackBar: MatSnackBar, public dialog: MatDialog, public service: CambioEstatusGradoRiesgoService,private localStorage: LocalStorageService,) {
    this.columnHidingCambio = {hide: true}; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.exportExcelCambio = {enabled: true, fileName: 'Listado de Información'};
    this.CheckboxCambio = {checkboxmode: 'multiple'};  // *Activar con multiple*/parametros de para checkbox
    this.EditingCambio = {allowupdate: true, mode: 'popup'}; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.ColumnchooserCambio = {columnchooser: true}; // parametros de para seleccion de columnas
    this.scrollCambio = {mode: 'standard'};
    this.toolbar = [];
    this.columnasCambio = [ // Columnas
      {
        caption: 'BP',
        dataField: 'bp',
        hiddingPriority: '0',
        allowEditing: false,
      },
      {
        caption: 'Nombre completo',
        dataField: 'nombre_completo',
        hiddingPriority: '0',
        allowEditing: false,
      },
      {
        caption: 'Fecha de nacimiento',
        dataField: 'fecha_nacimiento',
        hiddingPriority: '0',
        allowEditing: false,
      },
      {
        caption: 'Coincidencia',
        dataField: 'coincidencia',
        hiddingPriority: '0',
        allowEditing: false,
      },
      {
        caption: 'Oficina',
        dataField: 'oficina',
        hiddingPriority: '0',
        allowEditing: false,
      },
      {
        caption: 'Actividad económica',
        dataField: 'actividad_economica',
        allowEditing: false,
        width: 220
      },
      {
        caption: 'Fecha grado inicial',
        dataField: 'fecha_consulta',
        hiddingPriority: '0',
        allowEditing: false,
      },
      {
        caption: 'Fecha semestral',
        dataField: 'fecha_semestral',
        hiddingPriority: '0',
        allowEditing: false,
      },
      {
        caption: 'Grado de Riesgo',
        dataField: 'grado',
        allowEditing: false,
      },
      {
        caption: 'Grado Nuevo',
        dataField: 'grado_nuevo',
        status: [
          {id: 'Bajo', name: 'Bajo'},
          {id: 'Medio', name: 'Medio'},
          {id: 'Alto', name: 'Alto'},
          {id: 'Inaceptable', name: 'Inaceptable'}
        ],
        allowEditing: true,
        width: 100
      },
      {
        caption: 'Fecha PLD',
        dataField: 'fecha_modificacion',
        allowEditing: false,
        width: 220
      },
      {
        caption: 'Usuario modificación',
        dataField: 'usuario_modificacion',
        allowEditing: false,
        width: 220
      },
      {
        caption: 'Eventos Geolocalización',
        dataField: 'num_eventos_geo',
        allowEditing: false,
        width: 220
      },
      {
        caption: 'Monto Eventos Geolocalización',
        dataField: 'monto_eventos_geo',
        allowEditing: false,
        width: 220
      },
      {
        caption: 'Centro penitenciario',
        dataField: 'nombre_centro_penitenciario',
        allowEditing: false,
        width: 220
      },
      {
        caption: 'Frecuencia Operación',
        dataField: 'total_operacion',
        hiddingPriority: '0',
        allowEditing: false,
      },
      {
        caption: 'Número contrapartes',
        dataField: 'num_total_contrapartes',
        hiddingPriority: '0',
        allowEditing: false,
      },
      {
        caption: 'Manejo de efectivo',
        dataField: 'monto_total_manejo_efectivo',
        hiddingPriority: '0',
        allowEditing: false,
      },
      {
        caption: 'Usuario autoriza',
        dataField: 'usuario_autoriza',
        hiddingPriority: '0',
        allowEditing: false,
      },
      {
        caption: 'Comentarios',
        dataField: 'comentarios',
        allowEditing: true,
        width: 400
      },
      {
        caption: 'Estatus de la alerta',
        dataField: 'estatus_alerta',
        allowEditing: false,
        width: 200
      },
    ];
    this.obtenerOficinas();
  }

  formControl = new FormControl('', [Validators.required]);

  ngOnInit() {
  }

  limpiarFiltro() {
    if (this.loaded === 2) {
      this.data2 = {
        grado: '',
        oficina: '',
        bp: '',
        nombre: ''
      };
      this.dataCliente = [];
    } else {
      this.data = {};
      this.dataGeneral = [];
    }
    this.cargado = false;
  }

  changeVar(variable: number) {
    this.loaded = variable;
    this.limpiarFiltro();
  }

  obtenerOficinas() {
    this.encarga = true;
    this.service.getOficinas().toPromise().then((res) => {
      this.oficinas = res.datos.sort((x, y) => x.nombreOficina.localeCompare(y.nombreOficina));
      this.encarga = false;
    }).catch((err) => {
      console.log(err);
    });
  }

  buscarRiesgo() {
    this.cargado = true;
    this.encarga = true;
    if (this.loaded === 1) {
      this.guardavars = this.data;
      this.busquedaGeneral();
    } else {
      this.busquedaCliente();
    }

  }

  busquedaCliente() {
    this.service.getGradoRiesgoCliente(this.data2).toPromise().then((res) => {
      console.log(res);
      if (res.Status === 'True') {
        if (res.data.length > 0) {
          this.dataCliente = res.data;
          this.cargado = true;
        } else {
          this.cargado = false;
        }

      } else {
        this.cargado = false;
      }
      this.encarga = false;

    }).catch((err) => {
      console.log(err);
    });
  }

  salvar() {
    if (this.datosPorActualizar.length > 0) {
      console.log('En mantenimiento');
    } else {
      this.snackBar.open('No se ha realizado ningun cambio', 'close');
    }
  }

  getUpdating($event) {
    try {
      this.editevent = $event.data;
      const id = $event.editdata.key.id;
      const nombre = $event.editdata.key.nombre_completo;
      const bp = $event.editdata.key.bp;
      const oficina = $event.editdata.key.oficina;
      const id_oficina = $event.editdata.key.id_oficina;
      const coincidencia = $event.editdata.key.coincidencia;
      const usuario = JSON.parse(SecureStorage.decrypt(localStorage.getItem('currentUser')));

      const grado = $event.editdata.newData.grado_nuevo;
      const comentario = $event.editdata.newData.comentarios;
      const json = {
        id,
        grado,
        nombre_completo: nombre,
        bp,
        nombre_ofi: oficina,
        coincidencia,
        id_oficina,
        usuario: usuario.nombre,
        comentarios: comentario
      };
      this.service.putCambiarGrado(json).toPromise().then((res) => {
        if (res.Status === 'True') {
          this.snackBar.open('Se actualizo correctamente', 'close');
        }
      });
      console.log($event);
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch {
      }
      this.snackBar.open(msj, 'close');
    }
  }


  busquedaGeneral() {
    const datanew = {...this.data};
    datanew.fecha_inicio = this.getFormatDate(datanew.fecha_inicio);
    datanew.fecha_fin = this.getFormatDate(datanew.fecha_fin);
    this.service.getGradoRiesgo(datanew).toPromise().then((res) => {
      if (res.Status === 'True') {
        if (res.data.length > 0) {
          this.dataGeneral = res.data;
          this.cargado = true;
        } else {
          this.cargado = false;
        }
      } else {
        this.cargado = false;
      }
      this.encarga = false;
    }).catch((err) => {
      console.log(err);
    });
  }

  getFormatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }


  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Campo requerido' : '';
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch {
      }
      this.snackBar.open(msj, 'close');
    }
  }

}
