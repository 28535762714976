import { Component, OnDestroy, OnInit } from "@angular/core";
import { LoginService } from "@shared/login.service";
import { UserService } from "@shared/user.service";
import { Router } from "@angular/router";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { AuthconfigService } from "../../services/authconfig.service";
import { AService } from "../../services/auth.service";
import { Subscription } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { UserLogged, UserResponse } from "src/app/core/models/User";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  isLoading = false;
  hide = true;
  version = environment.version;

  constructor(
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private loginService: LoginService,
    public userService: UserService,
    private aService: AService,
    private authConfigService: AuthconfigService,
    private route: Router
  ) {
    this.checkCurrentUser();
  }

  public loginIcon = "assets/images/logo.png";
  public returnUrl: string;
  form!: FormGroup;

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() {
    this.crearFormulario();
    this.checkCurrentUser();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  checkCurrentUser() {
    if (this.authConfigService.authUserExists) {
      this.route.navigate(["/app/"], {
        replaceUrl: true,
      });
    }
  }

  crearFormulario() {
    this.form = this.formBuilder.group({
      usr: ["", Validators.required],
      pasd: ["", Validators.required],
    });
  }

  get formControls() {
    return this.form.controls;
  }

  onSubmit($event: Event): void {
    $event.preventDefault();
    if (this.form.valid) {
      this.authenticate();
    }
  }

  bonita() {
    const { usr, pasd } = this.form.value;
    const other = this.aService.getOther(usr, pasd);
    const user = {
      nomina: 82245,
      nombre: other.name,
      dateLogIn: Date.now(),
      permissions: [],
      perfil: other.perfil,
    };
    this.getPermission(user);

  }

  public authenticate(): void {
    this.isLoading = true;
    const { usr, pasd } = this.form.value;
    if (this.aService.existHardCodeUser(usr, pasd)) {
      this.bonita();
    } else {
      this.loginFunc();
    }
  }
  /**

   * Realiza el llamado al servicio para loguear

   * @param usrname Usuario de comparnet

   * @param pass Password de comparnet

   */
  private loginFunc() {
    const { usr, pasd } = this.form.value;
    this.subscription.add(
      this.loginService.postAuth({ Nombre: usr, Password: pasd }).subscribe({
        next: (data: any) => {
          if (data.Estatus === true) {
            const user = data.Usuario as UserResponse;
            if (user.Usuario) {
              this.loginInterno(user);
            }
          } else {
            this.loginExterno();
          }
        },
        error: (err) => {
          const msj = err.error.error.message + "\n" + err.error.text;
          this.snackBar.open(msj, "close");
          this.isLoading = false;
        },
      })
    );
  }

  loginInterno(user: UserResponse) {
    const { usr } = this.form.value;
    this.subscription.add(
      this.loginService.postPerfil({ nomina: user.Nomina }).subscribe({
        next: (response: any) => {
          if (response.error !== "") {
            this.snackBar.open(response.error, "close");
            this.isLoading = false;
          } else {
            const userLogged: UserLogged = {
              nomina: user.Nomina,
              nombre: usr,
              dateLogIn: Date.now(),
              permissions: [],
              perfil: response.datos as string,
            };
            this.getPermission(userLogged);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.snackBar.open(err.error.error.message, "close");
          this.isLoading = false;
        },
      })
    );
  }

  loginExterno() {
    const { usr } = this.form.value;
    this.subscription.add(
      this.loginService.postPerfilExterno({ usuario: usr }).subscribe({
        next: (response: any) => {
          if (response.error !== "") {
            this.snackBar.open(response.error, "close");
            this.isLoading = false;
          } else {
            const userLogged: UserLogged = {
              nomina: 5403,
              nombre: usr,
              dateLogIn: Date.now(),
              permissions: [],
              perfil: response.datos as string,
            };
            this.getPermission(userLogged);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.snackBar.open(err.error.error.message, "close");
          this.isLoading = false;
        },
      })
    );
  }

  getPermission(user: UserLogged) {
    this.subscription.add(
      this.loginService.getPermissions(user.perfil)
      .subscribe({
        next: (response: any) => {
          const permissions: number[] = response.datos.pantallas || [];
          if (permissions.length > 0) {
            this.aService.setPermissions(permissions);
            this.isLoading = false;
            this.setConfigUser(user);
          } else {
            this.isLoading = false;
            this.snackBar.open(
              "No se encontraron datos de perfil para la persona, contacte a su administrador",
              "close"
            );
          }
        },
        error: (err: HttpErrorResponse) => {
          this.snackBar.open(err.error.message, "close");
          this.isLoading = false;
        },
      })
    );
  }

  setConfigUser(user: UserLogged) {
    this.userService.setUserLogIn(user);
    this.aService.setTypeUser(user.perfil);
    this.navegacionPerfil(user);
  }

  navegacionPerfil(user: any) {
    this.authConfigService.configure(user);
    this.route.navigate(["/app/home"]);
  }
}