import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
// SERVICES
import { UserService } from "@shared/user.service";
import { AdminListas } from "@shared/admin-listas.service";
import { MatSnackBar } from "@angular/material";
import { ListasControlAutReg } from "@shared/listas-control-autorizacion_reg.service";
import { TimerNoti } from "src/app/core/utils/timer";
import { ConfirmacionGradoComponent } from "../../confirmacion-grado/confirmacion-grado.component";
import { Pipe, PipeTransform} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-unlock-geo",
  templateUrl: "./unlock-geo.component.html",
  styleUrls: ["./unlock-geo.component.scss"],
})
@Pipe({ name: 'safe' })
export class UnlockGeoComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  form!: FormGroup;
  statuses: any[] = [];
  item: any;
  loading = false;
  isVentas = false;
  action_active = false;
  fechaEvaluacionInicial = "";
  iframeUrl: any = `https://maps.google.com/maps?width=100%&height=600&hl=es&q=`;

  constructor(
    public dialogRef: MatDialogRef<UnlockGeoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private adminListasService: AdminListas,
    public listasControlAutRegService: ListasControlAutReg,
    private snackBar: MatSnackBar,
    private timer: TimerNoti
  ) {
    this.item = this.data.data;
    this.statuses = this.data.status;
    this.fechaEvaluacionInicial =
      this.data.type !== "auth-ventas"
        ? this.item.fecha_evaluacion_inicial
        : this.item.fecha_consulta;
    this.action_active = this.item.estatus == "En validación"
    this.iframeUrl += `${ this.item.latitud }, ${ this.item.longitud }&output=embed&z=14`;
    this.iframeUrl = this.transformURL(this.iframeUrl)
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  transformURL(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  get isAuthorized() {
    return this.item.idAutorizacionInicial !== null;
  }

  get nameUser() {
    return this.userService.user.nombre;
  }

  close() {
    this.dialogRef.close({ success: false });
  }

  createForm() {
    this.form = new FormGroup({
      statusId: new FormControl(null, [Validators.required]),
    });
  }

  startTransaction($event: Event) {
    $event.preventDefault();
    const dialogRefConfirm = this.dialog.open(ConfirmacionGradoComponent, 
      {
        hasBackdrop: false, 
        data: '¿Estás seguro de la acción a ejecutar? Recuerda que no podrá ser modificada una vez que la ejecutes'
      }
    );
    dialogRefConfirm.afterClosed().subscribe(resDialogConfirm => {
      if (resDialogConfirm == true) {

        const data = this.createPayload();
        this.loading = true;
        this.timer.startTimer();
        console.log("initTimer")
        this.subscription.add(
          this.adminListasService.putUpdAprobacionHitGeo(data).subscribe({
            next: (res) => {
              this.loading = false;
              this.timer.clearTimer();
              this.snackBar.open(`Se ha autorizado correctamente`, "ok", {
                duration: 2500,
              });
              this.dialogRef.close({ success: true });
            },
            error: (err: HttpErrorResponse) => {
              this.loading = false;
              this.timer.clearTimer();
              console.error(err);
              this.snackBar.open("Sucedio un error en el servicio", "close");
            },
          })
        );
      }
    });
  }

  createPayload() {
    const nivel_riesgo = Number(this.form.value.statusId);
    const { nombre } = this.statuses.find((x) => x.id === nivel_riesgo);
    return {
      ...this.item,
      estatus_riesgo: nombre,
      user: this.userService.user.nombre,
      nivel_riesgo: nivel_riesgo,
    };
  }

}
