import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { CargaMasivaService } from '@shared/carga-masiva.service';
import { IColumns, IExportExcel, IScroll, Toolbar, IColumnHiding, ICheckbox, IEditing, IColumnchooser, TiposdeDato, TiposdeFormato } from '@models/grid.interfaces'
import { ListasControlGeneral } from '@shared/listas-control-general.service';
import { ListasControlConsultaHits } from '@shared/listas-control-consulta-hits.service';
import { SecureStorage } from '@utils/secure-storage';

@Component({
  selector: 'app-listas-control-bloq',
  templateUrl: './listas-control-bloq.component.html',
  styleUrls: ['./listas-control-bloq.component.scss']
})
export class ListasControlBLOQComponent implements OnInit {
  wait: boolean = false;
  write: boolean = true;
  waitFind: boolean = false;
  waitFile: boolean = false;
  listas: any;
  motivos: any;
  showbutton: boolean = false;

  datosFind: any;
  datosTable: any;
  datoseventFind: any;

  nombresInput: string = '';
  listaSelect: string = '';
  idT: string = '';
  selected;

  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,
    public cargaMasiva: CargaMasivaService,
    public dataService: ListasControlGeneral,
    public consultaHitsService: ListasControlConsultaHits
  ) {
    if (!userService.getUserLogIn()) route.navigate(['./login']);
    this.datosTable = [];
    this.datosFind = [];
    this.nombresInput = '';
    this.idT = '';

    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: 'Listado de Información' };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: false }; // parametros de para seleccion de columnas
    this.listas = [
      {
        id: 'lbloq',
        name: 'Lista Personas Bloqueadas',
        columns: [
          {
            caption: 'id',
            dataField: 'id',
            hiddingPriority: '0',
            width: -10
          },
          {
            caption: 'Lista',
            dataField: 'lista',
            hiddingPriority: '0'
          },
          {
            caption: 'Nombre Completo',
            dataField: 'nombre_completo',
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha de Nacimiento',
            dataField: 'fecha_nacimiento',
            dataType: TiposdeDato.date,
            format: TiposdeFormato.dmy,
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha creado',
            dataField: 'created_at',
            dataType: TiposdeDato.datetime,
            format: TiposdeFormato.dmyt,
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha actualizado',
            dataField: 'updated_at',
            dataType: TiposdeDato.datetime,
            format: TiposdeFormato.dmyt,
            hiddingPriority: '0'
          },
          {
            caption: 'Agregado por',
            dataField: 'created_for',
            hiddingPriority: '0'
          },
          {
            caption: 'Modificado por',
            dataField: 'updated_for',
            hiddingPriority: '0'
          },
        ]
      },
      {
        id: 'lgris',
        name: 'Lista Gris',
        columns: [
          {
            caption: 'id',
            dataField: 'id',
            hiddingPriority: '0',
            width: -10
          },
          {
            caption: 'Lista',
            dataField: 'lista',
            hiddingPriority: '0'
          },
          {
            caption: 'Nombre Completo',
            dataField: 'nombre_completo',
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha de Nacimiento',
            dataField: 'fecha_nacimiento',
            dataType: TiposdeDato.date,
            format: TiposdeFormato.dmy,
            hiddingPriority: '0'
          },
          {
            caption: 'ID Motivo',
            dataField: 'motivo',
            hiddingPriority: '0'
          },
          {
            caption: 'Descripción Motivo',
            dataField: 'desc_motivo',
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha creado',
            dataField: 'created_at',
            dataType: TiposdeDato.datetime,
            format: TiposdeFormato.dmyt,
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha actualizado',
            dataField: 'updated_at',
            dataType: TiposdeDato.datetime,
            format: TiposdeFormato.dmyt,
            hiddingPriority: '0'
          },
          {
            caption: 'Agregado por',
            dataField: 'created_for',
            hiddingPriority: '0'
          },
          {
            caption: 'Modificado por',
            dataField: 'updated_for',
            hiddingPriority: '0'
          }
        ]
      },
      {
        id: 'lofac',
        name: 'Lista OFAC',
        columns: [
          {
            caption: 'id',
            dataField: 'id',
            hiddingPriority: '0',
            width: -10
          },
          {
            caption: 'Lista',
            dataField: 'lista',
            hiddingPriority: '0'
          },
          {
            caption: 'Nombre Completo',
            dataField: 'nombre_completo',
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha de Nacimiento',
            dataField: 'fecha_nacimiento',
            dataType: TiposdeDato.date,
            format: TiposdeFormato.dmy,
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha creado',
            dataField: 'created_at',
            dataType: TiposdeDato.datetime,
            format: TiposdeFormato.dmyt,
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha actualizado',
            dataField: 'updated_at',
            dataType: TiposdeDato.datetime,
            format: TiposdeFormato.dmyt,
            hiddingPriority: '0'
          },
          {
            caption: 'Agregado por',
            dataField: 'created_for',
            hiddingPriority: '0'
          },
          {
            caption: 'Modificado por',
            dataField: 'updated_for',
            hiddingPriority: '0'
          },
        ]
      },
      {
        id: 'lpep',
        name: 'Lista PEP',
        columns: [
          {
            caption: 'id',
            dataField: 'id',
            hiddingPriority: '0',
            width: -10
          },
          {
            caption: 'Lista',
            dataField: 'lista',
            hiddingPriority: '0'
          },
          {
            caption: 'Nombre Completo',
            dataField: 'nombre_completo',
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha de Nacimiento',
            dataField: 'fecha_nacimiento',
            dataType: TiposdeDato.date,
            format: TiposdeFormato.dmy,
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha creado',
            dataField: 'created_at',
            dataType: TiposdeDato.datetime,
            format: TiposdeFormato.dmyt,
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha actualizado',
            dataField: 'updated_at',
            dataType: TiposdeDato.datetime,
            format: TiposdeFormato.dmyt,
            hiddingPriority: '0'
          },
          {
            caption: 'Agregado por',
            dataField: 'created_for',
            hiddingPriority: '0'
          },
          {
            caption: 'Modificado por',
            dataField: 'updated_for',
            hiddingPriority: '0'
          },
        ]
      },
      {
        id: 'lpldyf',
        name: 'Lista PLD Y FT',
        columns: [
          {
            caption: 'id',
            dataField: 'id',
            hiddingPriority: '0',
            width: -10
          },
          {
            caption: 'Lista',
            dataField: 'lista',
            hiddingPriority: '0'
          },
          {
            caption: 'Número de Nomina o BP',
            dataField: 'nomina',
            hiddingPriority: '0'
          },
          {
            caption: 'Marca colaborador',
            dataField: 'marca_colab',
            hiddingPriority: '0'
          },
          {
            caption: 'Nombre Completo',
            dataField: 'nombre_completo',
            hiddingPriority: '0'
          },
          {
            caption: 'RFC',
            dataField: 'rfc',
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha de Nacimiento',
            dataField: 'fecha_nacimiento',
            dataType: TiposdeDato.date,
            format: TiposdeFormato.dmy,
            hiddingPriority: '0'
          },
          {
            caption: 'Motivo',
            dataField: 'motivo',
            hiddingPriority: '0'
          },
          {
            caption: 'Descripción Motivo',
            dataField: 'desc_motivo',
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha creado',
            dataField: 'created_at',
            dataType: TiposdeDato.datetime,
            format: TiposdeFormato.dmyt,
            hiddingPriority: '0'
          },
          {
            caption: 'Fecha actualizado',
            dataField: 'updated_at',
            dataType: TiposdeDato.datetime,
            format: TiposdeFormato.dmyt,
            hiddingPriority: '0'
          },
          {
            caption: 'Agregado por',
            dataField: 'created_for',
            hiddingPriority: '0'
          },
          {
            caption: 'Modificado por',
            dataField: 'updated_for',
            hiddingPriority: '0'
          },
        ]
      },
      // {
      //   id: 'lsat',
      //   name: 'Lista SAT',
      //   columns: [
      //     {
      //       caption: 'id',
      //       dataField: 'id',
      //       hiddingPriority: '0',
      //       width: -10
      //     },
      //     {
      //       caption: 'Lista',
      //       dataField: 'lista',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Nombre Completo',
      //       dataField: 'nombre_completo',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Fecha de Nacimiento',
      //       dataField: 'fecha_nacimiento',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Fecha creado',
      //       dataField: 'created_at',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Fecha actualizado',
      //       dataField: 'updated_at',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Agregado por',
      //       dataField: 'created_for',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Modificado por',
      //       dataField: 'updated_for',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'RFC',
      //       dataField: 'rfc',
      //       hiddingPriority: '0'
      //     },
      //   ]
      // },
      // {
      //   id: 'lnegra_yastas',
      //   name: 'Lista Negra Yastas',
      //   columns: [
      //     {
      //       caption: 'id',
      //       dataField: 'id',
      //       hiddingPriority: '0',
      //       width: -10
      //     },
      //     {
      //       caption: 'Lista',
      //       dataField: 'lista',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Nombre Completo',
      //       dataField: 'nombre_completo',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Fecha de Nacimiento',
      //       dataField: 'fecha_nacimiento',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Fecha creado',
      //       dataField: 'created_at',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Fecha actualizado',
      //       dataField: 'updated_at',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Agregado por',
      //       dataField: 'created_for',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Modificado por',
      //       dataField: 'updated_for',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'RFC',
      //       dataField: 'rfc',
      //       hiddingPriority: '0'
      //     },
      //   ]
      // },
      // {
      //   id: 'lprev_frau',
      //   name: 'Lista Prevencion Fraude',
      //   columns: [
      //     {
      //       caption: 'id',
      //       dataField: 'id',
      //       hiddingPriority: '0',
      //       width: -10
      //     },
      //     {
      //       caption: 'Lista',
      //       dataField: 'lista',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Nombre Completo',
      //       dataField: 'nombre_completo',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Fecha de Nacimiento',
      //       dataField: 'fecha_nacimiento',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Fecha creado',
      //       dataField: 'created_at',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Fecha actualizado',
      //       dataField: 'updated_at',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Agregado por',
      //       dataField: 'created_for',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'Modificado por',
      //       dataField: 'updated_for',
      //       hiddingPriority: '0'
      //     },
      //     {
      //       caption: 'RFC',
      //       dataField: 'rfc',
      //       hiddingPriority: '0'
      //     },
      //   ]
      // },
    ]
    this.scroll = { mode: 'standard' }; // Parametros de Scroll
    this.columns = [ // Columnas
    ];

    this.toolbar = [];

  }
  setWrite(name) {
    let user = JSON.parse(SecureStorage.decrypt(localStorage.getItem('currentUser')));
    let permissions = user.permissions;
    if (permissions.length > 0) {
      let element = permissions.filter(d => d.name == name)
      if (element.length > 0) {
        return element[0].write
      }
      return true
    }
    return true
  }
  setListas() {
    let list_tmp = []
    let user = JSON.parse(SecureStorage.decrypt(localStorage.getItem('currentUser')));
    let permissions = user.permissions;
    if (permissions.length > 0) {
      for (let permission of permissions) {
        let element = this.listas.filter(d => d.id.toUpperCase() == permission.name)
        if (element.length > 0) list_tmp.push(element[0])
      }
      this.listas = list_tmp;
      if (list_tmp.length > 0) {
        this.listaSelect = list_tmp[0].id
      }
    }
  }

  ngOnInit() {
    this.setListas();
    //if(this.listaSelect.length > 0) this.loadData();
  }
  changeList(event) {
    this.listaSelect = event.value;
    this.showbutton = false;
    //this.loadData();
  }
  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case 'add':
          this.add();
          break;
        case 'del':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.del(d)
          }
          break;
        case 'loadD':
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  loadData() {
    const list = this.listas.find(elem => elem.id === this.listaSelect);
    this.columns = list.columns
    this.wait = true;
    this.getAllFactoresRiesgo();
    if (this.setWrite(this.listaSelect.toUpperCase())) {
      this.write = true;
      this.toolbar = [
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 150,
            text: 'Agregar Persona',
            onClick: this.receiveMessageDoc.bind(this, 'add')
          },
          visible: true
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 150,
            text: 'Eliminar Persona',
            onClick: this.receiveMessageDoc.bind(this, 'del')
          },
          visible: false,
          name: 'simple'
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 170,
            text: 'Actualizacion de listas',
            onClick: this.receiveMessageDoc.bind(this, 'loadD')
          },
          visible: true
        }

      ];
    } else {
      this.write = false;
    }
    this.dataService.getAll(this.listaSelect).toPromise().then((res) => {
      this.wait = false;
      this.showbutton = true;
      this.datosTable = res.Datos;
    })
  }

  getAllFactoresRiesgo() {
    // lpldyf
    let upperFiltro = this.listaSelect;
    let filtro = upperFiltro.toUpperCase() == 'LPLDYF' || upperFiltro.toUpperCase() == 'LPLDYFT' ? 'PLD Y FT' : this.listaSelect;
    this.consultaHitsService.getMotivos(filtro).toPromise().then((data: any) => {
      this.motivos = data;
    }).catch((err) => {
      this.motivos = null;
    });


  }

  add() {
    let datos = [null,null,null,null,null,null,this.listaSelect,this.motivos];
    const dialogRef = this.dialog.open(AddListBLOQComponent, { data: datos });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.dataService.post(resDialog, this.listaSelect).toPromise().then((res) => {
          this.loadData();
          if (res.Status.length > 0) {
            this.snackBar.open(res.Status[0], 'ok', { duration: 3500 });
          }
          else {
            this.snackBar.open(`Se ha dado de alta correctamente`, 'ok', { duration: 2500 });
          }
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch { }
          this.snackBar.open('Sucedio un error en el servicio', 'close');
        });
      }
    });
  }

  del(datos) {
    const dialogRef = this.dialog.open(DelListBLOQComponent, { data: datos });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.dataService.delete(resDialog, this.listaSelect).toPromise().then((res) => {
          datos["status"] = 0;
          this.loadData();
          this.snackBar.open(`Se ha eliminado correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch { }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  onFileSelected(file: any) {
    //this.snackBar.open(`Se ha cargado correctamente el archivo`, 'ok', { duration: 6000 });
    this.waitFile = true;
    const inputNode: any = document.querySelector('#file');
    this.cargaMasiva.uploadListasControlGeneral(inputNode.files[0], this.listaSelect).subscribe(
      (data) => {
        this.snackBar.open(`Se ha cargado correctamente el archivo. ${data.respuesta}`, 'ok', { duration: 9000 });
        this.waitFile = false;
        this.loadData();
      },
      (error) => {
        this.snackBar.open('Ocurrió un error al cargar el archivo', 'ok', { duration: 3000 });
        this.waitFile = false;
        this.loadData();
      }
    );
  }
  onFileSelectedCompleta(file: any) {
    //this.snackBar.open(`Se ha cargado correctamente el archivo`, 'ok', { duration: 6000 });
    this.waitFile = true;
    const inputNode: any = document.querySelector('#fileComplete');
    this.cargaMasiva.uploadListasControlGeneralCompleta(inputNode.files[0], this.listaSelect).subscribe(
      (data) => {
        if ((data && data.Code) || (data && data.Error)) {
          this.snackBar.open(`Ocurrio un error al procesar el archivo`, 'ok', { duration: 9000 });
          this.waitFile = false;
          this.loadData();
        } else {
          this.snackBar.open(`Se ha cargado correctamente el archivo. ${data.respuesta}`, 'ok', { duration: 9000 });
          this.waitFile = false;
          this.loadData();
        }
      },
      (error) => {
        this.snackBar.open('Ocurrió un error al cargar el archivo', 'ok', { duration: 3000 });
        this.waitFile = false;
        this.loadData();
      }
    );
  }
}

interface Status {
  value: string;
  viewValue: string;
}

/*
==================================================================
==================================================================
==================================================================
      COMPONENTES ADD DIALOG
==================================================================
==================================================================
==================================================================
 */
@Component({
  selector: 'app-add-listas-control-bloq',
  templateUrl: './add.component.html',
})
export class AddListBLOQComponent {
  data: any;
  updt = false;
  nombre = 'Agrega ';
  actEconomica: any;
  odRecursos: any;
  prodServ: any;
  tipoPersona: any;
  ubicacionGeo: any;
  volOper: any;
  motivos: any;
  listaSelect: string;
  selected;
  status: Status[] = [
    { value: '0', viewValue: 'Desactivado' },
    { value: '1', viewValue: 'Activado' }
  ];
  identificadores: any = {
    lbloq: 'idlbloq',
    lgris: 'idlgris',
    lofac: 'idlofac',
    lpep: 'idlpep',
    lpldyf: 'idlpldyft',
  }
  identificador_id: string
  constructor(public dialogRef: MatDialogRef<AddListBLOQComponent>, @Inject(MAT_DIALOG_DATA) public data2: any) {
    this.actEconomica = data2[0];
    this.odRecursos = data2[1];
    this.prodServ = data2[2];
    this.tipoPersona = data2[3];
    this.ubicacionGeo = data2[4];
    this.volOper = data2[5];
    this.listaSelect = data2[6];
    this.motivos = data2[7];
    this.data = {}
    this.data.nombre_completo = "";
    this.data.id_lpldyf = "";
    this.data.tipo = "";
    this.data.tipo_alerta = "";
    this.data.alerta = "";
    this.identificador_id = this.identificadores[this.listaSelect]
  }

  formControl = new FormControl('', [Validators.required]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Campo vacio o con formato invalido' : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}


/*
==================================================================
==================================================================
==================================================================
      COMPONENTES DELETE DIALOG
==================================================================
==================================================================
==================================================================
 */

@Component({
  selector: 'app-del-listas-control-bloq',
  templateUrl: './del.component.html',
})
export class DelListBLOQComponent {
  nombre = 'Elimina ';
  selected;
  constructor(public dialogRef: MatDialogRef<DelListBLOQComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  formControl = new FormControl('', [Validators.required
  ]);


  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}


