/*
import { Component, OnInit } from '@angular/core';
import { UserService } from './shared/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  items = ['AUTORIZACIÓN'];
  itemAuto = {
    autorizacion: 'Autorización de listas',
    autorizacion_gr: 'Autorización de grado de riesgo',
    autorizacion_gr_2: 'Autorización de grado de riesgo'
  };
  expandedIndex = 0;

  items2 = ['FACTORES']
  itemFact = {
    sector: 'Sector',
    producto: 'Producto',
    origen: 'Origen',
    ubicacion: 'Ubicación',
    actividad: 'Actividad',
    volumen: 'Volumen',
    tipo_persona: 'Tipo de persona'
  };

  items3 = ['CONFIGURACIÓN']
  itemConf = {
    factores: 'Factores',
    grado: 'Grado',
    listas: 'Listas'
  };

  items4 = ['LISTAS DE CONTROL'];
  itemLista = {
    consulta: 'Consultar Hits en Listas',
    actualizacion: 'Actualización de Listas',
    bitacoralog: 'Validación Cargas LC',
    logBP: 'Historico de listas',
    autorizacion: 'Autorizaciones/Rechazos Hits',
    razones: 'Razones de ingreso'
  };

  items5 = ['GRADO DE RIESGO'];
  itemGR = {
    consulta: 'Consultar Grado de Riesgo',
    autorizacion: 'Autorizaciones/Rechazos de Grado de Riesgo',
    cambio: 'Cambio de Grado de Riesgo',
    hitgeo: 'Consulta Hit Geolocalización',
  }

  private readonly doctFact = 'D.F - ';
  public readonly docFactoryMenuPages = [
    { routerLink: '/agregarDoc/0', name: this.doctFact + 'Agregar doc.' },
    { routerLink: '/configuraDoc/0', name: this.doctFact + 'Configurar docs.' },
    { routerLink: '/configura', name: this.doctFact + 'Configurar servicio' },
  ];
  public isMenuOpen = false;
  constructor(public route: Router, public userService: UserService) {

  }

  public onSidenavClick(): void {
    this.isMenuOpen = false;
  }

  ngOnInit() {
    if (!this.userService.getUserLogIn()) {
      this.route.navigate(['./login']);
    }
  }

  closeNav(): void {
    document.getElementById('hamburger').click();
  }

  cerrarSesion(): void {
    this.userService.setUserLogOut();
    this.route.navigate(['./login']);
  }
}
*/
import { locale, loadMessages } from "devextreme/localization";

import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ent-sedes-front-angular';

  constructor() {
    locale('es-MX');
  }
}
