import { environment } from "src/environments/environment";
import * as CryptoJS from 'crypto-js'
import { KEYS_STORAGE } from "@constants/KeysStorage";

export class SecureStorage{
   
    static encrypt(txt:string):string{

        let url = JSON.parse(localStorage.getItem(KEYS_STORAGE.url));

        return CryptoJS.AES.encrypt(txt,url.secureCode).toString();
    }

    static decrypt(txt:string):any{
        let url = JSON.parse(localStorage.getItem(KEYS_STORAGE.url));

        return CryptoJS.AES.decrypt(txt,url.secureCode).toString(CryptoJS.enc.Utf8);
    }
}