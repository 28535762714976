import {Component, Input, OnInit} from '@angular/core';
import {ConsoleFilter} from '../../../shared/models/console-filters';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-crud-filters',
  templateUrl: './crud-filters.component.html',
  styleUrls: ['./crud-filters.component.scss']
})
export class CrudFiltersComponent implements OnInit {

  @Input() public consoleFilter: ConsoleFilter;

  public formGroup: FormGroup;
  public enumTypeString: ConsoleFilter.Type = ConsoleFilter.Type.String;
  public enumTypeDate: ConsoleFilter.Type = ConsoleFilter.Type.Date;
  public enumTypeComboBox: ConsoleFilter.Type = ConsoleFilter.Type.ComboBox;
  public enumTypeNumber: ConsoleFilter.Type = ConsoleFilter.Type.Number;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.setFormGroup();
  }

  getStringSearch(event: any): void {
    let search = '';
    for (const element of event.srcElement) {
      if (element.value !== undefined && element.value.length > 0) {
        search += search.length <= 0 ? '?' : '&';
        search += `${element.id}=${element.value}`;
      }
    }
    this.consoleFilter.searchFunction(search);
  }

  setFormGroup(): void {
    this.formGroup = this.formBuilder.group({});
    for (const filter of this.consoleFilter.filters) {
      this.formGroup.addControl(filter.name, this.formBuilder.control(filter.defaultValue, filter.validators));
    }
  }


}
