import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';

@Injectable({
  providedIn: 'root'
})
export class UbicacionGeografica {
  private environmentUrl: any = {};
  httpOptions: any;

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
    this.environmentUrl = this.localStorage.getObject(KEYS_STORAGE.url) || {};

    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', })
    }
  }

  public getAll = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/ubicacion_geografica`;
    return this.http.get(url, this.httpOptions);
  }

  public getArgs = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/ubicacion_geografica/args`;
    return this.http.get(url, this.httpOptions);
  }

  public post = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/ubicacion_geografica`;
    return this.http.post(url, JSON.stringify(body), this.httpOptions);
  }

  public put = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/ubicacion_geografica`;
    return this.http.put(url, JSON.stringify(body), this.httpOptions);
  }

  public find = (id: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/ubicacion_geografica/${id}`;
    return this.http.post(url, this.httpOptions);
  }

  public delete = (id: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/ubicacion_geografica/${id}`;
    return this.http.delete(url, this.httpOptions);
  }
}