import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
// SERVICES
import { UserService } from "@shared/user.service";
import { AdminListas } from "@shared/admin-listas.service";
import { MatSnackBar } from "@angular/material";
import { ListasControlAutReg } from "@shared/listas-control-autorizacion_reg.service";
import { TimerNoti } from "src/app/core/utils/timer";
import { ConfirmacionGradoComponent } from "../../confirmacion-grado/confirmacion-grado.component";

@Component({
  selector: "app-queries-status",
  templateUrl: "./queries-status.component.html",
  styleUrls: ["./queries-status.component.scss"],
})
export class QueriesStatusComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  form!: FormGroup;
  statuses: any[] = [];
  item: any;
  loading = false;
  showActions = false;
  isVentas = false;
  fechaEvaluacionInicial = "";

  constructor(
    public dialogRef: MatDialogRef<QueriesStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,    
    private userService: UserService,
    private adminListasService: AdminListas,
    public listasControlAutRegService: ListasControlAutReg,
    private snackBar: MatSnackBar,
    private timer: TimerNoti
  ) {
    this.item = this.data.data;
    this.statuses = this.data.status;
    this.fechaEvaluacionInicial =
      this.data.type !== "auth-ventas"
        ? this.item.fecha_evaluacion_inicial
        : this.item.fecha_consulta;
    this.configConfigActions();
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get isAuthorized() {
    return this.item.idAutorizacionInicial !== null;
  }

  get nameUser() {
    return this.userService.user.nombre;
  }

  configConfigActions() {
    if (this.item.actual == 1 || this.item.actual == "1") {
      if (this.item.num_Aprobaciones == 1) {
        if (
          this.item.status_AprobIni == "Rechazado" ||
          this.item.status_AprobIni == "Aprobado"
        ) {
          this.showActions = false;
        } else {
          this.showActions = true;
          if (
            this.nameUser == this.item.usuario_AprobIni ||
            this.nameUser == this.item.usuario_AprobIni2
          ) {
            this.showActions = false;
          }
        }
      } else if (this.item.num_Aprobaciones == 2) {
        if (
          (this.item.status_AprobIni == "Rechazado" ||
            this.item.status_AprobIni == "Aprobado") &&
          (this.item.status_AprobIni2 == "Rechazado" ||
            this.item.status_AprobIni2 == "Aprobado")
        ) {
          this.showActions = false;
        } else {
          this.showActions = true;
          if (
            this.nameUser == this.item.usuario_AprobIni ||
            this.nameUser == this.item.usuario_AprobIni2
          ) {
            this.showActions = false;
          }
        }
      }
    } else {
      this.showActions = false;
    }
  }

  close() {
    this.dialogRef.close({ success: false });
  }

  createForm() {
    this.form = new FormGroup({
      statusId: new FormControl(null, [Validators.required]),
    });
  }

  startTransaction($event: Event) {
    $event.preventDefault();
    if (this.form.valid) {
      this.item.sistemaId === "GD_LC" ? this.saveGD() : this.save();
    } else {
      this.snackBar.open(`Selecciona un estatus`, "error", { duration: 2500 });
    }
  }

  createPayload() {
    const statusId = Number(this.form.value.statusId);
    const { status } = this.statuses.find((x) => x.idStatus === statusId);
    return {
      ...this.item,
      EstatusAutorizacionInicial: status,
      user: this.userService.user.nombre,
      idAutIniNuevo: Number(this.form.value.statusId),
    };
  }

  save() {
    const dialogRefConfirm = this.dialog.open(ConfirmacionGradoComponent, { hasBackdrop: false });
        dialogRefConfirm.afterClosed().subscribe(resDialogConfirm => {
          if (resDialogConfirm == true) {

            const data = this.createPayload();
            this.loading = true;
            this.timer.startTimer();
            console.log("initTimer")
            this.subscription.add(
              this.adminListasService.putUpdAprobacionGradoRiesgoI(data).subscribe({
                next: (res) => {
                  this.loading = false;
                  this.timer.clearTimer();
                  if (res.Status[0][0] == "Modificado") {
                    this.snackBar.open(`Se ha autorizado correctamente`, "ok", {
                      duration: 2500,
                    });
                    this.dialogRef.close({ success: true });
                  }else{
                    this.snackBar.open("Sucedio un error en el servicio", "close");
                  }
                  
                },
                error: (err: HttpErrorResponse) => {
                  this.loading = false;
                  this.timer.clearTimer();
                  console.error(err);
                  this.snackBar.open("Sucedio un error en el servicio", "close");
                },
              })
            );
          }
        });
  }

  saveGD() {
    const data = this.createPayload();
    this.loading = true;
    this.timer.startTimer();
    console.log("initTimer")
    this.subscription.add(
      this.adminListasService.putUpdAprobacionGradoRiesgoGD(data).subscribe({
        next: (res) => {
          this.loading = false;
          this.timer.clearTimer();
          if (res.error) {
            this.snackBar.open("Sucedio un error en el servicio", "error");
          } else {
            this.snackBar.open(`Se ha autorizado correctamente`, "ok", {
              duration: 2500,
            });
            this.dialogRef.close({ success: true });
          }
        },
        error: (err: HttpErrorResponse) => {
          this.loading = false;
          this.timer.clearTimer();
          console.error(err);
          this.snackBar.open("Sucedio un error en el servicio", "close");
        },
      })
    );
  }
}
