export const TOOLBAR = [
  {
    location: "after",
    widget: "dxButton",
    locateInMenu: "auto",
    options: {
      width: 100,
      text: "Cambiar Nivel de Riesgo",
    },
    visible: false,
    name: "simple",
  },
  {
    location: "after",
    widget: "dxButton",
    locateInMenu: "auto",
    options: {
      width: 90,
      text: "Autorizar",
    },
    visible: false,
    name: "simple",
  },
]