import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';

@Injectable({
  providedIn: 'root'
})
export class AdminListas {
  private environmentUrl: any = {};
  httpOptions: any;

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
    this.environmentUrl = this.localStorage.getObject(KEYS_STORAGE.url) || {};

    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', })
    }

  }

  public getHits = (tabla: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/adminListas/?tabla=${tabla}`;
    return this.http.get(url, this.httpOptions);
  }

  public getStatus = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/adminListas/status`;
    return this.http.get(url, this.httpOptions);
  }

  public updateAprobacionSemestra = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/aprobaciones/semestral`;
    return this.http.put(url, JSON.stringify(body), this.httpOptions);
  }

  public getHitsByParams = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/adminListas/params`;
    return this.http.post(url, body, this.httpOptions);
  }

  public getHitsByParamsRep(body: any): Observable<any> {
    const url = `${this.environmentUrl.listasControlUrl}/adminListasRep/params`;
    return this.http.post(url, body, this.httpOptions);
  }

  public getSistemas = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/adminListas/sistemas`;
    return this.http.get(url, this.httpOptions);
  }

  public getOficinas = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/adminListas/oficinas`;
    return this.http.get(url, this.httpOptions);
  }

  public getStatus_l = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/status`;
    return this.http.get(url, this.httpOptions);
  }

  public putUpdAprobacionesInicial = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/update_aprobacionesI`;
    return this.http.put(url, JSON.stringify(body), this.httpOptions);
  }

  public putUpdAprobacionesSemestral = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/update_aprobacionesS`;
    return this.http.put(url, JSON.stringify(body), this.httpOptions);
  }

  public putUpdAprobacionGradoRiesgoI = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/config_nivel_riesgo/update_aprobacion_gr_I`;
    return this.http.put(url, JSON.stringify(body), this.httpOptions);
  }

  public putUpdAprobacionHitGeo = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/control-list-back/api/v1-0/autorizacion/hit_geolocalizacion`;
    return this.http.put(url, JSON.stringify(body), this.httpOptions);
  }

  public putUpdAprobacionGradoRiesgoGD = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/config_nivel_riesgo/update_aprobacion_gr_I_gd`;
    return this.http.put(url, JSON.stringify(body), this.httpOptions);
  }

  public getAllNameLC = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/nombreLC`;
    return this.http.get(url, this.httpOptions);
  }
}