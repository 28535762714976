import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthconfigService} from '../services/authconfig.service';
import {UserService} from "@shared/user.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authConfigService: AuthconfigService,
    private router: Router,
    public userService: UserService,
  ) {
  }

  canActivate() {
    // tslint:disable-next-line:triple-equals
   /*
    if (!this.authConfigService.authUserExists) {
      this.router.navigate(['/login']);
      return false;
    } else {
      if (this.authConfigService.currentUserType === 'banco' || this.authConfigService.currentUserType === 'yastas') {
        console.log('es banco o yastas');
        return true;
      }
    }
    return false;
  }

  */
    if(this.userService.getUserLogIn()) {
      if(this.authConfigService.currentUser) {
        if(this.authConfigService.currentUserType === 'banco' || this.authConfigService.currentUserType === 'yastas') {
          return true;
        }
      }
    }
    this.router.navigate(['/']);
    return false;
  }

}
