import { Component, OnInit ,Inject} from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { ListasControlWhite } from '@shared/listas-control-white.service';
import { ConsultaBitacora} from '@shared/consulta-bitacora.service';
import { ConsultaPersonales} from '@shared/consulta-datos-personales.service';
import {
  IColumns,
  IExportExcel,
  IScroll,
  Toolbar,
  IColumnHiding,
  ICheckbox,
  IEditing,
  IColumnchooser
} from '@models/grid.interfaces'

@Component({
  selector: 'app-listas-control-white',
  templateUrl: './listas-control-white.component.html',
  styleUrls: ['./listas-control-white.component.scss']
})
export class ListasControlWhiteComponent implements OnInit {
    /* *************** Parametros del Grid de datos ***************** */
    columns: IColumns[];
    toolbar: Toolbar[];
    exportExcel: IExportExcel;
    columnHiding: IColumnHiding;
    Checkbox: ICheckbox;
    Editing: IEditing;
    Columnchooser: IColumnchooser;
    scroll: IScroll;
    datosPost: any;
    datosevent: any;
    onExportingFun: Function;
    onExportedFun: Function;
    /* *************** Parametros del Grid Find de datos ***************** */
    columnsF: IColumns[];
    toolbarF: Toolbar[];
    exportExcelF: IExportExcel;
    columnHidingF: IColumnHiding;
    CheckboxF: ICheckbox;
    EditingF: IEditing;
    ColumnchooserF: IColumnchooser;
    scrollF: IScroll;
    datosFind: any;
    datoseventFind: any;
    /* *************** Otros ***************** */
    waitFind: boolean = true;
    nombresInput: string='';
    idT: string='';
    showF: boolean = false;
    /* *************** Otros ***************** */
    wait: boolean = false;
    waitFaile: boolean = false;
    show: boolean = false;
    constructor(
      public dialog: MatDialog,
      private route: Router,
      public snackBar: MatSnackBar,
      public _route: ActivatedRoute,
      public userService: UserService,  
      public dataService: ListasControlWhite,
      public dataServiceCB: ConsultaBitacora,
      public dataServiceDP: ConsultaPersonales,
      
    ) {
      if (!userService.getUserLogIn()) route.navigate(['./login']);
      this.datosPost = [];
      this.datosFind = [];
  
      /* *************** Parametros del Grid de datos ***************** */
      this.exportExcel = { enabled: true, fileName: 'Listado de documentos' };
      this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
      this.Checkbox = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
      this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
      this.Columnchooser = { columnchooser: false }; // parametros de para seleccion de columnas
  
      this.scroll = { mode: 'standard' }; // Parametros de Scroll
      this.columns = [ // Columnas
        {
          caption: 'Id',
          dataField: "id",
          hiddingPriority: '0'
        },
        {
          caption: 'Nombre',
          dataField: 'nombres',
          hiddingPriority: '0'
        },
        {
          caption: 'Dirección',
          dataField: 'direccion',
          hiddingPriority: '0'
        },
        {
          caption: 'Identificación',
          dataField: 'tipo_identificacion',
          hiddingPriority: '0'
        },
        {
          caption: 'Num Identificación',
          dataField: 'num_identificacion',
          hiddingPriority: '0'
        },
        {
          caption: 'Ident. Tributaria',
          dataField: 'identificacion_tributaria',
          hiddingPriority: '0'
        },
        {
          caption: 'Estatus',
          dataField: 'status',
          hiddingPriority: '0'
        },
      ];
  
      this.toolbar = [
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 90,
            text: 'Agregar',
            onClick: this.receiveMessageDoc.bind(this, 'add')
          },
          visible: true
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 110,
            text: 'Actualizar',
            onClick: this.receiveMessageDoc.bind(this, 'loadD')
          },
          visible: true
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 90,
            text: 'Editar',
            onClick: this.receiveMessageDoc.bind(this, 'edt')
          },
          visible: false,
          name: 'simple'
        }
      ];

      /* *************** Parametros del Grid de datos ***************** */
      this.exportExcelF = { enabled: true, fileName: 'Listado de documentos' };
      this.columnHidingF = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
      this.CheckboxF = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
      this.EditingF = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
      this.ColumnchooserF = { columnchooser: false }; // parametros de para seleccion de columnas
  
      this.scrollF = { mode: 'standard' }; // Parametros de Scroll
      this.columnsF = [ // Columnas
        {
          caption: 'Id',
          dataField: "id",
          hiddingPriority: '0'
        },
        {
          caption: 'Nombre',
          dataField: 'nombres',
          hiddingPriority: '0'
        },
        {
          caption: 'Ident. Tributaria',
          dataField: 'identificacion_tributaria',
          hiddingPriority: '0'
        },
        {
          caption: 'Fecha Consulta',
          dataField: 'fecha_consulta',
          hiddingPriority: '0'
        },
        {
          caption: 'Tabla',
          dataField: 'tabla_consultada',
          hiddingPriority: '0'
        },
        {
          caption: 'Estatus',
          dataField: 'status',
          hiddingPriority: '0'
        },
      ];

      this.toolbarF = [    
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 90,
            text: 'Detalles',
            onClick: this.receiveMessageFind.bind(this, 'det')
          },
          visible: true
        },
        ];
    }
    receiveMessageFind($event) {
      try {
        switch ($event) {
          case 'det':
            if (this.datoseventFind && this.datoseventFind.length > 0) {
              let d = this.datoseventFind[0];
              this.det(d);
            }else{
              console.error("SIN DETALLES");

            }
            break;
          case 'edt':
            if (this.datosevent && this.datosevent.length > 0) {
              let d = this.datosevent[0];
              this.upd(d)
            }
            break;
          case 'loadD':
            this.loadData();
            break;
        }
      } catch (err) {
        let msj = err.message;
        try {
          msj = err.error.error.message + '\n' + err.error.text;
        } catch{ }
        this.snackBar.open(msj, 'close');
      }
    }

    receiveMessageDoc($event) {
      try {
        switch ($event) {
          case 'add':
            this.add();
            break;
          case 'edt':
            if (this.datosevent && this.datosevent.length > 0) {
              let d = this.datosevent[0];
              this.upd(d)
            }
            break;
          case 'loadD':
            this.loadData();
            break;
        }
      } catch (err) {
        let msj = err.message;
        try {
          msj = err.error.error.message + '\n' + err.error.text;
        } catch{ }
        this.snackBar.open(msj, 'close');
      }
    }
  
    datosMessage($event) {
      try {
        this.datosevent = $event.data;
      } catch (err) {
        let msj = err.message;
        try {
          msj = err.error.error.message + '\n' + err.error.text;
        } catch{ }
        this.snackBar.open(msj, 'close');
      }
    }

    datosMessageFind($event) {
      try {
        this.datoseventFind = $event.data;
      } catch (err) {
        let msj = err.message;
        try {
          msj = err.error.error.message + '\n' + err.error.text;
        } catch{ }
        this.snackBar.open(msj, 'close');
      }
    }
  
    ngOnInit() {
      this.loadData()
    }
  
    loadData() {
      this.wait = true;
      this.dataService.getAll().toPromise().then((res) => { 
        this.datosPost = res; 
        if(this.datosPost['Code'] == "404"){
          this.datosPost = [];
          this.wait = false;
          this.show = false;
        }else{
          this.datosPost = res; 
          this.wait = false;
          this.show = true;
        }    
      })
    }
    
    
    add() {    
      const dialogRef = this.dialog.open(AddListWhiteComponent, { });
      dialogRef.afterClosed().subscribe(resDialog => {
        if (resDialog) {
          let infoLista = resDialog['nombre'];

          this.dataService.post(resDialog).toPromise().then((res) => {
            this.loadData();
            this.snackBar.open(`Se ha dado de alta correctamente`, 'ok', { duration: 2500 });
          }).catch((err) => {
            this.wait = false;
            let msj = err.message;
            try {
              msj = err.error.error.message + '\n' + err.error.text;
            } catch{ }
            this.snackBar.open(msj, 'close');
          });

          /*this.dataServiceDP.post(resDialog).toPromise().then((res) => {
            this.loadData();
            this.snackBar.open(`Se ha dado de alta correctamente en Datos Personales`, 'ok', { duration: 2500 });
          }).catch((err) => {
            this.wait = false;
            let msj = err.message;
            try {
              msj = err.error.error.message + '\n' + err.error.text;
            } catch{ }
            this.snackBar.open(msj, 'close');
          });*/
        }
      });
    }

    findArgs() {
      this.waitFind = true;
      let req = '{"nombres": "'+this.nombresInput+'", "identificacion_tributaria": "'+this.idT+'", "lista": "0"}';

      this.dataServiceCB.find(req).toPromise().then((res) => {
        this.datosFind = [];
        this.waitFind = false;
        if(res['Code'] != "404"){
          this.datosFind = res; 
          if(this.datosFind['tabla_consultada']=="lista_blanca"){
            this.waitFind = false;
            this.datosFind = res;
            this.snackBar.open(`Se han encontrado los siguientes registros`, 'ok', { duration: 2500 });
          }else{
            this.datosFind = [];
          }
        }
      }).catch((err) => {
        this.waitFind = true;
        let msj = err.message;
        try {
          msj = err.error.error.message + '\n' + err.error.text;
        } catch{ }
        this.snackBar.open(msj, 'close');
      });      
    }

    upd(datos) {
      const dialogRef = this.dialog.open(UpdListWhiteComponent, { data: datos});
      
      dialogRef.afterClosed().subscribe(resDialog => {
        if (resDialog) {
          this.dataService.put(resDialog).toPromise().then((res) => {
            this.loadData();
            this.snackBar.open(`Se ha actualizado correctamente`, 'ok', { duration: 2500 });
          }).catch((err) => {
            this.wait = false;
            let msj = err.message;
            try {
              msj = err.error.error.message + '\n' + err.error.text;
            } catch{ }
            this.snackBar.open(msj, 'close');
          });
        }
      });
    }

    det(datos) {     
      let req = '{"nombres": "'+datos['nombres']+'", "identificacion_tributaria": "'+datos['identificacion_tributaria']+'"}';   
      this.dataServiceDP.getDet(datos['identificacion_tributaria']).toPromise().then((res) => {
        if(res['Code'] == "404"){
          this.datosFind = [];
          this.waitFind = false;
        }else{
          this.datosFind = res;
          this.waitFind = false;
          this.snackBar.open(`Se han encontrado los siguientes registros`, 'ok', { duration: 2500 });

        }
        
      }).catch((err) => {
        this.wait = false;
        let msj = err.message;
        try {
          msj = err.error.error.message + '\n' + err.error.text;
        } catch{ }
        this.snackBar.open(msj, 'close');
      });
    }
   
}
interface Status {
  value: string;
  viewValue: string;
}

interface Status {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-add-listas-control-white',
  templateUrl: './add.component.html',
})
export class AddListWhiteComponent {
  data: any;
  nombre = 'Agrega ';
  
  status: Status[] = [
    {value: '0', viewValue: 'Desactivado'},
    {value: '1', viewValue: 'Activado'}
  ];
  
  constructor(public dialogRef: MatDialogRef<AddListWhiteComponent> , @Inject(MAT_DIALOG_DATA) public data2 ) {
    this.data = {}
    this.data.segundo_nombre = "";
    this.data.nombre = "";
    this.data.nombres = this.data.nombre +" "+ this.data.segundo_nombre;
   }

  formControl = new FormControl('', [Validators.required]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }  

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-upd-listas-control-white',
  templateUrl: './upd.component.html',
})
export class UpdListWhiteComponent {
  nombre = 'Edita '
  sta: Status[] = [
    {value: '0', viewValue: 'Desactivado'},
    {value: '1', viewValue: 'Activado'}
  ];

  constructor(public dialogRef: MatDialogRef<UpdListWhiteComponent>, @Inject(MAT_DIALOG_DATA) public data: any) 
  { 
    if(data.status == 1){
      data.status = this.sta[1].value

    }
    else{
      data.status = this.sta[0].value
    }
  }

  formControl = new FormControl('', [ Validators.required
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
