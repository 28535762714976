import {Component, OnInit} from '@angular/core';
import {CrudComponent} from '../crud/crud.component';
import {ConsoleFilter} from '../../../shared/models/console-filters';

@Component({
  selector: 'app-crud-test',
  templateUrl: '../crud/crud.component.html',
  styleUrls: ['../crud/crud.component.scss']
})
export class CrudTestComponent extends CrudComponent implements OnInit {

  constructor() {
    super();
  }

  // Screen title parameters
  title = 'Cambio de grado de riesgo';
  icon = 'analytics';

  // LISTS
  grados = [
    {id: 1, name: 'Bajo'},
    {id: 2, name: 'Medio'},
    {id: 3, name: 'Alto'},
    {id: 4, name: 'Inaceptable'}
  ];
  oficinas: any;

  // CONSTS
  personFilters = {
    searchFunction: this.searchWithFilter,
    filters: [
      {
        name: 'grado',
        defaultValue: '',
        placeholder: 'Grado',
        type: ConsoleFilter.Type.ComboBox,
        options: this.grados,
        optionKey: 'name'
      },
      {
        name: 'oficinas',
        defaultValue: '',
        placeholder: 'Oficina',
        type: ConsoleFilter.Type.ComboBox,
        options: this.oficinas,
        optionKey: 'nombreOficina'
      },
      {
        name: 'bp',
        defaultValue: '',
        placeholder: 'BP',
        type: ConsoleFilter.Type.String
      }
    ]
  };
  generalFilters = {
    searchFunction: this.searchWithFilter,
    filters: [
      {
        name: 'grado',
        defaultValue: '',
        placeholder: 'Grado',
        type: ConsoleFilter.Type.ComboBox,
        options: this.grados,
        optionKey: 'name'
      },
      {
        name: 'startdate',
        defaultValue: '',
        placeholder: 'Fecha de inicio',
        type: ConsoleFilter.Type.Date
      },
      {
        name: 'endDate',
        defaultValue: '',
        placeholder: 'Fecha de fin',
        type: ConsoleFilter.Type.Date
      }
    ]
  };


  // OVERRIDE FUNCTIONS.
  createFilters() {
    this.filters = this.personFilters;
  }

}
