import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  IColumns,
  IExportExcel,
  IScroll,
  Toolbar,
  IColumnHiding,
  ICheckbox,
  IEditing,
  IColumnchooser,
} from "@models/grid.interfaces";
import { MatSnackBar } from "@angular/material";
import { UserService } from "@shared/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { CargaMasivaService } from "@shared/carga-masiva.service";
import { BitacoraConsultasDevService } from "@shared/bitacora-consultas-dev.service";
import { ListasControlConsultaHits } from "@shared/listas-control-consulta-hits.service";
import { ListasControlAutReg } from "@shared/listas-control-autorizacion_reg.service";
import { AdminListas } from "@shared/admin-listas.service";
import { SecureStorage } from "@utils/secure-storage";
import { Subscription } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { COLUMNS_ALTO, COLUMNS_ALTO_PEP, COLUMNS_BMI } from "./COLUMNS";
import { TOOLBAR } from "./TOOLBAR";
import { formatDateTime, formatDate } from "src/app/core/utils/date";
import { formatDateGR } from "src/app/core/utils/dateConvert";

@Component({
  selector: "app-lc-consulta-gr-resp",
  templateUrl: "./lc-consulta-gr-resp.component.html",
  styleUrls: ["./lc-consulta-gr-resp.component.scss"],
})
export class LcConsultaGrRespComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  wait: boolean = true;
  datosHits: any;
  datosLC: any;
  datosNivelRiesgo: any;
  datosStatus: any;
  listaActEconomica: any;
  listaTipoPersona: any;
  listaProdServ: any;
  listaODRecursos: any;
  listaUbicacionGeo: any;
  listaVolOper: any;
  maxDate = new Date();
  columns: IColumns[];
  toolbar: Toolbar[];
  toolbarRisk: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  inicio: string = "";
  fin: string = "";
  estatus: string = "";
  listasNegras = null;
  coincidencia_tabla = "";
  select_datosBC: any;

  gradoRiesgos = [];
  riesgo: string = "";
  usuario: any;
  nomina: any;
  riesgo_temp: any;

  color = "primary";
  mode = "indeterminate";
  value = 50;
  displayProgressSpinner = false;
  url: any;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,
    public cargaMasiva: CargaMasivaService,
    public consultaHitsService: ListasControlConsultaHits,
    public bitacoraConsultasDevService: BitacoraConsultasDevService,
    public dataServiceAut: ListasControlAutReg,
    public dataServiceAdminListas: AdminListas
  ) {
    this.datosHits = [];
    this.wait = true;
    this.getIsAuthentication();
    this.paramsOfGridDataInitial();
    this.toolbarRisk = [
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          width: 90,
          text: "Cambiar Estatus",
          onClick: this.receiveMessageRisk.bind(this, "change_status"),
        },
        visible: false,
        name: "simple",
      },
    ];
  }

  ngOnInit() {
    this.loadData();
    this.getAllNameLC();
    this.wait = true;
    this.datosHits = null;
    this.datosLC = [];
    this.datosNivelRiesgo = [];
    this.getAllNivelRiesgo();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getIsAuthentication() {
    if (!this.userService.getUserLogIn()) this.route.navigate(["./login"]);
  }

  paramsOfGridDataInitial() {
    //Grid de Datos para mostrar Hits
    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: "Listado de Información" };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: "multiple" }; // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: true }; // parametros de para seleccion de columnas
    this.scroll = { mode: "standard" }; // Parametros de Scroll
    this.toolbar = TOOLBAR;
  }

  getAllNameLC() {
    this.listasNegras = null;
    this.dataServiceAdminListas
      .getAllNameLC()
      .toPromise()
      .then((data: any) => {
        this.listasNegras = data;
      })
      .catch((err) => {
        this.listasNegras = null;
      });
  }

  getErrorMessage() {}

  cambiarEtiquetas(data) {
    if (data.value != "LPEP" && this.datosStatus && this.datosStatus[2]) {
      this.datosStatus[2].statusFront = "PENDIENTE POR AUTORIZAR";
    } else if (
      data.value == "LPEP" &&
      this.datosStatus &&
      this.datosStatus[2]
    ) {
      this.datosStatus[2].statusFront = "EN ANALISIS";
    }
  }

  getAllNivelRiesgo() {
    this.datosNivelRiesgo = null;
    this.consultaHitsService
      .getAllNivelRiesgo()
      .toPromise()
      .then((data: any) => {
        this.gradoRiesgos = data;
        this.gradoRiesgos.push({ nombre: "Alto + PEP" });
      })
      .catch((err) => {
        this.datosNivelRiesgo = null;
      });
  }

  setWrite(name) {
    let user = JSON.parse(
      SecureStorage.decrypt(localStorage.getItem("currentUser"))
    );
    let permissions = user.permissions;
    if (permissions.length > 0) {
      let element = permissions.filter((d) => d.name == name);
      if (element.length > 0) {
        return element[0].write;
      }
      return true;
    }
    return true;
  }

  configToolbar() {
    if (this.setWrite(this.riesgo)) {
      this.toolbarRisk = [
        {
          location: "after",
          widget: "dxButton",
          locateInMenu: "auto",
          options: {},
          visible: false,
          name: "simple",
        },
      ];
    } else {
      this.toolbarRisk = [];
    }
  }

  configColumns() {
    switch (this.riesgo) {
      case "Alto + PEP":
        this.columns = COLUMNS_ALTO_PEP;
        break;
      case "Alto":
        this.columns = COLUMNS_ALTO;
        break;
      default:
        this.columns = COLUMNS_BMI;
        break;
    }
  }

  // formatData(datos: any[]) {
  //   return datos.map((x: any) => ({
  //     ...x,
  //     fecha_inicial: formatDateTime(x.fecha_inicial),
  //     FechaDeteccion: formatDateTime(x.FechaDeteccion),
  //     fecha_semestral: formatDateTime(x.fecha_semestral),
  //     fecha_AprobIni: formatDateTime(x.fecha_AprobIni),
  //     fecha_AprobIni2: formatDateTime(x.fecha_AprobIni2),
  //     fecha_consulta_GR: formatDateTime(x.fecha_consulta_GR),
  //     fecha_nacimiento: formatDate(x.fecha_nacimiento),
  //   }));
  // }

  loadBitacoraConsultasDev() {
    this.wait = true;
    this.riesgo_temp = this.riesgo;
    this.displayProgressSpinner = true;
    this.datosHits = [];
    this.configToolbar();
    this.configColumns();
    const body = {
      inicio: this.inicio,
      fin: this.fin,
      estatus: this.estatus,
      riesgo: this.riesgo,
      coincidencia_tabla: this.coincidencia_tabla,
    };

    this.subscription.add(
      this.bitacoraConsultasDevService
        .getGradoRiesgoByParamsRep(body)
        .subscribe({
          next: (res) => {
            if (res.datos === "sin registro") {
              this.snackBar.open("No se encontraron registros", "cerrar");
            } else if (res.Status != "False") {
              const { datos } = res;
              this.datosHits = formatDateGR(res.datos || []);
              const messageNoContent =
                "No existen hits con estos filtros, no se encontraron registros";
              datos.length === 0 &&
                this.snackBar.open(messageNoContent, "cerrar");
            } else {
              this.snackBar.open("Error de servidor", "cerrar");
            }
          },
          error: (err: HttpErrorResponse) => {
            console.log(err);
            this.snackBar.open("Error de servidor", "cerrar");
          },
        })
        .add(() => {
          this.wait = false;
          this.displayProgressSpinner = false;
        })
    );
  }

  limpiarFiltros() {
    this.riesgo = "";
    this.inicio = "";
    this.fin = "";
    this.estatus = "";
    this.coincidencia_tabla = "";
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + "\n" + err.error.text;
      } catch {}
      this.snackBar.open(msj, "close");
    }
  }

  loadData() {
    this.dataServiceAdminListas
      .getStatus()
      .toPromise()
      .then((res) => {
        this.datosStatus = res;
        if (this.datosStatus["Code"] == "404") {
          this.datosStatus = null;
        }
      });
  }

  receiveMessageRisk($event) {
    try {
      switch ($event) {
        case "change_status":
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
          }
          break;
        case "loadD":
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + "\n" + err.error.text;
      } catch {}
      this.snackBar.open(msj, "close");
    }
  }
}
