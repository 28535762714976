import { ValidatorFn } from '@angular/forms';

export class ConsoleFilter {

  constructor(
    public filters?: ConsoleFilter.Filter[],
    public searchFunction?: (arg:any) => any
  ) { }
}

// tslint:disable-next-line:no-namespace
export namespace ConsoleFilter {
  export enum Type {
    String,
    ComboBox,
    Date,
    Number
  }

  export class Filter {
    public name!: string;
    public defaultValue!: any;
    public placeholder!: string;
    public type!: ConsoleFilter.Type;
    public validators?: ValidatorFn[];
    public options?: ConsoleFilter.Option[];
    public optionKey?: string;
  }

  export class Option {
    id!: string;
    name!: string;
  }
}
