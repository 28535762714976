import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService } from "./api.service";
import { Enterprise } from "../models/enterprise";
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root'
})
export class EnterpriseService {

  private readonly endPointEnterprise = 'enterprises/';
  constructor(
    private api: APIService,
    private usr: UserService
  ) {

   }
  getEnterpriseAll(): Observable<Enterprise[]> {
    return this.api.get(this.endPointEnterprise);
  }
  getEnterprise(idEnterprise: number): Observable<Enterprise> {
    return this.api.get(`${this.endPointEnterprise}${idEnterprise}`)
  }
  CreateEnterprise(dataEnterprise: Enterprise) {
    const dataSend: any = Object.assign({payroll_number: this.usr.user.nomina}, dataEnterprise);
    return this.api.post(`${this.endPointEnterprise}`,dataSend);
  }
  UpdateEnterprise(id,dataEnterprise: Enterprise): Observable<any> {
    const dataSend: any = Object.assign({payroll_number: this.usr.user.nomina}, dataEnterprise);
    return this.api.put(`${this.endPointEnterprise}${id}`,dataSend);
  }
  DeleteEnterprise(idEnterprise: number) {
    return this.api.delete(`${this.endPointEnterprise}${this.usr.user.nomina}/${idEnterprise}`)
  }
}
