import { OnInit ,Inject, Component} from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';

import { CargaMasivaService } from '@shared/carga-masiva.service';
import { SystemsService } from '@shared/systems.services';
import { SecureStorage } from '@utils/secure-storage';

SystemsService
import {
  IColumns,
  IExportExcel,
  IScroll,
  Toolbar,
  IColumnHiding,
  ICheckbox,
  IEditing,
  IColumnchooser
} from '@models/grid.interfaces'

@Component({
  selector: 'app-systems',
  templateUrl: './systems.component.html',
  styleUrls: ['./systems.component.scss']
})
export class SystemsComponent implements OnInit {

  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  columnsHist: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosPost: any;
  datosEntities: any;
  datosProductos : any;
  datosServicios :  any;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;
  /* *************** Otros ***************** */

  wait: boolean = false;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,
    public systemsService: SystemsService,
    public cargaMasiva: CargaMasivaService,
  ) { 
    this.getIsAuthentication();
    this.paramsOfGridDataInitial();
  }
  
  ngOnInit() {
    this.getAllSystems();
  }

  paramsOfGridDataInitial(){
    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: 'Listado de sistemas' };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: false }; // parametros de para seleccion de columnas

    this.scroll = { mode: 'standard' }; // Parametros de Scroll
    this.columns = [ // Columnas
      {
        caption: 'id',
        dataField: 'systemId',
        hiddingPriority: '1',
        width: 150
      },
      {
        caption: 'Nombre',
        dataField: 'systemName',
        hiddingPriority: '1'
      },
      {
        caption: 'Descripción',
        dataField: 'description',
        hiddingPriority: '1'
      },
    ];

    this.toolbar = [
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Agregar',
          onClick: this.receiveMessageDoc.bind(this, 'add')
        },
        visible: true
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 110,
          text: 'Actualiza',
          onClick: this.receiveMessageDoc.bind(this, 'upd')
        },
        visible: true
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Elimina',
          onClick: this.receiveMessageDoc.bind(this, 'del')
        },
        visible: false,
        name: 'simple'
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Edita',
          onClick: this.receiveMessageDoc.bind(this, 'edt')
        },
        visible: false,
        name: 'simple'
      }
    ];
  }
  
  addSystem() {    
    const dialogRef = this.dialog.open(AddSystemComponent , {});

    dialogRef.afterClosed().subscribe(resDialog => {
      let cUser = JSON.parse(SecureStorage.decrypt(localStorage.getItem('currentUser')));
      resDialog['nomina'] = cUser.nomina
      if (resDialog) {
        this.systemsService.postSystem(resDialog).toPromise().then((res) => {
          /* this.loadData();*/
          this.snackBar.open(`Se ha dado de alta correctamente`, 'ok', { duration: 2500 });
          this.getAllSystems();
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch{ }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }
  
  delSystem(datos) {
    const dialogRef = this.dialog.open(DelSystemComponent, {
       data: datos
    });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.systemsService.delSystem(resDialog.systemId).toPromise().then((res) => {
          this.snackBar.open(`Se ha dado de eliminado correctamente`, 'ok', { duration: 2500 });
          this.getAllSystems();
          }).catch((err) => {
            this.wait = false;
            let msj = err.message;
            try {
              msj = err.error.error.message + '\n' + err.error.text;
            } catch{ }
              this.snackBar.open(msj, 'close');
          });
        }
    });
  }

  updateSystem(datos) {
    const dialogRef = this.dialog.open(UpdSystemComponent, {
      data: datos
    });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        let cUser = JSON.parse(SecureStorage.decrypt(localStorage.getItem('currentUser')));
        resDialog['nomina'] = cUser.nomina;
        this.systemsService.putSystem(resDialog).toPromise().then((res) => {
          /* this.loadData(); */
          this.snackBar.open(`Se ha dado de actualizado correctamente`, 'ok', { duration: 2500 });
          this.getAllSystems();
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch{ }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch{ }
      this.snackBar.open(msj, 'close');
    }
  }

  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case 'add':
          this.addSystem();
          break;
        case 'del':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.delSystem(d)
          }
          break;
        case 'edt':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.updateSystem(d)
          }
          break;
        case 'upd':
          this.getAllSystems();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch{ }
      this.snackBar.open(msj, 'close');
    }
  }

  getAllSystems(){
    this.wait = true;
    this.datosPost = null;
    this.systemsService.getCatalogo().toPromise().then((data: any) => {
      this.wait = false;
      this.datosPost = data;
    }).catch((err) => {
      this.wait = false;
      this.datosPost = null;
    });
  }

  getIsAuthentication(){
    if (! this.userService.getUserLogIn()) this.route.navigate(['./login']);
    this.datosPost = [];
  }

  
}

//*****  entryComponentAdd **************/
@Component({ 
  selector: 'app-add-system',
  templateUrl: './add.component-system.html'
})

export class AddSystemComponent { 
  data: any;
  nombre = 'Agrega '

  constructor(public dialogRef: MatDialogRef<AddSystemComponent>) {
    this.data = {}
  }

  formControl = new FormControl('', [
    Validators.required
  ]);
  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

//entryComponent Delete
@Component({
  selector: 'app-del-system',
  templateUrl: './del.component-system.html',
})

export class DelSystemComponent {
  constructor(
    public dialogRef: MatDialogRef<DelSystemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
  }
  formControl = new FormControl('', [
    Validators.required
  ]);
  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

//entryComponents Update
@Component({
  selector: 'app-upd-system',
  templateUrl: './add.component-system.html'
})

export class UpdSystemComponent {
  nombre = 'Edita ';
  constructor(
    public dialogRef: MatDialogRef<UpdSystemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
  }
  formControl = new FormControl('', [
    Validators.required
  ]);
  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
