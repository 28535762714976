import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { CargaMasivaService } from '@shared/carga-masiva.service';
import { IColumns, IExportExcel, IScroll, Toolbar, IColumnHiding, ICheckbox, IEditing, IColumnchooser, TiposdeDato, TiposdeFormato } from '@models/grid.interfaces';
import { ListasControlConfiguraListas } from '@shared/configura-listas.service';
import { ConsultaPersonales } from '@shared/consulta-datos-personales.service';

@Component({
  selector: 'app-configuracion-listas',
  templateUrl: './configuracion-listas.component.html',
  styleUrls: ['./configuracion-listas.component.scss']
})
export class ConfiguracionListasComponent implements OnInit {

  wait: boolean = false;
  waitFind: boolean = false;
  waitFile: boolean = false;

  datosFind: any;
  datosTable: any;
  datoseventFind: any;

  clave: any;
  mandt: any;
  descripcion: any;
  usuario: any;
  nomina: any;

  nombresInput: string = '';
  idT: string = '';

  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,
    public cargaMasiva: CargaMasivaService,
    public dataService: ListasControlConfiguraListas,
  ) {
    if (!userService.getUserLogIn()) route.navigate(['./login']);
    this.datosTable = [];
    this.datosFind = [];
    this.nombresInput = '';
    this.idT = '';

    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: 'Listado de Información' };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: false }; // parametros de para seleccion de columnas

    this.scroll = { mode: 'standard' }; // Parametros de Scroll
    this.columns = [ // Columnas
      {
        caption: 'Id',
        dataField: 'id',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre de la Lista',
        dataField: 'nombre_completo',
        hiddingPriority: '0'
      },
      {
        caption: 'Abreviatura',
        dataField: 'subnombre_completo',
        hiddingPriority: '0'
      },
      {
        caption: 'Tipo de Lista',
        dataField: 'tipo_lista',
        hiddingPriority: '0'
      },
      {
        caption: 'Estatus',
        dataField: 'estatus_lista',
        hiddingPriority: '0'
      },
      {
        caption: 'Usuario',
        dataField: 'usuario',
        hiddingPriority: '0'
      },
      {
        caption: 'Nomina',
        dataField: 'nomina',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha Ultimo Movimiento',
        dataField: 'fecha_mov',
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: '0'
      },


    ];

    this.toolbar = [
      // {
      //   location: 'after',
      //   widget: 'dxButton',
      //   locateInMenu: 'auto',
      //   options: {
      //     width: 90,
      //     text: 'Agregar',
      //     onClick: this.receiveMessageDoc.bind(this, 'add')
      //   },
      //   visible: true
      // },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Editar',
          onClick: this.receiveMessageDoc.bind(this, 'edt')
        },
        visible: false,
        name: 'simple'
      },
      // {
      //   location: 'after',
      //   widget: 'dxButton',
      //   locateInMenu: 'auto',
      //   options: {
      //     width: 90,
      //     text: 'Eliminar',
      //     onClick: this.receiveMessageDoc.bind(this, 'del')
      //   },
      //   visible: false,
      //   name: 'simple'
      // },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 110,
          text: 'Recargar',
          onClick: this.receiveMessageDoc.bind(this, 'loadD')
        },
        visible: true
      }

    ];
  }

  ngOnInit() {
    this.loadData()

  }

  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case 'add':
          //this.add();
          break;
        case 'edt':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.upd(d)
          }
          break;
        case 'del':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            //this.del(d)
          }
          break;
        case 'loadD':
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  loadData() {
    this.wait = true;
    this.dataService.getAll().toPromise().then((res) => {
      this.wait = false;
      // guardar perfil para config de listas
      this.datosTable = res.filter(x => x.tipo_lista == 'Banco');
      for (let i = 0; i < this.datosTable.length; i++) {
        let stat = this.datosTable[i].estatus_lista;
        if (stat == 1) {
          this.datosTable[i].estatus_lista = "Activo"
        } else {
          this.datosTable[i].estatus_lista = "Desactivado"
        }
      }
    })
  }

  upd(datos) {
    let dataIns = [datos, this.userService.user.nombre, this.userService.user.nomina]
    const dialogRef = this.dialog.open(UpdEstatusListas, { data: dataIns });
    dialogRef.afterClosed().subscribe(resDialog => {
      let bodyIns = { "id": resDialog[0].id, "estatus_lista": resDialog[0].estatus_lista, "usuario": resDialog[1], "nomina": resDialog[2] }
      if (bodyIns) {
        if (resDialog[0].tipo_lista == "Yastas") {
          this.dataService.putYastas(bodyIns).toPromise().then((res) => {
            this.loadData();
            this.insertaBitacora(resDialog[0]);
            this.snackBar.open(`Se ha dado de actualizado correctamente`, 'ok', { duration: 2500 });

          }).catch((err) => {
            this.wait = false;
            let msj = err.message;
            try {
              msj = err.error.error.message + '\n' + err.error.text;
            } catch { }
            this.snackBar.open(msj, 'close');
          });
        } else if (resDialog[0].tipo_lista == "Banco") {
          this.dataService.put(bodyIns).toPromise().then((res) => {
            this.loadData();
            this.insertaBitacora(resDialog[0]);
            this.snackBar.open(`Se ha dado de actualizado correctamente`, 'ok', { duration: 2500 });

          }).catch((err) => {
            this.wait = false;
            let msj = err.message;
            try {
              msj = err.error.error.message + '\n' + err.error.text;
            } catch { }
            this.snackBar.open(msj, 'close');
          });
        }

      }
    });
  }

  insertaBitacora(resDialog) {


    this.usuario = this.userService.user.nombre
    this.nomina = this.userService.user.nomina

    let data = {
      "tipo_mov": "cambio de estatus", "nombre_completo": resDialog.nombre_completo,
      "subnombre_completo": resDialog.subnombre_completo, "tipo_lista": resDialog.tipo_lista,
      "usuario": this.usuario, "nomina": this.nomina
    }

    this.dataService.post(data).toPromise().then((res) => {
    }).catch((err) => {
      this.wait = false;
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      console.error(msj)
    });
  }

}

interface Status {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-upd-sedes-balances',
  templateUrl: './add.component.html',
})
export class UpdEstatusListas {
  nombre = 'Edita '
  dataProducto: any;
  dataOficinas: any;
  dataServicios: any;
  dataDatosSel: any;

  status: Status[] = [
    { value: '0', viewValue: 'Desactivado' },
    { value: '1', viewValue: 'Activado' }
  ];

  sta: Status[] = [
    { value: '0', viewValue: 'Desactivado' },
    { value: '1', viewValue: 'Activo' }
  ];


  constructor(public dialogRef: MatDialogRef<UpdEstatusListas>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data[0].estatus_lista == "Activo") {
      data[0].estatus_lista = this.sta[1].value
    }
    else {
      data[0].estatus_lista = this.sta[0].value
    }

  }

  formControl = new FormControl('', [Validators.required
  ]);


  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}
