import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {environment} from '../../environments/environment';
import {SecureStorage} from '@utils/secure-storage';
import { LocalStorageService } from './local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';

@Injectable({
  providedIn: 'root'
})
export class AService {
  public showToolbar = false;
  usuario = '';
  contrasena = '';
  isLogged: boolean;
  url: any;

  constructor(
    private localStorage: LocalStorageService,
    private matSnackBar: MatSnackBar
  ) {
    this.isLogged = false;
    this.url = this.localStorage.getObject(KEYS_STORAGE.url);
  }

  handleError(data): void {
    this.matSnackBar.open(data, 'Cerrar', { duration: 10000 });
  }

  /**
   * Metodo que realiza validación para hardcode users
   * @param username Usuario de Comparnet
   * @param pass Password de Comparnet
   * @returns True es cuando encontro registro | false cuando no encontro
   */
  public existHardCodeUser(username: string, pass: string) {
    const environments = ['dev', 'qas'];
    if(environments.includes(this.url.versioname)) {
      const user = this.getOther(username, pass);
      if (user) {
        this.setTypeUser(user)
        return true;
      }
    }
    return false;
  }

  getOther(username: string, pass: string) {
    const usersHardCode = environment.usersHardCode || [];
    const users = usersHardCode.filter(d => d.name === username && d.password === pass);
    return users[0] || null;
  }

  setPermissions(permissions: number[]) {
    this.localStorage.setObject(KEYS_STORAGE.permissions, permissions);
  }

  getPermissions() {
    return this.localStorage.getObject(KEYS_STORAGE.permissions) || [];
  }

  setTypeUser(profile: any) {
    if(profile === 'CoordinadorPLDYastas') {
      localStorage.setItem('typeUser', SecureStorage.encrypt(JSON.stringify('yastas')));
    } else {
      localStorage.setItem('typeUser', SecureStorage.encrypt(JSON.stringify('banco')))
    }
  }

  deleteConfiguration() {
    this.localStorage.removeByKey(KEYS_STORAGE.permissions);
    this.localStorage.removeByKey('typeUser');
  }
}
