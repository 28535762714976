import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';

@Injectable({
  providedIn: 'root'
})
export class APIService {
  private environmentUrl: any = {};
  private httpOptions = { headers: new HttpHeaders(), params: new HttpParams() };

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
    this.environmentUrl = this.localStorage.getObject(KEYS_STORAGE.url) || {};
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams()
    }
  }

  get(endpoint: any, params: any = {}, headers?: any): Observable<any> {
    const url = `${this.environmentUrl.serviceUrl}/${endpoint}`;
    let myHeader: any = Object.assign({}, this.httpOptions);
    myHeader.params = new HttpParams({ fromObject: params });
    return this.http.get(url, myHeader);
  }

  post(endpoint: string, body: any = {}, header?): Observable<any> {
    const url = `${this.environmentUrl.serviceUrl}/${endpoint}`;
    return this.http.post(url, JSON.stringify(body), this.httpOptions);
  }

  put(endpoint: string, body: any = {}, header?): Observable<any> {
    const url = `${this.environmentUrl.serviceUrl}/${endpoint}`;
    return this.http.put(url, JSON.stringify(body), this.httpOptions);
  }

  delete(endpoint: string, body: any = {}, header?): Observable<any> {
    const url = `${this.environmentUrl.serviceUrl}/${endpoint}`;
    return this.http.delete(url, this.httpOptions);
  }
}
