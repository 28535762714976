import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { Enterprise } from 'src/app/shared/models/enterprise';
import { EnterpriseService } from "@shared/enterprise.service";
@Component({
  selector: 'app-selector-company',
  templateUrl: './selector-company.component.html',
  styleUrls: ['./selector-company.component.scss']
})
export class SelectorCompanyComponent implements OnInit {
  enterprises: Enterprise[] = [];
  idCompanySelected: number = 0;
  wait: boolean = false;
  @Output() onSelectEnterprise: EventEmitter<Enterprise> = new EventEmitter();
  constructor(
    private entp: EnterpriseService
  ) { }

  ngOnInit() {
    this.wait = true;
    this.entp.getEnterpriseAll()
      .subscribe((data) => {
        this.enterprises = data;
        const entDef = this.enterprises.find(d => d.isDefault == true);
        if (entDef){
          this.idCompanySelected = entDef.companyId;
          this.selectEnterpise();
        }
        this.wait = false;
      }, (err) => {
        console.error('ERROR ', err);
        this.wait = false;
      });
  }
  selectEnterpise(): void {
    this.onSelectEnterprise.emit(this.enterprises.find(d => d.companyId == this.idCompanySelected) as any);
  }
}
