import { formatDate, formatDateTime } from "./date";

export const formatDateLC = (datos: any[]) => {
  return datos.map((x: any) => ({
    ...x,
    FechaAprobacionInicial: formatDateTime(x.FechaAprobacionInicial),
    FechaAprobacionSemestral: formatDateTime(x.FechaAprobacionSemestral),
    FechaEvaluacionInicial: formatDateTime(x.FechaEvaluacionInicial),
    FechaEvaluacionSemestral: formatDateTime(x.FechaEvaluacionSemestral),
    fecha_consulta: formatDateTime(x.fecha_consulta),
    fecha_nacimiento: formatDate(x.fecha_nacimiento),
  }));
}

export const formatDateGR = (datos: any[]) => {
  return datos.map((x: any) => ({
    ...x,
    fecha_inicial: formatDateTime(x.fecha_inicial),
    FechaDeteccion: formatDateTime(x.FechaDeteccion),
    fecha_semestral: formatDateTime(x.fecha_semestral),
    fecha_AprobIni: formatDateTime(x.fecha_AprobIni),
    fecha_AprobIni2: formatDateTime(x.fecha_AprobIni2),
    fecha_consulta_GR: formatDateTime(x.fecha_consulta_GR),
    fecha_nacimiento: formatDate(x.fecha_nacimiento),
  }));
}