import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DateAdapter, MatSnackBar, MAT_DATE_FORMATS} from '@angular/material';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminListas} from '@shared/admin-listas.service';
import {CargaMasivaService} from '@shared/carga-masiva.service';
import {ListasControlAutReg} from '@shared/listas-control-autorizacion_reg.service';
import {ListasControlBLOQ} from '@shared/listas-control-bloq.service';
import {ListasControlGRIS} from '@shared/listas-control-gris.service';
import {ListasControlOFAC} from '@shared/listas-control-ofac.service';
import {ListasControlPEP} from '@shared/listas-control-pep.service';
import {ListasControlPLDYF} from '@shared/listas-control-pldyf.service';
import {UserService} from '@shared/user.service';
import {FormatDateEsp} from '@utils/format-date-esp';
import {SecureStorage} from '@utils/secure-storage';
import {
  ICheckbox,
  IColumnchooser,
  IColumnHiding,
  IColumns,
  IEditing,
  IExportExcel,
  IScroll,
  TiposdeDato,
  TiposdeFormato,
  Toolbar
} from '@models/grid.interfaces';
import {allHasValue, anyHasValue} from "../../shared/utils/hasValue";
import { LocalStorageService } from '@services/local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';
import { AdminListStatusComponent } from './admin-list-status/admin-list-status.component';
import { Subscription } from 'rxjs';
import { formatDateTime } from 'src/app/core/utils/date';
import { formatDateLC } from 'src/app/core/utils/dateConvert';

@Component({
  selector: 'app-admin-listas-control',
  templateUrl: './admin-listas-control.component.html',
  styleUrls: ['./admin-listas-control.component.scss'],
  providers: [
    {
      provide: MAT_DATE_FORMATS, useValue: FormatDateEsp
    }
  ]
})
export class AdminListasControlComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  flaglista: any;
  wait: boolean = true;
  wait2: boolean = true;
  waitFind: boolean = false;
  waitFile: boolean = false;
  maxDate = new Date();
  datosFind: any;
  datosTable: any;
  datosTableHits: any;
  datoseventFind: any;
  con_lista: any;
  datosStatus: any;
  tipoBusq = 0;

  coincidencia_tabla: string = '';
  inicio: string = '';
  fin: string = '';
  estatus: string = '';
  nombre_completo: string = '';
  oficina: string = '';
  oficinas: any;

  usuario: any;
  nomina: any;

  nombresInput: string = '';
  idT: string = '';
  listasNegras: [];


  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  columnsHits: IColumns[];
  toolbarHits: Toolbar[];
  exportExcelHits: IExportExcel;
  columnHidingHits: IColumnHiding;
  CheckboxHits: ICheckbox;
  EditingHits: IEditing;
  ColumnchooserHits: IColumnchooser;
  scrollHits: IScroll;
  datoseventHits: any;
  onExportingFunHits: Function;
  onExportedFunHits: Function;
  url: any;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,
    public cargaMasiva: CargaMasivaService,
    public dataServiceBLOQ: ListasControlBLOQ,
    public dataServiceGRIS: ListasControlGRIS,
    public dataServiceOFAC: ListasControlOFAC,
    public dataServicePEP: ListasControlPEP,
    public dataServicePLDYF: ListasControlPLDYF,
    public dataServiceAdminListas: AdminListas,
    public dataServiceAut: ListasControlAutReg,
    private readonly adapter: DateAdapter<Date>,
    private localStorage: LocalStorageService
  ) {
    this.url = localStorage.getObject(KEYS_STORAGE.url);
    this.adapter.setLocale('es-MX');
    if (!userService.getUserLogIn()) route.navigate(['./login']);
    this.datosTable = [];
    this.datosTableHits = [];
    this.datosFind = [];
    this.nombresInput = '';
    this.idT = '';
    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = {enabled: true, fileName: 'Listado de Información'};
    this.columnHiding = {hide: true}; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = {checkboxmode: 'multiple'};  // *Activar con multiple*/parametros de para checkbox
    this.Editing = {allowupdate: false}; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = {columnchooser: true}; // parametros de para seleccion de columnas

    this.scroll = {mode: 'standard'}; // Parametros de Scroll

    //Grid de Datos para mostrar Hits
    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcelHits = {enabled: true, fileName: 'Listado de Información'};
    this.columnHidingHits = {hide: true}; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.CheckboxHits = {checkboxmode: 'multiple'};  // *Activar con multiple*/parametros de para checkbox
    this.EditingHits = {allowupdate: false}; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.ColumnchooserHits = {columnchooser: true}; // parametros de para seleccion de columnas

    this.scrollHits = {mode: 'standard'}; // Parametros de Scroll
    this.columnsHits = [ // Columnas
      {
        caption: 'ID cliente',
        dataField: 'bp',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre completo',
        dataField: 'nombres',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha de nacimiento',
        dataField: 'fecha_nacimiento',
        dataType: TiposdeDato.date,
        format: TiposdeFormato.dmy,
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha detección',
        dataField: 'fecha_consulta',
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: '0'
      },
      {
        caption: 'Motivo',
        dataField: 'motivo',
        hiddingPriority: '0'
      },
      {
        caption: 'Oficina de servicios',
        dataField: 'sistema',
        hiddingPriority: '0'
      },
      {
        caption: 'Actividad económica',
        dataField: 'descActi',
        hiddingPriority: '0'
      },
      {
        caption: 'Municipalidad/Localidad',
        dataField: 'localidad',
        hiddingPriority: '0'
      },
      {
        caption: 'Entidad federativa',
        dataField: 'estado',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre del producto',
        dataField: 'descProd',
        hiddingPriority: '0'
      },
      {
        caption: 'Estatus Inicial',
        dataField: 'EstatusInicial',
        hiddingPriority: '0'
      },
      {
        caption: 'Estatus Autorización',
        dataField: 'EstatusAutorizacionInicial',
        hiddingPriority: '0'
      },
      {
        caption: 'Usuario que modifica',
        dataField: 'UsuarioAprobacionInicial',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha de modificación',
        dataField: 'FechaAprobacionInicial',
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: '0'
      }

      // {
      //   caption: 'Tabla consultada',
      //   dataField: 'tabla_consultada',
      //   hiddingPriority: '0'
      // },

      // {
      //   caption: 'RFC',
      //   dataField: 'rfc',
      //   hiddingPriority: '0'
      // },
      // {
      //   caption: 'Nivel riesgo',
      //   dataField: 'nivelRiesgo',
      //   hiddingPriority: '0'
      // },
      // {
      //   caption: 'Estatus inicial',
      //   dataField: 'EstatusInicial',
      //   hiddingPriority: '0'
      // },
      // {
      //   caption: 'Estatus autorizacion',
      //   dataField: 'EstatusAutorizacionInicial',
      //   hiddingPriority: '0'
      // },
      //
    ];

    this.toolbarHits = [
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Cambiar Estatus',
          onClick: this.receiveMessageDocHits.bind(this, 'change_status')
        },
        visible: false,
        name: 'simple'
      },
    ];
  }

  ngOnInit() {
    this.loadData();
    this.getAllNameLC();
    this.getOficinas();
    this.datosTableHits = null;
    this.datosTable = null;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch {
      }
      this.snackBar.open(msj, 'close');
    }
  }

  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case 'add':
          //this.add();
          break;
        case 'edt':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            //this.upd(d)
          }
          break;
        case 'del':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            //this.del(d)
          }
          break;
        case 'loadD':
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch {
      }
      this.snackBar.open(msj, 'close');
    }
  }

  receiveMessageDocHits($event) {
    try {
      switch ($event) {
        case 'change_status':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.change_status(d, this.datosStatus)
          }
          break;
        case 'loadD':
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch {
      }
      this.snackBar.open(msj, 'close');
    }
  }

  loadData() {
    this.dataServiceAdminListas.getStatus().toPromise().then((res) => {
      this.datosStatus = res;
      if (this.coincidencia_tabla != 'LPEP' && this.datosStatus && this.datosStatus[2]) {
        this.datosStatus[2].statusFront = 'PENDIENTE POR AUTORIZAR'
      } else if (this.coincidencia_tabla == 'LPEP' && this.datosStatus && this.datosStatus[2]) {
        this.datosStatus[2].statusFront = 'EN ANALISIS'
      }
      if (this.datosStatus['Code'] == "404") {
        this.datosStatus = null;
      }
    })
  }

  cambiarEtiquetas(data) {
    if (data.value != 'LPEP' && this.datosStatus && this.datosStatus[2]) {
      this.datosStatus[2].statusFront = 'PENDIENTE POR AUTORIZAR'
    } else if (data.value == 'LPEP' && this.datosStatus && this.datosStatus[2]) {
      this.datosStatus[2].statusFront = 'EN ANALISIS'
    }
  }

  loadHitsByParams() {
    try {
      this.displayProgressSpinner = true;
      let body = {
        'coincidencia_tabla': this.coincidencia_tabla,
        'inicio': this.inicio,
        'fin': this.fin,
        'estatus': this.estatus,
        'sistema': this.oficina,
        'nombre_completo': this.nombre_completo
      }
      this.dataServiceAdminListas.getHitsByParams(body).toPromise().then((res) => {
        this.wait2 = false;
        if (res.datos.length <= 0) {
          this.snackBar.open('No existen hits con estos filtros, no se encontraron registros', 'close');
        }
        this.datosTableHits = formatDateLC(res.datos || []);


        console.log("loadHitsByparams",this.datosTableHits)
        this.displayProgressSpinner = false;
      }).catch((err) => {
        this.wait2 = false;
        this.displayProgressSpinner = false;
        let msj = err.message;
        try {
          msj = err.error.error.message + '\n' + err.error.text;
        } catch {
        }
        this.snackBar.open(msj, 'close');
      });
    } catch (e) {
      //var msj = e.error.error.message + '\n' + e.error.text;
      //this.snackBar.open(msj, 'close');
      console.error(e);
    }

  }

  getOficinas() {
    this.dataServiceAdminListas.getOficinas().toPromise().then((res) => {
      this.oficinas = res.datos.sort((x, y) => x.nombreOficina.localeCompare(y.nombreOficina));
    }).catch((err) => {
      console.error(err)
    });
  }

  setWrite(name) {
    let user = JSON.parse(SecureStorage.decrypt(SecureStorage.decrypt(localStorage.getItem('currentUser'))));
    let permissions = user.permissions;
    if (permissions.length > 0) {
      let element = permissions.filter(d => d.name == name)
      if (element.length > 0) {
        return element[0].write
      }
      return true
    }
    return true
  }

  limpiarFiltros() {
    this.con_lista = '';
    this.coincidencia_tabla = '';
    this.inicio = '';
    this.fin = '';
    this.estatus = '';
    this.nombre_completo = '';
    this.oficina = '';
    this.datosTable = null;
    this.datosTableHits = null;
  }

  loadListaHits() {
    try {
      let body = {
        'coincidencia_tabla': this.con_lista,
        'inicio': this.inicio,
        'fin': this.fin,
        'estatus': this.estatus,
        'sistema': '',
        'nombre_completo': ''
      }
      this.wait = true;
      this.toolbarHits = [
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 90,
            text: 'Cambiar Estatus',
            onClick: this.receiveMessageDocHits.bind(this, 'change_status')
          },
          visible: false,
          name: 'simple'
        },
      ];
      this.displayProgressSpinner = true;
      this.dataServiceAdminListas.getHitsByParams(body).toPromise().then((res) => {
        this.displayProgressSpinner = false;
        this.wait = false;
        if (res.datos.length <= 0) {
          this.snackBar.open('No existen hits con estos filtros, no se encontraron registros', 'close');
        }
        this.datosTableHits = formatDateLC(res.datos || []);
        console.log("loadListasHits",this.datosTableHits)
        if (this.datosTableHits['Code'] == "404") {
          this.datosTableHits = null;
        }
      }).catch((err) => {
        this.displayProgressSpinner = false;
        this.wait2 = false;
        let msj = err.message;
        try {
          msj = err.error.error.message + '\n' + err.error.text;
        } catch {
        }
        this.snackBar.open(msj, 'close');
      });
    } catch (e) {
      console.error(e);
    }
  }

  getAllNameLC() {
    this.listasNegras = null;
    this.dataServiceAdminListas.getAllNameLC().toPromise().then((data: any) => {
      this.listasNegras = data;
    }).catch((err) => {
      this.listasNegras = null;
    });
  }

  change_status(datos: any, datosSta: any) {
    this.usuario = this.userService.user.nombre;
    this.nomina = this.userService.user.nomina;
    const data = { data: datos, status: datosSta };
    const dialogRef = this.dialog.open(AdminListStatusComponent, {
      data,
      restoreFocus: false,
      autoFocus: false,
    });

    this.subscription.add(dialogRef.afterClosed()
    .subscribe(x => {
      if (x.success) {
        this.tipoBusq == 2
          ? this.loadHitsByParams()
          : this.loadListaHits();
      }
    }));

  }

  formControl = new FormControl('', [Validators.required]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }

  getCanSearchByPerson() {
    return anyHasValue([this.coincidencia_tabla, this.estatus, this.oficina, this.nombre_completo]) || allHasValue([this.inicio, this.fin]);
  }

  getCanSearchGeneral() {
    return anyHasValue([this.con_lista, this.estatus]) || allHasValue([this.inicio, this.fin]);
  }

}