import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { 
  ICheckbox,
  IColumnchooser,
  IColumnHiding,
  IColumns,
  IEditing, 
  IExportExcel, 
  IScroll, 
  Toolbar 
} from '@models/grid.interfaces';

import { ModulosService } from '@shared/modulos.service';
import { ProcesosService } from '@shared/procesos.service';
import { SystemsService } from '@shared/systems.services';
import { UserService } from '@shared/user.service';
//servicio de modules. 

@Component({
  selector: 'app-modulos',
  templateUrl: './modulos.component.html',
  styleUrls: ['./modulos.component.scss']
})
export class ModulosComponent implements OnInit {

  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  columnsHist: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosPost: any;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;
  /* *************** Otros ***************** */
  wait: boolean = false;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,
    public _modulesService: ModulosService
  ) { 
    this.isAuthentication();
    this.paramsOfGrdiData();
  }

  ngOnInit() {
    this.getModuleData();
  }

  isAuthentication() {
    if (!this.userService.getUserLogIn()) this.route.navigate(['./login']);
    this.datosPost = [];
  }

  paramsOfGrdiData() {
        /* *************** Parametros del Grid de datos ***************** */
        this.exportExcel = { enabled: true, fileName: 'Listado de Modulos' };
        this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
        this.Checkbox = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
        this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
        this.Columnchooser = { columnchooser: false }; // parametros de para seleccion de columnas
    
        this.scroll = { mode: 'standard' }; // Parametros de Scroll
        this.columns = [ // Columnas
          {
            caption: 'sistema',
            dataField: 'systemName',
            hiddingPriority: '0'
          },
          {
            caption: 'proceso',
            dataField: 'processName',
            hiddingPriority: '0'
          },
          {
            caption: 'Id',
            dataField: 'moduleId',
            hiddingPriority: '0'
          },
          {
            caption: 'modulo',
            dataField: 'moduleName',
            hiddingPriority: '0'
          },
        ];
    
        this.toolbar = [
          {
            location: 'after',
            widget: 'dxButton',
            locateInMenu: 'auto',
            options: {
              width: 90,
              text: 'Agregar',
              onClick: this.receiveMessageDoc.bind(this, 'add')
            },
            visible: true
          },
          {
            location: 'after',
            widget: 'dxButton',
            locateInMenu: 'auto',
            options: {
              width: 110,
              text: 'Actualiza',
              onClick: this.receiveMessageDoc.bind(this, 'upd')
            },
            visible: true
          },
          {
            location: 'after',
            widget: 'dxButton',
            locateInMenu: 'auto',
            options: {
              width: 90,
              text: 'Elimina',
              onClick: this.receiveMessageDoc.bind(this, 'del')
            },
            visible: false,
            name: 'simple'
          },
          {
            location: 'after',
            widget: 'dxButton',
            locateInMenu: 'auto',
            options: {
              width: 90,
              text: 'Edita',
              onClick: this.receiveMessageDoc.bind(this, 'edt')
            },
            visible: false,
            name: 'simple'
          }
        ];
  }

  getModuleData() { 
    this.wait = true;
    this._modulesService.getAllModules().toPromise().then((res) => {
      this.wait = false;
      this.datosPost = res;
    });
  }

  addModule() {
    const dialogRef = this.dialog.open(AddModulesComponent, {});
    dialogRef.afterClosed().subscribe(resDialog => {
      const nomina = Number(this.userService.user.nomina);
      const bodyModule = { ...resDialog, nomina }
      
      if (resDialog) {
        this._modulesService.createModule(bodyModule).toPromise().then((res) => {
          this.getModuleData();
          this.snackBar.open(`Se ha dado de alta correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch{ }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }
  
  updModule(datos) {
    const dialogRef = this.dialog.open(UpdModulesComponent, {
      data: datos
    });
    dialogRef.afterClosed().subscribe(resDialog => {
      const nomina = Number(this.userService.user.nomina);
      const bodyModule = { ...resDialog, nomina }
      if (resDialog) {
        this._modulesService.updateModule(bodyModule).toPromise().then((res) => {
          this.getModuleData();
          this.snackBar.open(`Se ha dado de actualizado correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch{ }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  delModule(datos) {
    const dialogRef = this.dialog.open(DelModulesComponent, {
      data: datos
    });
    dialogRef.afterClosed().subscribe(resDialog => {
      const bodyModule = { ...resDialog, userNomina: this.userService.user.nomina }
      if (resDialog) {
        this._modulesService.deleteModule(bodyModule).toPromise().then((res) => {
          this.getModuleData();
          this.snackBar.open(`Se ha dado de eliminado correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch{ }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case 'add':
          this.addModule();
          break;
        case 'del':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.delModule(d)
          }
          break;
        case 'edt':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.updModule(d)
          }
          break;
        case 'upd':
          this.getModuleData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch{ }
      this.snackBar.open(msj, 'close');
    }
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch{ }
      this.snackBar.open(msj, 'close');
    }
  }

}

//*****  entryComponentAdd **************/
@Component({ 
  selector: 'app-add-modules',
  templateUrl: './add.modulo.component.html'
})

export class AddModulesComponent { 
  data: any;
  nombre = 'Agrega '
  valueSystem: any;
  catalogsSystems: any; 
  catalogsProcess: any;

  constructor(
    public dialogRef: MatDialogRef<AddModulesComponent>,
    public serviceSystem: SystemsService,
    public serviceProcess: ProcesosService
    ) {
    this.data = {}
    this.catalogsProcess = [];
    this.catalogsSystems = [];
    this.getSystems();
  }

  getSystems() {
    this.serviceSystem.getCatalogo().subscribe(systems => {
      this.catalogsSystems = systems;
    });
  }

  getByIdSystem(idSystem) {
    this.getProcessById(idSystem);
  }

  getProcessById(idSystem) { 
    this.serviceProcess.getProcessById(idSystem).subscribe(process => {
      this.catalogsProcess = process;
    });
  }

  formControl = new FormControl('', [
    Validators.required
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

//entryComponents Update
@Component({
  selector: 'app-upd-modules',
  templateUrl: './add.modulo.component.html'
})

export class UpdModulesComponent {
  nombre = 'Edita ';
  catalogsSystems: any; 
  catalogsProcess: any;
  constructor(
    public dialogRef: MatDialogRef<UpdModulesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public serviceSystem: SystemsService,
    public serviceProcess: ProcesosService
  ) {
    this.catalogsProcess = [];
    this.catalogsSystems = [];
    this.getSystems();
    this.getByIdSystem(data.systemId);
  }

  getSystems() {
    this.serviceSystem.getCatalogo().subscribe(systems => { 
      this.catalogsSystems = systems;
    });
  }

  getByIdSystem(idSystem) {
    this.getProcessById(idSystem);
  }

  getProcessById(idSystem) {
    this.serviceProcess.getProcessById(idSystem).subscribe(process => {
      this.catalogsProcess = process;
    });
  }

  formControl = new FormControl('', [
    Validators.required
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
}

//entryComponent Delete
@Component({
  selector: 'app-del-modules',
  templateUrl: './del.modulo.componet.html',
})

export class DelModulesComponent {
  constructor(
    public dialogRef: MatDialogRef<DelModulesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
  formControl = new FormControl('', [
    Validators.required
  ]);
  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

