import { Component, OnInit, Inject } from '@angular/core';
import { IColumns, IExportExcel, IScroll, Toolbar, IColumnHiding, ICheckbox, IEditing, IColumnchooser } from '@models/grid.interfaces';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { CargaMasivaService } from '@shared/carga-masiva.service';
import { BitacoraConsultasDevService } from '@shared/bitacora-consultas-dev.service';
import { ListasControlConsultaHits } from '@shared/listas-control-consulta-hits.service';
import { ListasControlAutReg } from '@shared/listas-control-autorizacion_reg.service';
import { AdminListas } from '@shared/admin-listas.service';
import { SecureStorage } from '@utils/secure-storage';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '@services/local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';
import { formatDate, formatDateTime } from 'src/app/core/utils/date';
import { formatDateGR } from 'src/app/core/utils/dateConvert';

@Component({
  selector: 'app-lc-consulta-gr',
  templateUrl: './lc-consulta-gr.component.html',
  styleUrls: ['./lc-consulta-gr.component.scss']
})
export class LcConsultaGrComponent implements OnInit {
  wait: boolean = true;
  datosHits: any;
  datosLC: any;
  datosNivelRiesgo: any;
  datosStatus: any;
  listaActEconomica: any;
  listaTipoPersona: any;
  listaProdServ: any;
  listaODRecursos: any;
  listaUbicacionGeo: any;
  listaVolOper: any;
  maxDate = new Date();
  columns : IColumns[];
  columnsAltoPEP: IColumns[];
  columnsBMI: IColumns[];
  columnsAlto: IColumns[];
  toolbar: Toolbar[];
  toolbarRisk: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  inicio: string = '';
  fin: string = '';
  estatus: string = '';
  listasNegras = null;
  coincidencia_tabla = '';
  select_datosBC: any;

  gradoRiesgos = [];
  riesgo: string = '';
  usuario: any;
  nomina: any;
  riesgo_temp: any;

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  url: any;

  constructor(public dialog: MatDialog,
  private route: Router,
  public snackBar: MatSnackBar,
  public _route: ActivatedRoute,
  public userService: UserService,
  public cargaMasiva: CargaMasivaService,
  public consultaHitsService: ListasControlConsultaHits,
  public bitacoraConsultasDevService: BitacoraConsultasDevService,
  public dataServiceAut: ListasControlAutReg,
  private localStorage: LocalStorageService,
  public dataServiceAdminListas: AdminListas) {
    this.datosHits = [];
    this.wait = true;
    this.getIsAuthentication();
    this.paramsOfGridDataInitial();
    this.toolbarRisk = [
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Cambiar Estatus',
          onClick: this.receiveMessageRisk.bind(this, 'change_status')
        },
        visible: false,
        name: 'simple'
      },
    ];
  }

  ngOnInit() {
    this.loadData();
    this.getAllNameLC();
    this.wait = true;
    this.datosHits = null;
    this.datosLC = [];
    this.datosNivelRiesgo = [];
    this.getAllNivelRiesgo();
  }

  getIsAuthentication() {
    if (!this.userService.getUserLogIn()) this.route.navigate(['./login']);

  }

  paramsOfGridDataInitial() {
    //Grid de Datos para mostrar Hits
    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: 'Listado de Información' };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: 'multiple' };  // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: true }; // parametros de para seleccion de columnas

    this.scroll = { mode: 'standard' }; // Parametros de Scroll
    this.columnsAltoPEP = [ // Columnas
      {
        caption: 'ID cliente',
        dataField: 'bp',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre completo',
        dataField: 'nombre_completo',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha de nacimiento',
        dataField: 'fecha_nacimiento',
        hiddingPriority: '0'
      },
      {
        caption: 'Coincidencia en Lista',
        dataField: 'coincidencia_tabla',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha de Detección',
        dataField: 'FechaDeteccion',
        hiddingPriority: '0'
      },
      {
        caption: 'Oficina de servicios',
        dataField: 'serviceOfficeID',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre del producto',
        dataField: 'descProd',
        hiddingPriority: '0'
      },
      {
        caption: 'Grado de Riesgo Inicial',
        dataField: 'nivel_riesgo',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha inicial',
        dataField: 'fecha_inicial',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha semestral',
        dataField: 'fecha_semestral',
        hiddingPriority: '0'
      },
      {
        caption: 'Estatus Autorizador 1',
        dataField: 'status_AprobIni',
        hiddingPriority: '0'
      },
      {
        caption: 'Usuario que modifica',
        dataField: 'usuario_AprobIni',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha de modificación',
        dataField: 'fecha_AprobIni',
        hiddingPriority: '0'
      },
      {
        caption: 'Estatus Autorizador 2',
        dataField: 'status_AprobIni2',
        hiddingPriority: '0'
      },
      {
        caption: 'Usuario que modifica',
        dataField: 'usuario_AprobIni2',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha de modificación',
        dataField: 'fecha_AprobIni2',
        hiddingPriority: '0'
      },
      {
        caption: 'Tipo',
        dataField: 'tipo',
        allowEditing: false,
        width: 100
      },
    ];

    this.columnsAlto = [ // Columnas
      {
        caption: 'ID cliente',
        dataField: 'bp',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre completo',
        dataField: 'nombre_completo',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha de nacimiento',
        dataField: 'fecha_nacimiento',
        hiddingPriority: '0'
      },
      {
        caption: 'Coincidencia en Lista',
        dataField: 'coincidencia_tabla',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha de Detección',
        dataField: 'FechaDeteccion',
        hiddingPriority: '0'
      },
      {
        caption: 'Oficina de servicios',
        dataField: 'serviceOfficeID',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre del producto',
        dataField: 'descProd',
        hiddingPriority: '0'
      },
      {
        caption: 'Grado de Riesgo',
        dataField: 'nivel_riesgo',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha inicial',
        dataField: 'fecha_inicial',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha semestral',
        dataField: 'fecha_semestral',
        hiddingPriority: '0'
      },
      {
        caption: 'Estatus Autorizador 1',
        dataField: 'status_AprobIni',
        hiddingPriority: '0'
      },
      {
        caption: 'Usuario que modifica',
        dataField: 'usuario_AprobIni',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha de modificación',
        dataField: 'fecha_AprobIni',
        hiddingPriority: '0'
      },
      {
        caption: 'Tipo',
        dataField: 'tipo',
        allowEditing: false,
        width: 100
      },
    ];

    this.columnsBMI = [ // Columnas
      {
        caption: 'ID cliente',
        dataField: 'bp',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre completo',
        dataField: 'nombre_completo',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha Nacimiento',
        dataField: 'fecha_nacimiento',
        hiddingPriority: '0'
      },
      {
        caption: 'Coincidencia en Lista',
        dataField: 'coincidencia_tabla',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha de Detección',
        dataField: 'FechaDeteccion',
        hiddingPriority: '0'
      },
      {
        caption: 'Oficina de servicios',
        dataField: 'serviceOfficeID',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre del producto',
        dataField: 'descProd',
        hiddingPriority: '0'
      },
      {
        caption: 'Grado de Riesgo',
        dataField: 'nivel_riesgo',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha inicial',
        dataField: 'fecha_inicial',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha semestral',
        dataField: 'fecha_semestral',
        hiddingPriority: '0'
      },
      {
        caption: 'Usuario que modifica',
        dataField: 'UsuarioAprobacionInicial',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha de modificación',
        dataField: 'fecha_consulta',
        hiddingPriority: '0'
      },
      {
        caption: 'Tipo',
        dataField: 'tipo',
        allowEditing: false,
        width: 100
      },
    ];




    this.toolbar = [
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 100,
          text: 'Cambiar Nivel de Riesgo'
          //onClick: this.receiveMessageDoc.bind(this, 'change_status')
        },
        visible: false,
        name: 'simple'
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Autorizar'
          //onClick: this.receiveMessageDoc.bind(this, 'autorizar')
        },
        visible: false,
        name: 'simple'
      },
    ];
  }

  getAllNameLC() {
    this.listasNegras = null;
    this.dataServiceAdminListas.getAllNameLC().toPromise().then((data: any) => {
      this.listasNegras = data;
    }).catch((err) => {

      this.listasNegras = null;
    });
  }

  getErrorMessage() {}

  cambiarEtiquetas(data) {
    if (data.value != 'LPEP' && this.datosStatus && this.datosStatus[2]) {
      this.datosStatus[2].statusFront = 'PENDIENTE POR AUTORIZAR'
    } else if (data.value == 'LPEP' && this.datosStatus && this.datosStatus[2]) {
      this.datosStatus[2].statusFront = 'EN ANALISIS'
    }

  }

  getAllNivelRiesgo() {
    this.datosNivelRiesgo = null;
    this.consultaHitsService.getAllNivelRiesgo().toPromise().then((data: any) => {
      this.gradoRiesgos = data;
      this.gradoRiesgos.push({'nombre':'Alto + PEP'});
    }).catch((err) => {
      this.datosNivelRiesgo = null;
    });
  }

  setWrite(name){
    let user = JSON.parse(SecureStorage.decrypt(localStorage.getItem('currentUser')));
    let permissions = user.permissions;
    if(permissions.length > 0){
      let element = permissions.filter(d => d.name == name)
      if(element.length > 0){
        return element[0].write
      }
      return true
    }
    return true
  }

  loadBitacoraConsultasDev() {
    let body = {
      'inicio': this.inicio,
      'fin': this.fin,
      'estatus': this.estatus,
      'riesgo': this.riesgo,
      'coincidencia_tabla': this.coincidencia_tabla
    }
    this.riesgo_temp = this.riesgo
    this.wait = true;
    if(this.setWrite(this.riesgo)){
      this.toolbarRisk = [
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
          },
          visible: false,
          name: 'simple'
        },
      ];
    }else{
      this.toolbarRisk = [];
    }
    this.displayProgressSpinner = true;
    this.bitacoraConsultasDevService.getGradoRiesgoByParams(body).toPromise().then((res) => {
      this.wait = false;
      this.displayProgressSpinner = false;
      if (res.datos == "sin registro") {
        this.datosHits = null;
        this.wait = true;
      } else {
        this.datosHits = formatDateGR(res.datos || []);
        if(this.riesgo == 'Alto + PEP'){
          this.columns = this.columnsAltoPEP
        }
        else if(this.riesgo == 'Alto'){
          this.columns = this.columnsAlto
        }
        else{
          this.columns = this.columnsBMI
        }

      }
    }).catch((err) => {
      console.error(err);
      this.displayProgressSpinner = false;
      this.snackBar.open(`Ocurrio un error al realizar la consulta`, 'close');
      this.wait = true;
    });
  }

  limpiarFiltros() {
    this.riesgo = '';
    this.inicio = '';
    this.fin= '';
    this.estatus = '';
    this.coincidencia_tabla = '';

  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  loadData() {
    this.dataServiceAdminListas.getStatus().toPromise().then((res) => {
      this.datosStatus = res;
      if (this.datosStatus['Code'] == "404") {
        this.datosStatus = null;
      }
    })

  }

  receiveMessageRisk($event) {
    try {
      switch ($event) {
        case 'change_status':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            //this.change_status(d, this.datosStatus)
          }
          break;
        case 'loadD':
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  // formatData(datos: any[]) {
  //   return datos.map((x: any) => ({
  //     ...x,
  //     fecha_inicial: formatDateTime(x.fecha_inicial),
  //     FechaDeteccion: formatDateTime(x.FechaDeteccion),
  //     fecha_semestral: formatDateTime(x.fecha_semestral),
  //     fecha_AprobIni: formatDateTime(x.fecha_AprobIni),
  //     fecha_AprobIni2: formatDateTime(x.fecha_AprobIni2),
  //     fecha_consulta_GR: formatDateTime(x.fecha_consulta_GR),
  //     fecha_nacimiento: formatDate(x.fecha_nacimiento),
  //   }));
  // }

  


}



