import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import {
  IColumns,
  IExportExcel,
  IScroll,
  Toolbar,
  IColumnHiding,
  ICheckbox,
  IEditing,
  IColumnchooser,
  TiposdeDato,
  TiposdeFormato,
} from "@models/grid.interfaces";
import { MatSnackBar } from "@angular/material";
import { UserService } from "@shared/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormControl, Validators } from "@angular/forms";
import { CargaMasivaService } from "@shared/carga-masiva.service";
import { BitacoraConsultasDevService } from "@shared/bitacora-consultas-dev.service";
import { ListasControlConsultaHits } from "@shared/listas-control-consulta-hits.service";
import { ListasControlAutReg } from "@shared/listas-control-autorizacion_reg.service";
import { AdminListas } from "@shared/admin-listas.service";
import { SecureStorage } from "@utils/secure-storage";
import { LocalStorageService } from "@services/local-storage.service";
import { KEYS_STORAGE } from "@constants/KeysStorage";
import { formatDate, formatDateTime } from "src/app/core/utils/date";
import { QueriesStatusComponent } from "./queries-status/queries-status.component";
import { Subscription } from 'rxjs';
import { formatDateGR } from "src/app/core/utils/dateConvert";

@Component({
  selector: "app-bitacora-consultas-dev",
  templateUrl: "./bitacora-consultas-dev.component.html",
  styleUrls: ["./bitacora-consultas-dev.component.scss"],
})
export class BitacoraConsultasDevComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  wait: boolean = true;
  datosHits: any;
  datosLC: any;
  datosNivelRiesgo: any;
  datosStatus: any;
  listaActEconomica: any;
  listaTipoPersona: any;
  listaProdServ: any;
  listaODRecursos: any;
  listaUbicacionGeo: any;
  listaVolOper: any;
  maxDate = new Date();
  columns: IColumns[];
  columnsAlto: IColumns[];
  columnsAltoPEP: IColumns[];
  columnsBMI: IColumns[];

  toolbar: Toolbar[];
  toolbarRisk: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  select_datosBC: any;
  inicio: string = "";
  fin: string = "";
  estatus: string = "";
  gradoRiesgos = [];
  riesgo: string = "";
  usuario: any;
  nomina: any;
  riesgo_temp: any;
  listasNegras = null;
  coincidencia_tabla = "";

  color = "primary";
  mode = "indeterminate";
  value = 50;
  displayProgressSpinner = false;
  url: any;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,
    public cargaMasiva: CargaMasivaService,
    public consultaHitsService: ListasControlConsultaHits,
    public bitacoraConsultasDevService: BitacoraConsultasDevService,
    private localStorage: LocalStorageService,
    public dataServiceAut: ListasControlAutReg,
    public dataServiceAdminListas: AdminListas
  ) {
    this.url = localStorage.getObject(KEYS_STORAGE.url);

    this.datosHits = [];
    this.wait = true;
    this.getIsAuthentication();
    this.paramsOfGridDataInitial();
    this.toolbarRisk = [
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          width: 90,
          text: "Cambiar Estatus",
          onClick: this.receiveMessageRisk.bind(this, "change_status"),
        },
        visible: false,
        name: "simple",
      },
    ];
  }

  ngOnInit() {
    this.loadData();
    this.getAllNameLC();
    this.wait = true;
    this.datosHits = null;
    this.datosLC = [];
    this.datosNivelRiesgo = [];
    this.getAllNivelRiesgo();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getAllNameLC() {
    this.listasNegras = null;
    this.dataServiceAdminListas
      .getAllNameLC()
      .toPromise()
      .then((data: any) => {
        this.listasNegras = data;
      })
      .catch((err) => {
        this.listasNegras = null;
      });
  }

  getIsAuthentication() {
    if (!this.userService.getUserLogIn()) this.route.navigate(["./login"]);
  }

  paramsOfGridDataInitial() {
    //Grid de Datos para mostrar Hits
    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: "Listado de Información" };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: "multiple" }; // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: true }; // parametros de para seleccion de columnas

    this.scroll = { mode: "standard" }; // Parametros de Scroll
    this.columnsAltoPEP = [
      // Columnas
      {
        caption: "ID cliente",
        dataField: "bp",
        hiddingPriority: "0",
      },
      {
        caption: "Nombre completo",
        dataField: "nombre_completo",
        hiddingPriority: "0",
      },
      {
        caption: "Fecha de nacimiento",
        dataField: "fecha_nacimiento",
        dataType: TiposdeDato.date,
        format: TiposdeFormato.dmy,
        hiddingPriority: "0",
      },
      {
        caption: "Coincidencia en Lista",
        dataField: "coincidencia_tabla",
        hiddingPriority: "0",
      },
      {
        caption: "Fecha de Detección",
        dataField: "FechaDeteccion",
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: "0",
      },
      {
        caption: "Oficina de servicios",
        dataField: "serviceOfficeID",
        hiddingPriority: "0",
      },
      {
        caption: "Nombre del producto",
        dataField: "descProd",
        hiddingPriority: "0",
      },
      {
        caption: "Grado de Riesgo",
        dataField: "nivel_riesgo",
        hiddingPriority: "0",
      },
      {
        caption: "Fecha inicial",
        dataField: "fecha_inicial",
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: "0",
      },
      {
        caption: "Fecha semestral",
        dataField: "fecha_semestral",
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: "0",
      },
      {
        caption: "Estatus Autorizador 1",
        dataField: "status_AprobIni",
        hiddingPriority: "0",
      },
      {
        caption: "Usuario que modifica",
        dataField: "usuario_AprobIni",
        hiddingPriority: "0",
      },
      {
        caption: "Fecha de modificación",
        dataField: "fecha_AprobIni",
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: "0",
      },
      {
        caption: "Estatus Autorizador 2",
        dataField: "status_AprobIni2",
        hiddingPriority: "0",
      },
      {
        caption: "Usuario que modifica",
        dataField: "usuario_AprobIni2",
        hiddingPriority: "0",
      },
      {
        caption: "Fecha de modificación",
        dataField: "fecha_AprobIni2",
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: "0",
      },
    ];

    this.columnsAlto = [
      // Columnas
      {
        caption: "ID cliente",
        dataField: "bp",
        hiddingPriority: "0",
      },
      {
        caption: "Nombre completo",
        dataField: "nombre_completo",
        hiddingPriority: "0",
      },
      {
        caption: "Fecha de nacimiento",
        dataField: "fecha_nacimiento",
        dataType: TiposdeDato.date,
        format: TiposdeFormato.dmy,
        hiddingPriority: "0",
      },
      {
        caption: "Coincidencia en Lista",
        dataField: "coincidencia_tabla",
        hiddingPriority: "0",
      },
      {
        caption: "Fecha de Detección",
        dataField: "FechaDeteccion",
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: "0",
      },
      {
        caption: "Oficina de servicios",
        dataField: "serviceOfficeID",
        hiddingPriority: "0",
      },
      {
        caption: "Nombre del producto",
        dataField: "descProd",
        hiddingPriority: "0",
      },
      {
        caption: "Grado de Riesgo",
        dataField: "nivel_riesgo",
        hiddingPriority: "0",
      },
      {
        caption: "Fecha inicial",
        dataField: "fecha_inicial",
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: "0",
      },
      {
        caption: "Fecha semestral",
        dataField: "fecha_semestral",
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: "0",
      },
      {
        caption: "Estatus Autorizador 1",
        dataField: "status_AprobIni",
        hiddingPriority: "0",
      },
      {
        caption: "Usuario que modifica",
        dataField: "usuario_AprobIni",
        hiddingPriority: "0",
      },
      {
        caption: "Fecha de modificación",
        dataField: "fecha_AprobIni",
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: "0",
      },
    ];

    this.columnsBMI = [
      // Columnas
      {
        caption: "ID cliente",
        dataField: "bp",
        hiddingPriority: "0",
      },
      {
        caption: "Nombre completo",
        dataField: "nombre_completo",
        hiddingPriority: "0",
      },
      {
        caption: "Fecha Nacimiento",
        dataField: "fecha_nacimiento",
        dataType: TiposdeDato.date,
        format: TiposdeFormato.dmy,
        hiddingPriority: "0",
      },
      {
        caption: "Coincidencia en Lista",
        dataField: "coincidencia_tabla",
        hiddingPriority: "0",
      },
      {
        caption: "Fecha de Detección",
        dataField: "FechaDeteccion",
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: "0",
      },
      {
        caption: "Oficina de servicios",
        dataField: "serviceOfficeID",
        hiddingPriority: "0",
      },
      {
        caption: "Nombre del producto",
        dataField: "descProd",
        hiddingPriority: "0",
      },
      {
        caption: "Grado de Riesgo",
        dataField: "nivel_riesgo",
        hiddingPriority: "0",
      },
      {
        caption: "Fecha inicial",
        dataField: "fecha_inicial",
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: "0",
      },
      {
        caption: "Fecha semestral",
        dataField: "fecha_semestral",
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: "0",
      },
      {
        caption: "Usuario que modifica",
        dataField: "UsuarioAprobacionInicial",
        hiddingPriority: "0",
      },
      {
        caption: "Fecha de modificación",
        dataField: "fecha_consulta",
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: "0",
      },
    ];

    this.toolbar = [
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          width: 100,
          text: "Cambiar Nivel de Riesgo",
          //onClick: this.receiveMessageDoc.bind(this, 'change_status')
        },
        visible: false,
        name: "simple",
      },
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          width: 90,
          text: "Autorizar",
          //onClick: this.receiveMessageDoc.bind(this, 'autorizar')
        },
        visible: false,
        name: "simple",
      },
    ];
  }

  getAllNivelRiesgo() {
    this.datosNivelRiesgo = null;
    this.gradoRiesgos.push({ nombre: "Alto + PEP" });
    // this.consultaHitsService.getAllNivelRiesgo().toPromise().then((data: any) => {
    //   this.gradoRiesgos = data;
    //   this.gradoRiesgos.push({ 'nombre': 'Alto + PEP' });
    // }).catch((err) => {
    //   this.datosNivelRiesgo = null;
    // });
  }

  limpiarFiltros() {
    this.riesgo = "";
    this.inicio = "";
    this.fin = "";
    this.estatus = "";
    this.coincidencia_tabla = "";
  }

  setWrite(name) {
    let user = JSON.parse(
      SecureStorage.decrypt(localStorage.getItem("currentUser"))
    );
    let permissions = user.permissions;
    if (permissions.length > 0) {
      let element = permissions.filter((d) => d.name == name);
      if (element.length > 0) {
        return element[0].write;
      }
      return true;
    }
    return true;
  }

  cambiarEtiquetas(data) {
    if (data.value != "LPEP" && this.datosStatus && this.datosStatus[2]) {
      this.datosStatus[2].statusFront = "PENDIENTE POR AUTORIZAR";
    } else if (
      data.value == "LPEP" &&
      this.datosStatus &&
      this.datosStatus[2]
    ) {
      this.datosStatus[2].statusFront = "EN ANALISIS";
    }
  }
  
  loadBitacoraConsultasDev() {
    let body = {
      inicio: this.inicio,
      fin: this.fin,
      estatus: this.estatus,
      riesgo: this.riesgo,
      coincidencia_tabla: this.coincidencia_tabla,
    };
    this.riesgo_temp = this.riesgo;
    this.wait = true;
    this.wait = true;
    if (this.setWrite(this.riesgo)) {
      this.toolbarRisk = [
        {
          location: "after",
          widget: "dxButton",
          locateInMenu: "auto",
          options: {
            width: 90,
            text: "Cambiar Estatus",
            onClick: this.receiveMessageRisk.bind(this, "change_status"),
          },
          visible: false,
          name: "simple",
        },
      ];
    } else {
      this.toolbarRisk = [];
    }
    this.displayProgressSpinner = true;
    this.bitacoraConsultasDevService
      .getGradoRiesgoByParams(body)
      .toPromise()
      .then((res) => {
        this.wait = false;
        this.displayProgressSpinner = false;
        if (res.datos == "sin registro") {
          this.datosHits = null;
          this.wait = true;
        } else {
          this.datosHits = formatDateGR(res.datos || []);
          if (this.riesgo == "Alto + PEP") {
            this.columns = this.columnsAltoPEP;
          } else if (this.riesgo == "Alto") {
            this.columns = this.columnsAlto;
          } else {
            this.columns = this.columnsBMI;
          }
        }
      })
      .catch((err) => {
        console.error(err);
        this.displayProgressSpinner = false;
        this.wait = true;
        this.snackBar.open(`Ocurrio un error al realizar la consulta`, "close");
      });
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + "\n" + err.error.text;
      } catch {}
      this.snackBar.open(msj, "close");
    }
  }

  loadData() {
    this.dataServiceAdminListas
      .getStatus()
      .toPromise()
      .then((res) => {
        this.datosStatus = res;
        if (this.datosStatus["Code"] == "404") {
          this.datosStatus = null;
        }
      });
  }

  receiveMessageRisk($event) {
    try {
      switch ($event) {
        case "change_status":
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.change_status(d, this.datosStatus);
          }
          break;
        case "loadD":
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + "\n" + err.error.text;
      } catch {}
      this.snackBar.open(msj, "close");
    }
  }

  check_SendAutProxy(reg, approver) {
    //id,rg,nivel_riesgo,approver,resolId,numAprob,idStatus_AprobIni,idStatus_AprobIni2){
    var id = reg["id"];
    var rg = reg["solicitud_GR"];
    var numAprob = reg["num_Aprobaciones"];
    var nivel_riesgo = "";
    if (reg["nivel_riesgo"] != null && reg["nivel_riesgo"] != "") {
      nivel_riesgo = reg["nivel_riesgo"];
    }
    var idStatus_AprobIni = reg["idStatus_AprobIni"];
    var idStatus_AprobIni2 = reg["idStatus_AprobIni2"];
    var ejecutarProxy = false;
    var idResolution = "";
    if (numAprob == 2) {
      if (idStatus_AprobIni != null && idStatus_AprobIni2 != null) {
        if (idStatus_AprobIni == 1 && idStatus_AprobIni2 == 1) {
          idResolution = "1";
        } else if (idStatus_AprobIni == 0 || idStatus_AprobIni2 == 0) {
          idResolution = "0";
        }
        ejecutarProxy = true;
      }
    } else if (numAprob == 1) {
      if (idStatus_AprobIni != null) {
        ejecutarProxy = true;
        idResolution = idStatus_AprobIni;
      }
    }
    var json_str =
      '{"id":"' +
      id +
      '","RiskGradeRequest":"' +
      rg +
      '","RiskGradeResponse":"' +
      nivel_riesgo +
      '","Approver":"' +
      approver +
      '","Resolution":"' +
      idResolution +
      '"}';
    if (ejecutarProxy) {
      this.dataServiceAut
        .postGradoRiesgo(json_str)
        .toPromise()
        .then((res) => {
          this.snackBar.open(`Se ha autorizado correctamente`, "ok", {
            duration: 2500,
          });
        })
        .catch((err) => {
          this.wait = true;
          this.snackBar.open(
            `Ocurrio un error al ejecuar servicio proxy`,
            "close"
          );
        });
    }
  }

  getErrorMessage() {}

  change_status(datos: any, datosSta: any) {
    this.usuario = this.userService.user.nombre;
    this.nomina = this.userService.user.nomina;
    const data = { data: datos, status: datosSta };
    const dialogRef = this.dialog.open(QueriesStatusComponent, {
      data,
      restoreFocus: false,
      autoFocus: false,
    });

    this.subscription.add(dialogRef.afterClosed()
    .subscribe(x => {
      if (x.success) {
        this.loadBitacoraConsultasDev();
      }
    }));
  }
}

@Component({
  selector: "app-changeStatus-grado-riesgo",
  templateUrl: "./changeStatus.component.html",
})
export class ChangeStatusRiskGradeComponent {
  nombre = "Autorizacion Grado de Riesgo";
  dataAut: any;
  dataStatus: any;
  inicial = false;
  semes = false;
  flagEstatus: Boolean;
  //flagLista: any;
  constructor(
    public dialogRef: MatDialogRef<ChangeStatusRiskGradeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dataAut = data[0];
    this.dataStatus = data[1];
    this.dataAut.estatusAutIni = null;
    this.dataAut.estatusAutSemes = null;
    //this.flagLista = data[3]
    data = data[0];
    this.flagEstatus = false;
    if (this.dataAut.actual == 1 || this.dataAut.actual == "1") {
      if (this.dataAut.num_Aprobaciones == 1) {
        if (
          this.dataAut.status_AprobIni == "Rechazado" ||
          this.dataAut.status_AprobIni == "Aprobado"
        ) {
          this.flagEstatus = false;
        } else {
          this.flagEstatus = true;
          if (
            this.data[2].nombre == this.dataAut.usuario_AprobIni ||
            this.data[2].nombre == this.dataAut.usuario_AprobIni2
          ) {
            this.flagEstatus = false;
          }
        }
      } else if (this.dataAut.num_Aprobaciones == 2) {
        if (
          (this.dataAut.status_AprobIni == "Rechazado" ||
            this.dataAut.status_AprobIni == "Aprobado") &&
          (this.dataAut.status_AprobIni2 == "Rechazado" ||
            this.dataAut.status_AprobIni2 == "Aprobado")
        ) {
          this.flagEstatus = false;
        } else {
          this.flagEstatus = true;
          if (
            this.data[2].nombre == this.dataAut.usuario_AprobIni ||
            this.data[2].nombre == this.dataAut.usuario_AprobIni2
          ) {
            this.flagEstatus = false;
          }
        }
      }
    } else {
      this.flagEstatus = false;
    }
  }

  formControl = new FormControl("", [Validators.required]);

  getErrorMessage() {
    return this.formControl.hasError("required") ? "Required field" : "";
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
