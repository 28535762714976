export const environment = {
  conf: '/config/config.json',
  production: true,
  logTimeMinutes: 1115,
  version:"1.0",
  usersHardCode: [
    {
      name: 'usertestsubdirector',
      password: 'usertest',
      perfil: 'SudirectorVentas'
    },
    {
      name: 'usertestanalista',
      password: 'usertest',
      perfil: 'AnalistaPLD'
    },
    {
      name: 'userliderpld',
      password: 'usertest',
      perfil: 'LiderPLD'
    },
    {
      name: 'mesa',
      password: 'usertest',
      perfil: 'MesaServicio'
    }
  ],
};


