import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SignIn} from '../interfaces/signIn';
import {LocalStorageService} from './local-storage.service';
import {SecureStorage} from '@utils/secure-storage';
import {SesionStorageService} from "./sesion-storage.service";

@Injectable({
  providedIn: 'root'
})
export class AuthconfigService {

  public actualUserSubject!: BehaviorSubject<any>;
  public actualUser: Observable<SignIn>;

  constructor(
    private storageService: SesionStorageService
  ) {
    const user = this.storageService.getObject('user');
    this.actualUserSubject = new BehaviorSubject<any>(user);
    this.actualUser = this.actualUserSubject.asObservable();
  }

  configure(user: SignIn): void {
    this.actualUserSubject.next(user);
    this.storageService.setObject('user', user);
  }

  deleteConfiguration() {
    this.actualUserSubject.next(null);
    this.storageService.removeByKey('user');
  }

  get currentUser() {
    return this.storageService.getObject('user');
  }

  get currentUserType() {
    const type = JSON.parse(SecureStorage.decrypt(localStorage.getItem('typeUser')));
    return type;
  }

  get authUserExists(): boolean {
    return !!this.actualUserSubject.value;
  }


}
