/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '../local-storage.service';
import { KEYS_STORAGE } from '../../core/constants/KeysStorage';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
	constructor(
		private localstorage: LocalStorageService,
		private http: HttpClient
	) {}

	loadAppConfig() {
		return this.http.get(environment.conf)
		.toPromise()
		.then((res: any) => {
			this.localstorage.setObject(KEYS_STORAGE.url, res);
		});
	}
}
