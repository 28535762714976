import { IColumns, TiposdeDato, TiposdeFormato } from "@models/grid.interfaces";

export const COLUMNS_ALTO_PEP: IColumns[] = [
  {
    caption: "ID cliente",
    dataField: "bp",
    hiddingPriority: "0",
  },
  {
    caption: "Nombre completo",
    dataField: "nombre_completo",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha de nacimiento",
    dataField: "fecha_nacimiento",
    dataType: TiposdeDato.date,
    format: TiposdeFormato.dmy,
    hiddingPriority: "0",
  },
  {
    caption: "Coincidencia en Lista",
    dataField: "coincidencia_tabla",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha de Detección",
    dataField: "FechaDeteccion",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
  {
    caption: "Oficina de servicios",
    dataField: "serviceOfficeID",
    hiddingPriority: "0",
  },
  {
    caption: "Nombre del producto",
    dataField: "descProd",
    hiddingPriority: "0",
  },
  {
    caption: "Grado de Riesgo Inicial",
    dataField: "nivel_riesgo",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha inicial",
    dataField: "fecha_inicial",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
  {
    caption: "Fecha semestral",
    dataField: "fecha_semestral",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
  {
    caption: "Estatus Autorizador 1",
    dataField: "status_AprobIni",
    hiddingPriority: "0",
  },
  {
    caption: "Usuario que modifica",
    dataField: "usuario_AprobIni",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha de modificación",
    dataField: "fecha_AprobIni",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
  {
    caption: "Estatus Autorizador 2",
    dataField: "status_AprobIni2",
    hiddingPriority: "0",
  },
  {
    caption: "Usuario que modifica",
    dataField: "usuario_AprobIni2",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha de modificación",
    dataField: "fecha_AprobIni2",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
  {
    caption: "Tipo",
    dataField: "tipo",
    allowEditing: false,
    width: 100,
  },
];

export const COLUMNS_ALTO: IColumns[] = [
  {
    caption: "ID cliente",
    dataField: "bp",
    hiddingPriority: "0",
  },
  {
    caption: "Nombre completo",
    dataField: "nombre_completo",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha de nacimiento",
    dataField: "fecha_nacimiento",
    dataType: TiposdeDato.date,
    format: TiposdeFormato.dmy,
    hiddingPriority: "0",
  },
  {
    caption: "Coincidencia en Lista",
    dataField: "coincidencia_tabla",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha de Detección",
    dataField: "FechaDeteccion",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
  {
    caption: "Oficina de servicios",
    dataField: "serviceOfficeID",
    hiddingPriority: "0",
  },
  {
    caption: "Nombre del producto",
    dataField: "descProd",
    hiddingPriority: "0",
  },
  {
    caption: "Grado de Riesgo",
    dataField: "nivel_riesgo",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha inicial",
    dataField: "fecha_inicial",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
  {
    caption: "Fecha semestral",
    dataField: "fecha_semestral",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
  {
    caption: "Estatus Autorizador 1",
    dataField: "status_AprobIni",
    hiddingPriority: "0",
  },
  {
    caption: "Usuario que modifica",
    dataField: "usuario_AprobIni",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha de modificación",
    dataField: "fecha_AprobIni",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
  {
    caption: "Tipo",
    dataField: "tipo",
    allowEditing: false,
    width: 100,
  },
];

export const COLUMNS_BMI: IColumns[] = [
  // Columnas
  {
    caption: "ID cliente",
    dataField: "bp",
    hiddingPriority: "0",
  },
  {
    caption: "Nombre completo",
    dataField: "nombre_completo",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha Nacimiento",
    dataField: "fecha_nacimiento",
    dataType: TiposdeDato.date,
    format: TiposdeFormato.dmy,
    hiddingPriority: "0",
  },
  {
    caption: "Coincidencia en Lista",
    dataField: "coincidencia_tabla",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha de Detección",
    dataField: "FechaDeteccion",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
  {
    caption: "Oficina de servicios",
    dataField: "serviceOfficeID",
    hiddingPriority: "0",
  },
  {
    caption: "Nombre del producto",
    dataField: "descProd",
    hiddingPriority: "0",
  },
  {
    caption: "Grado de Riesgo",
    dataField: "nivel_riesgo",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha inicial",
    dataField: "fecha_inicial",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
  {
    caption: "Fecha semestral",
    dataField: "fecha_semestral",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
  {
    caption: "Usuario que modifica",
    dataField: "UsuarioAprobacionInicial",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha de modificación",
    dataField: "fecha_consulta",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
  {
    caption: "Tipo",
    dataField: "tipo",
    allowEditing: false,
    width: 100,
  },
];
