import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { GridComponentComponent } from "./components/grid-component/grid-component.component";
import { LoginComponent } from "./components/login/login.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { UserService } from "./shared/services/user.service";
import { SidebarDirective } from "./sidebar.directive";

// ANGULAR MATERIAL COMPONENTS
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";

import { MaterialModules } from "./material-module";
import {
  DevExtremeModule,
  DxAutocompleteModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDropDownBoxModule,
  DxFileUploaderModule,
  DxSelectBoxModule,
  DxTemplateModule,
} from "devextreme-angular";
import { ProgressSpinnerComponent } from "./components/progress-spinner/progress-spinner.component";
import { AppOverlayModule } from "./components/overlay/overlay.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { DataService } from "./shared/services/data.service";
import { UploadComponent } from "./components/upload/upload.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import {
  AddModulesComponent,
  DelModulesComponent,
  ModulosComponent,
  UpdModulesComponent,
} from "./components/modulos/modulos.component";
import {
  AddSystemComponent,
  DelSystemComponent,
  SystemsComponent,
  UpdSystemComponent,
} from "./components/systems/systems.component";
import {
  AddListBlackComponent,
  DelListBlackComponent,
  DetListBlackComponent,
  FindListBlackComponent,
  ListasControlBlackComponent,
  UpdListBlackComponent,
} from "./components/listas-control-black/listas-control-black.component";
import {
  AddListWhiteComponent,
  ListasControlWhiteComponent,
  UpdListWhiteComponent,
} from "./components/listas-control-white/listas-control-white.component";
import {
  AddProductosServiciosComponent,
  DelProductosServiciosComponent,
  ProductosServiciosComponent,
  UpdProductosServiciosComponent,
} from "./components/productos-servicios/productos-servicios.component";
import {
  AddOrigDestRecursosComponent,
  DelOrigDestRecursosComponent,
  OrigDestRecursosComponent,
  UpdOrigDestRecursosComponent,
} from "./components/orig-dest-recursos/orig-dest-recursos.component";
import {
  AddUbicacionGeograficaComponent,
  DelUbicacionGeograficaComponent,
  UbicacionGeograficaComponent,
  UpdUbicacionGeograficaComponent,
} from "./components/ubicacion-geografica/ubicacion-geografica.component";
import {
  AddFactoresRiesgo,
  DelFactoresRiesgo,
  GradoRiesgoComponent,
  UpdFactoresRiesgo,
} from "./components/grado-riesgo/grado-riesgo.component";
import { CdkAccordionModule } from "@angular/cdk/accordion";
/**
 * DOC FACTORY
 */
import { SelectorCompanyComponent } from "./components/selector-company/selector-company.component";
import {
  AddEnterprisesComponent,
  DelEnterprisesComponent,
  EnterprisesComponent,
  UpdEnterprisesComponent,
} from "./components/enterprises/enterprises.component";
/**
 * SERVICES DOC FACTORY
 */
import { EnterpriseService } from "./shared/services/providers.service";
import { APIService } from "./shared/services/api.service";
import {
  AddListBLOQComponent,
  DelListBLOQComponent,
  ListasControlBLOQComponent,
} from "./components/listas-control-bloq/listas-control-bloq.component";
import {
  AddListGRISComponent,
  DelListGRISComponent,
  ListasControlGRISComponent,
  UpdListGRISComponent,
} from "./components/listas-control-gris/listas-control-gris.component";
import {
  AddListOFACComponent,
  DelListOFACComponent,
  ListasControlOFACComponent,
  UpdListOFACComponent,
} from "./components/listas-control-ofac/listas-control-ofac.component";
import {
  AddListPEPComponent,
  DelListPEPComponent,
  ListasControlPEPComponent,
  UpdListPEPComponent,
} from "./components/listas-control-pep/listas-control-pep.component";
import {
  AddListPLDYFComponent,
  DelListPLDYFComponent,
  ListasControlPLDYFComponent,
  UpdListPLDYFComponent,
} from "./components/listas-control-pldyf/listas-control-pldyf.component";
import {
  ListasControlAutorizacionComponent,
  UpdListAutComponent,
} from "./components/listas-control-autorizacion/listas-control-autorizacion.component";
import {
  AdminListasControlComponent,
} from "./components/admin-listas-control/admin-listas-control.component";
import { AdminListStatusComponent } from "./components/admin-listas-control/admin-list-status/admin-list-status.component";
import {
  AutListHitsComponent,
  ListasControlConsultaHitsComponent,
  UpdListHitsComponent,
} from "./components/listas-control-consulta-hits/listas-control-consulta-hits.component";
import {
  BitacoraConsultasDevComponent,
  ChangeStatusRiskGradeComponent,
} from "./components/bitacora-consultas-dev/bitacora-consultas-dev.component";
import { QueriesStatusComponent } from "./components/bitacora-consultas-dev/queries-status/queries-status.component";
import { UnlockGeoComponent } from "./components/consulta-hit-geolocalizacion/unlock-geo/unlock-geo.component";
import {
  AddGiroActi,
  DelGiroActi,
  ListasControlGiroActiComponent,
  UpdGiroAct,
} from "./components/listas-control-giro-acti/listas-control-giro-acti.component";
import {
  AddSectorActi,
  DelSectorActividad,
  ListasControlSectorActiComponent,
  UpdSectorAct,
} from "./components/listas-control-sector-acti/listas-control-sector-acti.component";
import {
  ActividadEconomicaComponent,
  AddActividadEconomica,
  DelActividadEconomicaComponent,
  UpdActividadEconomica,
} from "./components/actividad-economica/actividad-economica.component";
import {
  AddVolumen,
  DelVolumenComponent,
  UpdVolumen,
  VolumenComponent,
} from "./components/volumen/volumen.component";
import {
  AddTipoPersona,
  DelTipoPersonaComponent,
  TipoPersonaComponent,
  UpdTipoPersona,
} from "./components/tipo-persona/tipo-persona.component";
import {
  AddFactorRiesgo,
  DelFactorRiesgo,
  ListasControlConfigFactoresRiesgoComponent,
  UpdFactorRiesgo,
} from "./components/listas-control-config-factores-riesgo/listas-control-config-factores-riesgo.component";
import {
  ConfiguracionListasComponent,
  UpdEstatusListas,
} from "./components/configuracion-listas/configuracion-listas.component";
import {
  AddNivelRiesgo,
  ConfigNivelComponent,
  DelNivelRiesgo,
  UpdNivelRiesgo,
} from "./components/config-nivel/config-nivel.component";
import { LogListComponent } from "./components/log-list/log-list.component";
import {
  AutorizaGrVentana2Component,
  ChangeStatusRGComponent,
} from "./components/autoriza-gr-ventana2/autoriza-gr-ventana2.component";
import { LcConsultaHitComponent } from "./components/lc-consulta-hit/lc-consulta-hit.component";
import { LcConsultaGrComponent } from "./components/lc-consulta-gr/lc-consulta-gr.component";
import {
  AddMotivoIngresoComponent,
  DelMotivoIngresoComponent,
  MotivosIngresoComponent,
  UpdMotivoIngresoComponent,
} from "./components/motivos-ingreso/motivos-ingreso.component";
import { SpinnerLoadComponent } from "./components/spinner-load/spinner-load.component";
import { ConfirmacionGradoComponent } from "./components/confirmacion-grado/confirmacion-grado.component";
import { CambioEstatusGradoRiesgoComponent } from "./components/cambio-estatus-grado-riesgo/cambio-estatus-grado-riesgo.component";
import { ConsultaHitGeolocalizacionComponent } from "./components/consulta-hit-geolocalizacion/consulta-hit-geolocalizacion.component";
import { LayoutComponent } from "./components/ui/layout/layout.component";
import { CrudComponent } from "./components/custom/crud/crud.component";
import { TitleComponent } from "./components/ui/title/title.component";
import { CrudFiltersComponent } from "./components/custom/crud-filters/crud-filters.component";
import { CrudTestComponent } from "./components/custom/crud-test/crud-test.component";
import { InicioComponent } from "./components/inicio/inicio.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SidebarComponent } from "./components/custom/sidebar/sidebar.component";
import { ModalEditGradoRiesgoComponent } from "./components/cambio-estatus-grado-riesgo/modal-edit-grado-riesgo/modal-edit-grado-riesgo.component";
import { TablaCammbioGradoRiesgoComponent } from "./components/cambio-estatus-grado-riesgo/tabla-cammbio-grado-riesgo/tabla-cammbio-grado-riesgo.component";
import { AppConfigService } from "./services/config/app-config.service";
import { LcConsultaHitRespComponent } from "./components/lc-consulta-hit-resp/lc-consulta-hit-resp.component";
import { LcConsultaGrRespComponent } from "./components/lc-consulta-gr-resp/lc-consulta-gr-resp.component";
import { ToolbarDialogComponent } from "./shared/components/toolbar-dialog/toolbar-dialog.component";
import { TimerNoti } from "./core/utils/timer";

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  entryComponents: [
    AddModulesComponent,
    UpdModulesComponent,
    DelModulesComponent,
    AddSystemComponent,
    DelSystemComponent,
    UpdSystemComponent,
    ListasControlWhiteComponent,
    AddListWhiteComponent,
    UpdListWhiteComponent,
    ListasControlBlackComponent,
    AddListBlackComponent,
    FindListBlackComponent,
    UpdListBlackComponent,
    DelListBlackComponent,
    DetListBlackComponent,
    SelectorCompanyComponent,
    EnterprisesComponent,
    AddEnterprisesComponent,
    UpdEnterprisesComponent,
    DelEnterprisesComponent,
    ListasControlGRISComponent,
    AddListGRISComponent,
    UpdListGRISComponent,
    DelListGRISComponent,
    ListasControlOFACComponent,
    AddListOFACComponent,
    UpdListOFACComponent,
    DelListOFACComponent,
    DelListOFACComponent,
    ListasControlPEPComponent,
    AddListPEPComponent,
    UpdListPEPComponent,
    DelListPEPComponent,
    ListasControlPLDYFComponent,
    AddListPLDYFComponent,
    UpdListPLDYFComponent,
    DelListPLDYFComponent,
    DelListPLDYFComponent,
    ListasControlBLOQComponent,
    AddListBLOQComponent,
    DelListBLOQComponent,
    ListasControlAutorizacionComponent,
    UpdListAutComponent,
    AdminListasControlComponent,
    AdminListStatusComponent,
    ListasControlConsultaHitsComponent,
    UpdListHitsComponent,
    AutListHitsComponent,
    BitacoraConsultasDevComponent,
    ChangeStatusRiskGradeComponent,
    QueriesStatusComponent,
    UnlockGeoComponent,
    ListasControlGiroActiComponent,
    ListasControlSectorActiComponent,
    AddGiroActi,
    DelGiroActi,
    UpdGiroAct,
    AddSectorActi,
    UpdSectorAct,
    DelSectorActividad,
    ProductosServiciosComponent,
    AddProductosServiciosComponent,
    UpdProductosServiciosComponent,
    DelProductosServiciosComponent,
    OrigDestRecursosComponent,
    AddOrigDestRecursosComponent,
    UpdOrigDestRecursosComponent,
    DelOrigDestRecursosComponent,
    UbicacionGeograficaComponent,
    AddUbicacionGeograficaComponent,
    UpdUbicacionGeograficaComponent,
    DelUbicacionGeograficaComponent,
    AddActividadEconomica,
    UpdActividadEconomica,
    DelActividadEconomicaComponent,
    AddVolumen,
    DelVolumenComponent,
    UpdVolumen,
    AddTipoPersona,
    DelTipoPersonaComponent,
    UpdTipoPersona,
    AddTipoPersona,
    DelTipoPersonaComponent,
    UpdTipoPersona,
    ListasControlConfigFactoresRiesgoComponent,
    AddFactorRiesgo,
    DelFactorRiesgo,
    UpdFactorRiesgo,
    GradoRiesgoComponent,
    AddFactoresRiesgo,
    UpdFactoresRiesgo,
    DelFactoresRiesgo,
    ConfiguracionListasComponent,
    UpdEstatusListas,
    AddNivelRiesgo,
    UpdNivelRiesgo,
    DelNivelRiesgo,
    AutorizaGrVentana2Component,
    ChangeStatusRGComponent,
    AddMotivoIngresoComponent,
    UpdMotivoIngresoComponent,
    DelMotivoIngresoComponent,
    ConfirmacionGradoComponent,
  ],
  declarations: [
    AppComponent,
    ToolbarDialogComponent,
    ProgressSpinnerComponent,
    GridComponentComponent,
    SidebarDirective,
    LoginComponent,
    UploadComponent,
    AddModulesComponent,
    UpdModulesComponent,
    DelModulesComponent,
    ModulosComponent,
    SystemsComponent,
    AddSystemComponent,
    DelSystemComponent,
    UpdSystemComponent,
    ListasControlWhiteComponent,
    AddListWhiteComponent,
    UpdListWhiteComponent,
    ListasControlBlackComponent,
    AddListBlackComponent,
    FindListBlackComponent,
    UpdListBlackComponent,
    DelListBlackComponent,
    DetListBlackComponent,
    EnterprisesComponent,
    AddEnterprisesComponent,
    UpdEnterprisesComponent,
    DelEnterprisesComponent,
    SelectorCompanyComponent,
    ListasControlBLOQComponent,
    AddListBLOQComponent,
    DelListBLOQComponent,
    ListasControlGRISComponent,
    AddListGRISComponent,
    UpdListGRISComponent,
    DelListGRISComponent,
    ListasControlOFACComponent,
    AddListOFACComponent,
    UpdListOFACComponent,
    DelListOFACComponent,
    ListasControlPEPComponent,
    AddListPEPComponent,
    UpdListPEPComponent,
    DelListPEPComponent,
    ListasControlPLDYFComponent,
    AddListPLDYFComponent,
    UpdListPLDYFComponent,
    DelListPLDYFComponent,
    ListasControlAutorizacionComponent,
    UpdListAutComponent,
    AdminListasControlComponent,
    AdminListStatusComponent,
    ListasControlConsultaHitsComponent,
    UpdListHitsComponent,
    AutListHitsComponent,
    BitacoraConsultasDevComponent,
    QueriesStatusComponent,
    UnlockGeoComponent,
    ChangeStatusRiskGradeComponent,
    ListasControlGiroActiComponent,
    ListasControlSectorActiComponent,
    AddGiroActi,
    DelGiroActi,
    UpdGiroAct,
    AddSectorActi,
    UpdSectorAct,
    DelSectorActividad,
    ProductosServiciosComponent,
    AddProductosServiciosComponent,
    UpdProductosServiciosComponent,
    DelProductosServiciosComponent,
    OrigDestRecursosComponent,
    AddOrigDestRecursosComponent,
    UpdOrigDestRecursosComponent,
    DelOrigDestRecursosComponent,
    UbicacionGeograficaComponent,
    AddUbicacionGeograficaComponent,
    UpdUbicacionGeograficaComponent,
    DelUbicacionGeograficaComponent,
    ActividadEconomicaComponent,
    AddActividadEconomica,
    UpdActividadEconomica,
    DelActividadEconomicaComponent,
    VolumenComponent,
    AddVolumen,
    DelVolumenComponent,
    UpdVolumen,
    TipoPersonaComponent,
    AddTipoPersona,
    DelTipoPersonaComponent,
    UpdTipoPersona,
    TipoPersonaComponent,
    AddTipoPersona,
    DelTipoPersonaComponent,
    UpdTipoPersona,
    ListasControlConfigFactoresRiesgoComponent,
    AddFactorRiesgo,
    DelFactorRiesgo,
    UpdFactorRiesgo,
    GradoRiesgoComponent,
    AddFactoresRiesgo,
    UpdFactoresRiesgo,
    DelFactoresRiesgo,
    ConfiguracionListasComponent,
    UpdEstatusListas,
    ConfigNivelComponent,
    AddNivelRiesgo,
    UpdNivelRiesgo,
    DelNivelRiesgo,
    LogListComponent,
    AutorizaGrVentana2Component,
    ChangeStatusRGComponent,
    LcConsultaHitComponent,
    LcConsultaGrComponent,
    MotivosIngresoComponent,
    AddMotivoIngresoComponent,
    UpdMotivoIngresoComponent,
    DelMotivoIngresoComponent,
    SpinnerLoadComponent,
    ConfirmacionGradoComponent,
    CambioEstatusGradoRiesgoComponent,
    ConsultaHitGeolocalizacionComponent,
    LayoutComponent,
    CrudComponent,
    TitleComponent,
    CrudFiltersComponent,
    CrudTestComponent,
    InicioComponent,
    SidebarComponent,
    ModalEditGradoRiesgoComponent,
    TablaCammbioGradoRiesgoComponent,
    LcConsultaHitRespComponent,
    LcConsultaGrRespComponent,
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    FontAwesomeModule,
    AppOverlayModule,
    DevExtremeModule,
    DxDataGridModule,
    DxFileUploaderModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxButtonModule,
    DxDropDownBoxModule,
    DxAutocompleteModule,
    DxTemplateModule,
    MaterialModules,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    NgbModule,
    CdkAccordionModule,
  ],
  providers: [
    UserService,
    DataService,
    APIService,
    EnterpriseService,
    TimerNoti,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
