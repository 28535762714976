import { Component, OnInit ,Inject} from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { CargaMasivaService } from '@shared/carga-masiva.service';
import {IColumns, IExportExcel, IScroll, Toolbar, IColumnHiding, ICheckbox, IEditing, IColumnchooser} from '@models/grid.interfaces'
import { OrigDestRecursos } from '@shared/orig-dest-recursos.service';
import { ListasControlConsultaHits } from '@shared/listas-control-consulta-hits.service';

@Component({
  selector: 'app-orig-dest-recursos',
  templateUrl: './orig-dest-recursos.component.html',
  styleUrls: ['./orig-dest-recursos.component.scss']
})
export class OrigDestRecursosComponent implements OnInit {
  wait: boolean = false;
  waitFind: boolean = false;
  waitFile: boolean = false;
  listaProdServ: any;
  datosFind: any;
  datosTable: any;
  datoseventFind: any;
  nombresInput : string = '';
  idT: string = '' ;

  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,  
    public cargaMasiva: CargaMasivaService,
    public dataService: OrigDestRecursos,
    public consultaHitsService: ListasControlConsultaHits    
  ) {
    if (!userService.getUserLogIn()) route.navigate(['./login']);
    this.datosTable = [];
    this.datosFind = [];
    this.nombresInput = '';
    this.idT = '' ;

    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: 'Listado de Información' };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: false }; // parametros de para seleccion de columnas

    this.scroll = { mode: 'standard' }; // Parametros de Scroll
    this.columns = [ // Columnas
      {
        caption: 'Id',
        dataField: 'idod_recursos',
        hiddingPriority: '0'
      },
      {
        caption: 'Producto',
        dataField: 'producto',
        hiddingPriority: '0'
      },
      {
        caption: 'Origen destino-producto',
        dataField: 'origen_destino_prod',
        hiddingPriority: '0'
      },
      {
        caption: 'Riesgo',
        dataField: 'riesgo',
        hiddingPriority: '0'
      },
      {
        caption: 'Valor riesgo',
        dataField: 'valor_riesgo',
        hiddingPriority: '0'
      }
    ];

    this.toolbar = [
      /*{
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Agregar',
          onClick: this.receiveMessageDoc.bind(this, 'add')
        },
        visible: true
      },*/
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Editar',
          onClick: this.receiveMessageDoc.bind(this, 'edt')
        },
        visible: false,
        name: 'simple'
      },
      /*{
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Eliminar',
          onClick: this.receiveMessageDoc.bind(this, 'del')
        },
        visible: false,
        name: 'simple'
      },*/
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 110,
          text: 'Actualizar',
          onClick: this.receiveMessageDoc.bind(this, 'loadD')
        },
        visible: true
      }
    ];
  }

  ngOnInit() {
    this.loadData();
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch{ }
      this.snackBar.open(msj, 'close');
    }
  }
  
  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case 'add':
          this.add();
          break;
        case 'edt':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.upd(d)
          }
          break;
        case 'del':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.del(d)
          }
          break;
        case 'loadD':
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch{ }
      this.snackBar.open(msj, 'close');
    }
  }

  loadData() {
    this.wait = true;
    this.getAllFactoresRiesgo();
    this.dataService.getAll().toPromise().then((res) => {
      this.wait = false;    
      this.datosTable = res; 
    })
  }
  
  getAllFactoresRiesgo(){

    this.listaProdServ = null;
    this.consultaHitsService.getProd_serv().toPromise().then((data: any) => {
      this.listaProdServ = data;
    }).catch((err) => {
      this.listaProdServ = null;
    });

  }

  add() {    
    let datos = [this.listaProdServ];
    const dialogRef = this.dialog.open(AddOrigDestRecursosComponent, { data: datos });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.dataService.post(resDialog).toPromise().then((res) => {
          this.loadData();
          this.snackBar.open(`Se ha dado de alta correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch{ }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  upd(datos) {
    const dialogRef = this.dialog.open(UpdOrigDestRecursosComponent, { data: datos});
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.dataService.put(resDialog).toPromise().then((res) => {
          this.loadData();
          this.snackBar.open(`Se ha actualizado correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch{ }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  del(datos) {
    const dialogRef = this.dialog.open(DelOrigDestRecursosComponent, { data: datos  });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.dataService.delete(resDialog).toPromise().then((res) => {
          datos["status"] = 0; 
          this.loadData();
          this.snackBar.open(`Se ha dado de eliminado correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch{ }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }
}

interface Status {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-add-orig-dest-recursos',
  templateUrl: './add.component.html',
})
export class AddOrigDestRecursosComponent {
  data: any;
  nombre = 'Agrega ';
  prodServ: any;

  status: Status[] = [
    {value: '0', viewValue: 'Desactivado'},
    {value: '1', viewValue: 'Activado'}
  ];
  
  constructor(public dialogRef: MatDialogRef<AddOrigDestRecursosComponent> , @Inject(MAT_DIALOG_DATA) public data2: any ) {
    this.prodServ = data2[0];
    this.data = {}
    this.data.producto = "";
    this.data.origen_destino_prod ="";
    this.data.riesgo="";
    this.data.valor_riesgo="";
   }

  formControl = new FormControl('', [Validators.required]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }  

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-upd-orig-dest-recursos',
  templateUrl: './add.component.html',
})
export class UpdOrigDestRecursosComponent {

  status: Status[] = [
    {value: '0', viewValue: 'Desactivado'},
    {value: '1', viewValue: 'Activado'}
  ];

  nombre = 'Edita '
  sta: Status[] = [
    {value: '0', viewValue: 'Desactivado'},
    {value: '1', viewValue: 'Activado'}
  ];

  constructor(public dialogRef: MatDialogRef<UpdOrigDestRecursosComponent>, @Inject(MAT_DIALOG_DATA) public data: any) 
  { 
    if(data.status == 1){
      data.status = this.sta[1].value
    }
    else{
      data.status = this.sta[0].value
    }
  }

  formControl = new FormControl('', [ Validators.required
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-del-orig-dest-recursos',
  templateUrl: './del.component.html',
})
export class DelOrigDestRecursosComponent {
  nombre = 'Elimina ';
  constructor(public dialogRef: MatDialogRef<DelOrigDestRecursosComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  formControl = new FormControl('', [ Validators.required]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

