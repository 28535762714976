import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { Issue } from '../models/issue';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { LocalStorageService } from '@services/local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';

@Injectable()
export class DataService {
  private environmentUrl: any = {};
  httpOptions: any
  httpDocOptions: any

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
    this.environmentUrl = this.localStorage.getObject(KEYS_STORAGE.url) || {};
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', })
    }

    this.httpDocOptions = {
      headers: new HttpHeaders({'Content-Disposition': 'form-data; name=\"file\"'})
    }
  }

  /** CRUD METHODS */
  public getAllIssues = (): Observable<any> => {
    const url = `${this.environmentUrl.geolocationUrl}/data`;
    return this.http.get(url, this.httpOptions);
  }

  public getEntities = (): Observable<any> => {
    const url = `${this.environmentUrl.geolocationUrl}/entities`;
    return this.http.get(url, this.httpOptions);
  }

  public postDetail = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.geolocationUrl}/filterData`;
    return this.http.post(url, JSON.stringify(body), this.httpOptions);
  }

  public addIssue = (issue: Issue): Observable<any> =>  {
    const url = `${this.environmentUrl.geolocationUrl}/insert`;
    return this.http.post(url, JSON.stringify(issue), this.httpOptions);
  }

  public updateIssue = (issue: any): Observable<any> => {
    const url = `${this.environmentUrl.geolocationUrl}/update`;
    return this.http.post(url, JSON.stringify(issue), this.httpOptions);
  }

  public deleteIssue = (issue: Issue): Observable<any> => {
    const url = `${this.environmentUrl.geolocationUrl}/delete`;
    return this.http.post(url, JSON.stringify(issue), this.httpOptions);
  }

  public uploadDocument = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.geolocationUrl}/upload`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }
}