import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ModalService} from '../../../services/modal.service';
import {CambioEstatusGradoRiesgoService} from "@shared/cambio-estatus-grado-riesgo";
import {SecureStorage} from "@utils/secure-storage";
import {MatSnackBar} from "@angular/material/snack-bar";
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-edit-grado-riesgo',
  templateUrl: './modal-edit-grado-riesgo.component.html',
  styleUrls: ['./modal-edit-grado-riesgo.component.scss']
})
export class ModalEditGradoRiesgoComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  @Input()
  data$: Observable<any>;
  data: any;
  @Output() refresh = new EventEmitter();
  form: FormGroup;
  gradeDefault = 'Bajo'.toUpperCase();
  grades = ['Bajo', 'Medio', 'Alto', 'Inaceptable'].map(x => x.toUpperCase())

  constructor(
    private fb: FormBuilder,
    public modalService: ModalService,
    public service: CambioEstatusGradoRiesgoService,
    public snackBar: MatSnackBar
  ) {
    this.createForm();
    this.watchChangeToggleFalsePositive();
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  createForm() {
    this.form = this.fb.group({
      toggleFalsoPositivo: [false],
      grado: ["", [Validators.required]],
      comentarios: [""],
    });
  }

  get controls() {
    return this.form.controls;
  }

  get isToggleFalsePositive() {
    return this.controls['toggleFalsoPositivo'].value;
  }

  watchChangeToggleFalsePositive() {
    this.subscription.add(
      this.controls['toggleFalsoPositivo'].valueChanges
      .subscribe((isToggle: boolean) => {
        if (isToggle) {
          this.controls['grado'].setValue(this.data.grado || this.gradeDefault);
          this.controls['grado'].disable();
        } else {
          this.controls['grado'].enable();
          this.controls['grado'].setValue("");
        }

			})
		);
  }

  closeModal() {
    this.modalService.closeModal();
  }

  loadData() {
    this.subscription.add(
      this.data$.subscribe({
        next: (data: any) => {
          if (data) {
            this.data = data;
            const status: string = data.estatus_alerta || 'falso positivo';
            this.controls['toggleFalsoPositivo'].setValue(status.toLowerCase() === 'falso positivo');
            this.controls['comentarios'].setValue(data.comentarios);
            this.controls['grado'].setValue(data.grado || this.gradeDefault);
          }
        }
      })
    );
  }

  sendUpdate() {
    try {
      const usuario = JSON.parse(SecureStorage.decrypt(localStorage.getItem('currentUser')));
      const data = this.form.getRawValue();
      const json = {
        id: this.data.id,
        grado: data.grado,
        nombre_completo: this.data.nombre_completo,
        bp: this.data.bp,
        nombre_ofi: this.data.oficina,
        coincidencia : this.data.coincidencia,
        id_oficina: this.data.id_oficina,
        usuario: usuario.nombre,
        comentarios: data.comentarios,
        falso_positivo: data.toggleFalsoPositivo ? 1 : 0,
      };

      this.service.putCambiarGrado(json).toPromise().then((res) => {
        if (res.Status === 'True') {
          this.snackBar.open('Se actualizo correctamente', 'close');
          this.refresh.emit();
        } else if (res.Status === 'Falso Positivo') {
          // TODO IF TEMPORAL PORQUE SI GUARDA LA INFO
          this.snackBar.open('Se actualizo correctamente', 'close');
          this.refresh.emit();
        } else {
          this.snackBar.open('Error al actualizar', 'close');
        }
      });


    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch {
      }
      this.snackBar.open(msj, 'close');
    } finally {
      this.modalService.closeModal();
    }
  }

}
