import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { Router } from '@angular/router';
import { EnterpriseService } from '@shared/enterprise.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';

import {
  IColumns, IExportExcel,
  IScroll, Toolbar, IColumnHiding,
  ICheckbox, IEditing, IColumnchooser
} from '@models/grid.interfaces'
@Component({
  selector: 'app-enterprises',
  templateUrl: './enterprises.component.html',
  styleUrls: ['./enterprises.component.scss']
})
export class EnterprisesComponent implements OnInit {

  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  columnsHist: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosPost: any;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;
  /* *************** Otros ***************** */
  wait: boolean = false;

  constructor(
    public dialog: MatDialog,  route: Router,
    public snackBar: MatSnackBar,
    public userService: UserService,
    public dataService: EnterpriseService
  ) {
    if (!userService.getUserLogIn()) route.navigate(['./login']);
    this.datosPost = [];

    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: 'Listado de Empresas' };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: false }; // parametros de para seleccion de columnas

    this.scroll = { mode: 'standard' }; // Parametros de Scroll
    this.columns = [ // Columnas
      {
        caption: 'Id',
        dataField: 'companyId',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre',
        dataField: 'name',
        hiddingPriority: '0'
      },
      {
        caption: 'Por defecto',
        dataField: 'isDefaultStr',
        hiddingPriority: '0'
      },
      {
        caption: 'Estatus',
        dataField: 'status',
        hiddingPriority: '0'
      }
    ];

    this.toolbar = [
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Agregar',
          onClick: this.receiveMessageDoc.bind(this, 'add')
        },
        visible: true
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 110,
          text: 'Acutaliza',
          onClick: this.receiveMessageDoc.bind(this, 'upd')
        },
        visible: true
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Elimina',
          onClick: this.receiveMessageDoc.bind(this, 'del')
        },
        visible: false,
        name: 'simple'
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Edita',
          onClick: this.receiveMessageDoc.bind(this, 'edt')
        },
        visible: false,
        name: 'simple'
      }
    ];
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.wait = true;
    this.dataService.getEnterpriseAll()
      .subscribe((res) => {
        this.wait = false;
        this.datosPost = res.map((item: any) => {
          item.isDefaultStr = (item.isDefault == 1 ? 'Si': 'No');
          return item;
        });
      })
  }

  add() {
    const dialogRef = this.dialog.open(AddEnterprisesComponent, {});
    dialogRef.afterClosed()
      .subscribe(resDialog => {
        if (resDialog) {
          this.dataService.CreateEnterprise(resDialog)
            .subscribe((res) => {
              this.loadData();
              this.snackBar.open(`Se ha dado de alta correctamente`, 'ok', { duration: 2500 });
            }, (err) => {
              this.wait = false;
              let msj = err.message;
              try {
                msj = err.error.error.message + '\n' + err.error.text;
              } catch { }
              this.snackBar.open(msj, 'close');
            });
        }
      });
  }

  upd(datos) {
    const dialogRef = this.dialog.open(UpdEnterprisesComponent, {
      data: datos
    });
    dialogRef.afterClosed()
      .subscribe(resDialog => {
        if (resDialog) {
          this.dataService.UpdateEnterprise(resDialog.companyId,resDialog)
            .subscribe((res) => {
              this.snackBar.open(`Se ha dado de actualizado correctamente`, 'ok', { duration: 2500 });
              this.loadData();
            }, (err) => {
              this.wait = false;
              let msj = err.message;
              try {
                msj = err.error.error.message + '\n' + err.error.text;
              } catch { }
              this.snackBar.open(msj, 'close');
            });
        }
      });
  }

  del(datos) {
    const dialogRef = this.dialog.open(DelEnterprisesComponent, {
      data: datos
    });
    dialogRef.afterClosed()
      .subscribe(resDialog => {
        if (resDialog) {
          this.dataService.DeleteEnterprise(resDialog.companyId)
          .subscribe((res) => {
            this.snackBar.open(`Se ha dado de eliminado correctamente`, 'ok', { duration: 2500 });
            this.loadData();
          },(err) => {
            this.wait = false;
            let msj = err.message;
            try {
              msj = err.error.error.message + '\n' + err.error.text;
            } catch { }
            this.snackBar.open(msj, 'close');
          });
        }
      });
  }

  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case 'add':
          this.add();
          break;
        case 'del':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.del(d)
          }
          break;
        case 'edt':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.upd(d)
          }
          break;
        case 'upd':
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }
}

@Component({
  selector: 'app-add-enterprises',
  templateUrl: './add.component.html',
})
export class AddEnterprisesComponent {
  data: any;
  nombre = 'Agrega '
  constructor(public dialogRef: MatDialogRef<AddEnterprisesComponent>) {
    this.data = {}
    this.data["idCompany"] = 0;
    this.data["isDefault"] = false;
    this.data["status"] = 'ACTIVE';
  }
  formControl = new FormControl('', [
    Validators.required
  ]);
  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-upd-enterprises',
  templateUrl: './add.component.html',
})
export class UpdEnterprisesComponent {
  nombre = 'Edita ';
  constructor(
    public dialogRef: MatDialogRef<UpdEnterprisesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.data["status"] = 'ACTIVE';
  }
  formControl = new FormControl('', [
    Validators.required
  ]);
  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'app-del-enterprises',
  templateUrl: './del.component.html',
})
export class DelEnterprisesComponent {
  constructor(
    public dialogRef: MatDialogRef<DelEnterprisesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.data["status"] = 0;
  }
  formControl = new FormControl('', [
    Validators.required
  ]);
  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}