import { MatSnackBar } from "@angular/material";
import { Injectable } from "@angular/core";

@Injectable()
export class TimerNoti{
  timeoutId: any;
  refreshId: any;

  constructor(
    private snackBar: MatSnackBar
  ){

  }

  startTimer = ()=>{
    this.timeoutId = setTimeout(() => {
      console.log("endtimer");
      this.snackBar.open("El servicio se esta tardando mas de lo esperado. En un momento se recargara la pagina, favor de validar.");
      this.refreshPage();
    }, 30000);
  }

  refreshPage = ()=>{
    this.refreshId = setTimeout(() => {
      console.log("refreshing");
      window.location.reload();
    }, 10000);
  }
  clearTimer = ()=>{
    if (this.timeoutId) {
      console.log("cleartimer");
      clearTimeout(this.timeoutId);
    }
    if (this.refreshId) {
      console.log("clearrefresh");
      clearTimeout(this.refreshId);
    }
  }  

}

