import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';
@Injectable({
  providedIn: 'root'
})
export class ListasControlConsultaHits {
  private environmentUrl: any = {};
  httpOptions: any;

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
    this.environmentUrl = this.localStorage.getObject(KEYS_STORAGE.url) || {};

    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', })
    }

  }

  public getAllCargasMasivas = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/getCargas`;
    return this.http.get(url, this.httpOptions);
  }

  public getLogsCargasMasivas = (id: any): string => {
    return `${this.environmentUrl.listasControlUrl}/consultaHits/getErroresCSV/${id}`;
  }

  public getAllNameLC = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/nombreLC`;
    return this.http.get(url, this.httpOptions);
  }

  public getHits = (tabla: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/?tabla=${tabla}`;
    return this.http.get(url, this.httpOptions);
  }

  public getAllNivelRiesgo = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/config_nivel_riesgo/`;
    return this.http.get(url, this.httpOptions);
  }

  public postUpdNivelRiesgo = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/update_nivelRiesgo`;
    return this.http.put(url, JSON.stringify(body), this.httpOptions);
  }

  public getActEconomica = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/actEconomica`;
    return this.http.get(url, this.httpOptions);
  }

  public getVolOperacion = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/volumenOp`;
    return this.http.get(url, this.httpOptions);
  }

  public getUbicacionGeo = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/ubicacionGeo`;
    return this.http.get(url, this.httpOptions);
  }

  public getOdRecursos = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/odRecursos`;
    return this.http.get(url, this.httpOptions);
  }

  public getProd_serv = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/prod_serv`;
    return this.http.get(url, this.httpOptions);
  }

  public getMotivos = (lista: any): Observable<any> => {
    let list = lista == 'lpldyf' ? 'lpldyft': lista;
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/motivos/${list.toUpperCase()}`;
    return this.http.get(url, this.httpOptions);
  }

  public gettipo_persona = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/tipo_persona`;
    return this.http.get(url, this.httpOptions);
  }

  public getStatus = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/status`;
    return this.http.get(url, this.httpOptions);
  }

  public getAprobaciones = (id:any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/aprobaciones?id=${id}`;
    return this.http.get(url, this.httpOptions);
  }

  public putUpdAprobacionesInicial = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/update_aprobacionesI`;
    return this.http.put(url, JSON.stringify(body), this.httpOptions);
  }

  public putUpdAprobacionesSemestral = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/update_aprobacionesS`;
    return this.http.put(url, JSON.stringify(body), this.httpOptions);
  }

  public getLogBp = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/consultaHits/get_log_bp`;
    return this.http.post(url, JSON.stringify(body), this.httpOptions);
  }
}