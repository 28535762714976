import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';

@Injectable({
  providedIn: 'root'
})
export class ModulosService {
  private environmentUrl: any = {};
  httpOptions: any;

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
    this.environmentUrl = this.localStorage.getObject(KEYS_STORAGE.url) || {};
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', })
    }
  }

  public getAllModules = (): Observable<any> => {
    const url = `${this.environmentUrl.serviceTechnicalUrl}/modules`;
    return this.http.get(url, this.httpOptions);
  }

  public getModuleById = (systemId: any, processId: any): Observable<any> => {
    const url = `${this.environmentUrl.serviceTechnicalUrl}/module/${systemId}/${processId}`;
    return this.http.get(url, this.httpOptions);
  }

  public createModule = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.serviceTechnicalUrl}/module`;
    return this.http.post(url, JSON.stringify(body), this.httpOptions );
  }

  public updateModule = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.serviceTechnicalUrl}/module`;
    return this.http.put(url, JSON.stringify(body), this.httpOptions );
  }

  public deleteModule = (body: any): Observable<any> => {
    const { moduleId, processId, systemId, userNomina } = body;
    const url = `${this.environmentUrl.serviceTechnicalUrl}/module/${systemId}/${processId}/${moduleId}/${userNomina}`;
    return this.http.delete(url);
  }
}
