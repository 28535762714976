import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { CargaMasivaService } from '@shared/carga-masiva.service';
import { IColumns, IExportExcel, IScroll, Toolbar, IColumnHiding, ICheckbox, IEditing, IColumnchooser } from '@models/grid.interfaces'
import { ListasControlPEP } from '@shared/listas-control-pep.service';
import { ListasControlConsultaHits } from '@shared/listas-control-consulta-hits.service';

@Component({
  selector: 'app-listas-control-pep',
  templateUrl: './listas-control-pep.component.html',
  styleUrls: ['./listas-control-pep.component.scss']
})
export class ListasControlPEPComponent implements OnInit {
  wait: boolean = false;
  waitFind: boolean = false;
  waitFile: boolean = false;

  listaActEconomica: any;
  listaTipoPersona: any;
  listaProdServ: any;
  listaODRecursos: any;
  listaUbicacionGeo: any;
  listaVolOper: any;

  datosFind: any;
  datosTable: any;
  datoseventFind: any;

  nombresInput: string = '';
  idT: string = '';

  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,
    public cargaMasiva: CargaMasivaService,
    public dataService: ListasControlPEP,
    public consultaHitsService: ListasControlConsultaHits
  ) {
    if (!userService.getUserLogIn()) route.navigate(['./login']);
    this.datosTable = [];
    this.datosFind = [];
    this.nombresInput = '';
    this.idT = '';

    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: 'Listado de Información' };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: false }; // parametros de para seleccion de columnas

    this.scroll = { mode: 'standard' }; // Parametros de Scroll
    this.columns = [ // Columnas
      {
        caption: 'Id',
        dataField: 'id',
        hiddingPriority: '0'
      },
      {
        caption: 'Id_PDLyF',
        dataField: 'id_lpdlyf',
        hiddingPriority: '0'
      },
      {
        caption: 'Identificador',
        dataField: 'identificador',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre Completo',
        dataField: 'nombre_completo',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha de Nacimiento',
        dataField: 'fecha_nacimiento',
        hiddingPriority: '0'
      },
      {
        caption: 'CURP',
        dataField: 'curp',
        hiddingPriority: '0'
      },
      {
        caption: 'RFC',
        dataField: 'rfc',
        hiddingPriority: '0'
      },
      {
        caption: 'Ciudad',
        dataField: 'ciudad',
        hiddingPriority: '0'
      },
      {
        caption: 'Tipo',
        dataField: 'tipo',
        hiddingPriority: '0'
      },
      {
        caption: 'Tipo de Alerta',
        dataField: 'tipo_alerta',
        hiddingPriority: '0'
      },
      {
        caption: 'Alerta',
        dataField: 'alerta',
        hiddingPriority: '0'
      },
    ];

    this.toolbar = [
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Agregar',
          onClick: this.receiveMessageDoc.bind(this, 'add')
        },
        visible: true
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Editar',
          onClick: this.receiveMessageDoc.bind(this, 'edt')
        },
        visible: false,
        name: 'simple'
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Eliminar',
          onClick: this.receiveMessageDoc.bind(this, 'del')
        },
        visible: false,
        name: 'simple'
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 110,
          text: 'Actualizar',
          onClick: this.receiveMessageDoc.bind(this, 'loadD')
        },
        visible: true
      }

    ];
  }

  ngOnInit() {
    this.loadData();
  }

  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case 'add':
          this.add();
          break;
        case 'edt':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.upd(d)
          }
          break;
        case 'del':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.del(d)
          }
          break;
        case 'loadD':
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  loadData() {
    this.wait = true;
    this.getAllFactoresRiesgo();
    this.dataService.getAll().toPromise().then((res) => {
      this.wait = false;
      this.datosTable = res;
      if (this.datosTable['Code'] == "404") {
        this.datosTable = null;
      }
      

    })
  }

  add() {
    let datos = [this.listaActEconomica, this.listaODRecursos, this.listaProdServ, this.listaTipoPersona, this.listaUbicacionGeo, this.listaVolOper];
    const dialogRef = this.dialog.open(AddListPEPComponent, { data: datos });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        resDialog['nombre_completo'] = resDialog['nombre'] + " " + resDialog['apellido_paterno'] + " " + resDialog['apellido_materno'];
        this.dataService.post(resDialog).toPromise().then((res) => {
          this.loadData();
          this.snackBar.open(`Se ha dado de alta correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch { }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  getAllFactoresRiesgo() {
    this.listaTipoPersona = null;
    this.consultaHitsService.gettipo_persona().toPromise().then((data: any) => {
      this.listaTipoPersona = data;
    }).catch((err) => {
      this.listaTipoPersona = null;
    });

    this.listaProdServ = null;
    this.consultaHitsService.getProd_serv().toPromise().then((data: any) => {
      this.listaProdServ = data;
    }).catch((err) => {
      this.listaProdServ = null;
    });

    this.listaODRecursos = null;
    this.consultaHitsService.getOdRecursos().toPromise().then((data: any) => {
      this.listaODRecursos = data;
    }).catch((err) => {
      this.listaODRecursos = null;
    });

    this.listaUbicacionGeo = null;
    this.consultaHitsService.getUbicacionGeo().toPromise().then((data: any) => {
      this.listaUbicacionGeo = data;
    }).catch((err) => {
      this.listaUbicacionGeo = null;
    });

    this.listaVolOper = null;
    this.consultaHitsService.getVolOperacion().toPromise().then((data: any) => {
      this.listaVolOper = data;
    }).catch((err) => {
      this.listaVolOper = null;
    });

    this.listaActEconomica = null;
    this.consultaHitsService.getActEconomica().toPromise().then((data: any) => {
      this.listaActEconomica = data;
    }).catch((err) => {
      this.listaActEconomica = null;
    });

  }

  upd(datos) {
    datos['catalog'] = [this.listaActEconomica, this.listaODRecursos, this.listaProdServ, this.listaTipoPersona, this.listaUbicacionGeo, this.listaVolOper];
    const dialogRef = this.dialog.open(UpdListPEPComponent, { data: datos });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        resDialog['nombre_completo'] = resDialog['nombre'] + " " + resDialog['apellido_paterno'] + " " + resDialog['apellido_materno'];
        this.dataService.put(resDialog).toPromise().then((res) => {
          this.loadData();
          this.snackBar.open(`Se ha actualizado correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch { }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  del(datos) {
    const dialogRef = this.dialog.open(DelListPEPComponent, { data: datos });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.dataService.delete(resDialog).toPromise().then((res) => {
          datos["status"] = 0;
          this.loadData();
          this.snackBar.open(`Se ha dado de eliminado correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch { }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  onFileSelected(file: any) {
    //this.snackBar.open(`Se ha cargado correctamente el archivo`, 'ok', { duration: 6000 });
    this.waitFile = true;
    const inputNode: any = document.querySelector('#file');
    this.cargaMasiva.uploadListasControlPep(inputNode.files[0]).subscribe(
      (data) => {
        this.snackBar.open(`Se ha cargado correctamente el archivo`, 'ok', { duration: 9000 });
        this.waitFile = false;
        this.loadData();
      },
      (error) => {
        this.snackBar.open('Ocurrió un error al cargar el archivo', 'ok', { duration: 3000 });
        this.waitFile = false;
        this.loadData();
      }
    );
  }
}


interface Status {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-add-listas-control-pep',
  templateUrl: './add.component.html',
})
export class AddListPEPComponent {
  data: any;
  nombre = 'Agrega ';
  actEconomica: any;
  odRecursos: any;
  prodServ: any;
  tipoPersona: any;
  ubicacionGeo: any;
  volOper: any;

  status: Status[] = [
    { value: '0', viewValue: 'Desactivado' },
    { value: '1', viewValue: 'Activado' }
  ];

  constructor(public dialogRef: MatDialogRef<AddListPEPComponent>, @Inject(MAT_DIALOG_DATA) public data2: any) {
    this.actEconomica = data2[0];
    this.odRecursos = data2[1];
    this.prodServ = data2[2];
    this.tipoPersona = data2[3];
    this.ubicacionGeo = data2[4];
    this.volOper = data2[5];
    this.data = {}
    this.data.nombre_completo = "";
    this.data.id_lpldyf = "";
    this.data.tipo = "";
    this.data.tipo_alerta = "";
    this.data.alerta = "";
  }

  formControl = new FormControl('', [Validators.required]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-upd-listas-control-pep',
  templateUrl: './add.component.html',
})
export class UpdListPEPComponent {

  tipoPersona: any;
  actEconomica: any;
  ubicacionGeo: any;
  prodServ: any;
  odRecursos: any;
  volOper: any;

  status: Status[] = [
    { value: '0', viewValue: 'Desactivado' },
    { value: '1', viewValue: 'Activado' }
  ];

  nombre = 'Edita '
  sta: Status[] = [
    { value: '0', viewValue: 'Desactivado' },
    { value: '1', viewValue: 'Activado' }
  ];

  constructor(public dialogRef: MatDialogRef<UpdListPEPComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.actEconomica = data['catalog'][0];
    this.odRecursos = data['catalog'][1];
    this.prodServ = data['catalog'][2];
    this.tipoPersona = data['catalog'][3];
    this.ubicacionGeo = data['catalog'][4];
    this.volOper = data['catalog'][5];
    if (data.status == 1) {
      data.status = this.sta[1].value

    }
    else {
      data.status = this.sta[0].value
    }
  }

  formControl = new FormControl('', [Validators.required
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-del-listas-control-pep',
  templateUrl: './del.component.html',
})
export class DelListPEPComponent {
  nombre = 'Elimina ';
  constructor(public dialogRef: MatDialogRef<DelListPEPComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  formControl = new FormControl('', [Validators.required
  ]);


  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}