import { Component, OnInit } from '@angular/core';
import {faSignOutAlt, faCircle, faBars, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Router} from "@angular/router";
import {UserService} from "@shared/user.service";
import {LoginService} from "@shared/login.service";
import {AService} from "../../../services/auth.service";
import {AuthconfigService} from "../../../services/authconfig.service";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(
    private router: Router,
    public userService: UserService,
    private loginService: LoginService,
    private authService: AService,
    private authConfigService: AuthconfigService,
    private route: Router,
  ) {
    this.checkCurrentUser();

  }

  isSidebarOpen = false;

  // ICONS
  faSignOut = faSignOutAlt;
  faCircle = faCircle;
  faMenu = faBars;
  faTimes = faTimes;

  ngOnInit() {
  }

  onClickMenu(): void {
    this.router.navigate(['/']);
  }

  onCLickLogout(): void {
    this.userService.setUserLogOut();
    this.authConfigService.deleteConfiguration();
    this.authService.deleteConfiguration();
    this.router.navigate(['./login'], {replaceUrl: true});
  }

  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
  }

  checkCurrentUser() {
    if (!this.authConfigService.authUserExists) {
      this.route.navigate(['/login'], {
        replaceUrl: true
      });
    }
  }

}
