import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
// SERVICES
import { UserService } from "@shared/user.service";
import { AdminListas } from "@shared/admin-listas.service";
import { MatSnackBar } from "@angular/material";
import { ListasControlAutReg } from "@shared/listas-control-autorizacion_reg.service";
import { TimerNoti } from "src/app/core/utils/timer";

@Component({
  selector: "app-admin-list-status",
  templateUrl: "./admin-list-status.component.html",
  styleUrls: ["./admin-list-status.component.scss"],
})
export class AdminListStatusComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  form!: FormGroup;
  statuses: any[] = [];
  item: any;
  loading = false;
  

  constructor(
    public dialogRef: MatDialogRef<AdminListStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private adminListasService: AdminListas,
    public listasControlAutRegService: ListasControlAutReg,
    private snackBar: MatSnackBar,
    private timer: TimerNoti
  ) {
    this.item = this.data.data;
    this.statuses = this.data.status;
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get isAuthorized() {
    return this.item.idAutorizacionInicial !== null;
  }

  get showActions() {
    return !(
      this.item.idAutorizacionInicial == 0 ||
      this.item.idAutorizacionInicial == 1 ||
      this.item.IdEstatusInicial == 0 ||
      this.item.IdEstatusInicial == 1 ||
      this.item.tabla == "Historica"
    );
  }

  close() {
    this.dialogRef.close({ success: false });
  }

  createForm() {
    this.form = new FormGroup({
      statusId: new FormControl(null, [Validators.required]),
    });
  }

  startTransaction($event: Event) {
    $event.preventDefault();
    if (this.form.valid) {
      const data = this.createPayload();
      // this.item.sistemaId === 'GD_LC' ? this.updateDataGD(data) : this.save(data);
      this.item.sistemaId === 'GD_LC' ? this.updateDataGD(data) : this.updateData2(data);
    } else {
      this.snackBar.open(`Selecciona un estatus`, "error", {duration: 2500});
    }
  }

  createPayload() {
    const statusId = Number(this.form.value.statusId);
    const { status } = this.statuses.find((x) => x.idStatus === statusId);
    return {
      ...this.item,
      EstatusAutorizacionInicial: status,
      user: this.userService.user.nombre,
      idAutIniNuevo: Number(this.form.value.statusId),
    };
  }

  save(data: any) {
    this.loading = true;
    this.timer.startTimer();
    console.log("initTimer")
      this.subscription.add(
        this.adminListasService.putUpdAprobacionesInicial(data)
        .subscribe({
          next: (res) => {
            this.loading = false;
            this.timer.clearTimer();
            this.snackBar.open(`Se ha autorizado correctamente`, "ok", {duration: 2500});
            if (res.Status[0][0] == 0) {
              this.updateData(data);
            }else{
              this.snackBar.open("Sucedio un error en el servicio", "close");
            }
          },
          error: (err: HttpErrorResponse) => {
            this.loading = false;
            this.timer.clearTimer();
            console.error(err);
            this.snackBar.open("Sucedio un error en el servicio", "close");
          },
        })
      );
  }
  save2(data: any) {
    this.loading = true;
    this.timer.startTimer();
    console.log("initTimer")
      this.subscription.add(
        this.adminListasService.putUpdAprobacionesInicial(data)
        .subscribe({
          next: (res) => {
            this.loading = false;
            this.timer.clearTimer();
            this.snackBar.open(`Se ha autorizado correctamente`, "ok", {duration: 2500});
            if (res.Status[0][0] == 0) {
              this.snackBar.open("Se ha autorizado correctamente", "ok");
              this.dialogRef.close({ success: true });
            }else{
              this.snackBar.open("Sucedio un error en el servicio", "close");
            }
          },
          error: (err: HttpErrorResponse) => {
            this.loading = false;
            this.timer.clearTimer();
            console.error(err);
            this.snackBar.open("Sucedio un error en el servicio", "close");
          },
        })
      );
  }

  updateData(data: any) {
    this.loading = true;
    this.timer.startTimer();
    console.log("initTimer")
    const payload = {
      id: String(data.id),
      RiskGradeRequest: 'RGI',
      RiskGradeResponse: String(data.nivelRiesgo),
      Approver: data.user,
      Resolution: String(data.idAutIniNuevo),
    };

    this.subscription.add(
      this.listasControlAutRegService.updateList(payload)
      .subscribe({
        next: (response: any) => {
          this.loading = false;
          this.timer.clearTimer();
          if (response.error) {
            this.snackBar.open("Sucedio un error en el servicio", "error");
          } else {
            this.snackBar.open("Se ha autorizado correctamente", "ok");
            this.dialogRef.close({ success: true });
          }
        },
        error: () => {
          this.loading = false;
          this.timer.clearTimer();
          this.snackBar.open("Sucedio un error en el servicio", "close");
        },
      })
    );
  }
  updateData2(data: any) {
    this.loading = true;
    this.timer.startTimer();
    console.log("initTimer")
    const payload = {
      id: String(data.id),
      RiskGradeRequest: 'RGI',
      RiskGradeResponse: String(data.nivelRiesgo),
      Approver: data.user,
      Resolution: String(data.idAutIniNuevo),
    };

    this.subscription.add(
      this.listasControlAutRegService.updateList(payload)
      .subscribe({
        next: (response: any) => {
          this.loading = false;
          this.timer.clearTimer();
          if (response.error) {
            this.snackBar.open("Sucedio un error en el servicio", "error");
          } else {
            this.dialogRef.close({ success: true });
            this.snackBar.open("Se ha autorizado correctamente", "ok");
            //this.save2(data)
          }
        },
        error: () => {
          this.loading = false;
          this.timer.clearTimer();
          this.snackBar.open("Sucedio un error en el servicio", "close");
        },
      })
    );
  }

  saveGD(data: any) {
    this.loading = true;
    this.timer.startTimer();
    console.log("initTimer")
    this.subscription.add(
      this.adminListasService.putUpdAprobacionesInicial(data)
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.timer.clearTimer();
          this.snackBar.open(`Se ha autorizado correctamente`, "ok", {duration: 2500});
          this.dialogRef.close({ success: true });
        },
        error: (err: HttpErrorResponse) => {
          this.loading = false;
          this.timer.clearTimer();
          console.error(err);
          this.snackBar.open("Sucedio un error en el servicio", "close");
        },
      })
    );
  }

  updateDataGD(data: any) {
    this.loading = true;
    this.timer.startTimer();
    console.log("initTimer")
    const payload = {
      id: String(data.id),
      RiskGradeRequest: 'RGI',
      RiskGradeResponse: String(data.nivelRiesgo),
      Approver: data.user,
      Resolution: String(data.idAutIniNuevo),
    };

    this.subscription.add(
      this.listasControlAutRegService.updateListGD(payload).subscribe({
        next: (response: any) => {
          this.loading = false;
          this.timer.clearTimer();
          if (response.error) {
            this.snackBar.open("Sucedio un error en el servicio", "error");
          } else {
            this.snackBar.open("Se ha autorizado correctamente", "ok");
            this.saveGD(data);
          }
        },
        error: () => {
          this.loading = false;
          this.timer.clearTimer();
          this.snackBar.open("Sucedio un error en el servicio", "close");
        },
      })
    );
  }
}