import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CargaMasivaService } from '@shared/carga-masiva.service';
import { IColumns, IExportExcel, IScroll, Toolbar, IColumnHiding, ICheckbox, IEditing, IColumnchooser, TiposdeDato, TiposdeFormato } from '@models/grid.interfaces'
import { ListasControlConsultaHits } from '@shared/listas-control-consulta-hits.service';

@Component({
  selector: 'app-log-list',
  templateUrl: './log-list.component.html',
  styleUrls: ['./log-list.component.scss']
})
export class LogListComponent implements OnInit {
  wait: boolean = false;
  waitFind: boolean = false;
  waitFile: boolean = false;

  listaActEconomica: any;
  listaTipoPersona: any;
  listaProdServ: any;
  listaODRecursos: any;
  listaUbicacionGeo: any;
  listaVolOper: any;

  datosFind: any;
  datosTable: any;
  datoseventFind: any;

  nombresInput: string = '';
  idT: string = '';

  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,
    public cargaMasiva: CargaMasivaService,
    public consultaHitsService: ListasControlConsultaHits
  ) {
    if (!userService.getUserLogIn()) route.navigate(['./login']);
    this.datosTable = [];
    this.datosFind = [];
    this.nombresInput = '';
    this.idT = '';

    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: 'Listado de Información' };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: false }; // parametros de para seleccion de columnas

    this.scroll = { mode: 'standard' }; // Parametros de Scroll
    this.columns = [ // Columnas
      {
        caption: 'Id carga',
        dataField: 'id',
        hiddingPriority: '0'
      },
      {
        caption: 'Lista',
        dataField: 'lista',
        hiddingPriority: '0'
      },
      {
        caption: 'Errores',
        dataField: 'errores',
        hiddingPriority: '0'
      },
      {
        caption: 'Creado',
        dataField: 'created_at',
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: '0'
      },
    ];

    this.toolbar = [
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 170,
          text: 'Descargar Errores',
          onClick: this.downloadErrors.bind(this)
        },
        visible: false,
        name: 'simple'
      },
    ];
  }

  ngOnInit() {
    this.loadData();
  }

  downloadErrors(){
    if (this.datosevent && this.datosevent.length > 0) {
      let d = this.datosevent[0];
      if(d.errores !== 0){
        window.open(this.consultaHitsService.getLogsCargasMasivas(d.id), "_blank");
      }else{
        this.snackBar.open(`No hay errores en la carga seleccionada`, 'ok', { duration: 2500 });
      }
    }
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  loadData() {
    this.wait = true;
    this.consultaHitsService.getAllCargasMasivas().toPromise().then((res) => {
      this.wait = false;
      this.datosTable = res;
    })
  }

}
