import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { IColumns, IExportExcel, IScroll, Toolbar, IColumnHiding, ICheckbox, IEditing, IColumnchooser, TiposdeDato, TiposdeFormato } from '@models/grid.interfaces'
import { MotivoIngreso } from '@shared/motivo-ingreso.service';

@Component({
  selector: 'app-motivos-ingreso',
  templateUrl: './motivos-ingreso.component.html',
  styleUrls: ['./motivos-ingreso.component.scss']
})
export class MotivosIngresoComponent implements OnInit {
  wait: boolean = false;
  waitFind: boolean = false;
  waitFile: boolean = false;

  listaActEconomica: any;
  listaTipoPersona: any;
  listaProdServ: any;
  listaODRecursos: any;
  listaUbicacionGeo: any;
  listaVolOper: any;

  datosFind: any;
  datosTable: any;
  datoseventFind: any;

  nombresInput: string = '';
  idT: string = '';

  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;


  constructor(
      public dialog: MatDialog,
      private route: Router,
      public snackBar: MatSnackBar,
      public _route: ActivatedRoute,
      public userService: UserService,
      public dataService: MotivoIngreso,
    ) {
      if (!userService.getUserLogIn()) route.navigate(['./login']);
      this.datosTable = [];
      this.datosFind = [];
      this.nombresInput = '';
      this.idT = '';
  
      /* *************** Parametros del Grid de datos ***************** */
      this.exportExcel = { enabled: true, fileName: 'Listado de Información' };
      this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
      this.Checkbox = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
      this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
      this.Columnchooser = { columnchooser: false }; // parametros de para seleccion de columnas
  
      this.scroll = { mode: 'standard' }; // Parametros de Scroll
      this.columns = [ // Columnas
        {
          caption: 'Id',
          dataField: 'id',
          hiddingPriority: '0'
        },
        {
          caption: 'Lista',
          dataField: 'lista',
          hiddingPriority: '0'
        },
        {
          caption: 'Descripcion',
          dataField: 'descripcion',
          hiddingPriority: '0'
        },
        {
          caption: 'Fecha creado',
          dataField: 'created_at',
          dataType: TiposdeDato.datetime,
          format: TiposdeFormato.dmyt,
          hiddingPriority: '0'
        },
        {
          caption: 'Fecha actualizado',
          dataField: 'updated_at',
          dataType: TiposdeDato.datetime,
          format: TiposdeFormato.dmyt,
          hiddingPriority: '0'
        },
        {
          caption: 'Agregado por',
          dataField: 'created_for',
          hiddingPriority: '0'
        },
        {
          caption: 'Modificado por',
          dataField: 'updated_for',
          hiddingPriority: '0'
        },
    ];
  
      this.toolbar = [
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 90,
            text: 'Agregar',
            onClick: this.receiveMessageDoc.bind(this, 'add')
          },
          visible: true
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 90,
            text: 'Editar',
            onClick: this.receiveMessageDoc.bind(this, 'edt')
          },
          visible: false,
          name: 'simple'
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 90,
            text: 'Eliminar',
            onClick: this.receiveMessageDoc.bind(this, 'del')
          },
          visible: false,
          name: 'simple'
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            width: 110,
            text: 'Actualizar',
            onClick: this.receiveMessageDoc.bind(this, 'loadD')
          },
          visible: true
        }
      ];  
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.wait = true;
    this.dataService.getAll().toPromise().then((res) => {
      this.wait = false;
      if (this.datosTable['Code'] == "404") {
        this.datosTable = null;
      }
      this.datosTable = res.Datos;
    })
  }

  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case 'add':
          this.add();
          break;
        case 'edt':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.upd(d)
          }
          break;
        case 'del':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.del(d)
          }
          break;
        case 'loadD':
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  add() {
    const dialogRef = this.dialog.open(AddMotivoIngresoComponent, {});
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.dataService.post(resDialog).toPromise().then((res) => {
          this.loadData();
          this.snackBar.open(`Se ha dado de alta correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch { }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  upd(datos) {
    const dialogRef = this.dialog.open(UpdMotivoIngresoComponent, { data: datos });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.dataService.put(resDialog).toPromise().then((res) => {
          this.loadData();
          this.snackBar.open(`Se ha actualizado correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch { }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  del(datos) {
    const dialogRef = this.dialog.open(DelMotivoIngresoComponent, { data: datos });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.dataService.delete(resDialog).toPromise().then((res) => {
          this.loadData();
          this.snackBar.open(res['Status'][0], 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch { }
          this.snackBar.open("Error en el servicio", 'close');
        });
      }
    });
  }

}

@Component({
  selector: 'app-add-motivo-ingreso',
  templateUrl: './add.component.html',
})
export class AddMotivoIngresoComponent {
  data: any;
  nombre = 'Agrega ';
  actEconomica: any;
  odRecursos: any;
  prodServ: any;
  tipoPersona: any;
  ubicacionGeo: any;
  volOper: any;
  selected;

  constructor(public dialogRef: MatDialogRef<AddMotivoIngresoComponent>, @Inject(MAT_DIALOG_DATA) public data2: any) {
    this.data = {}
    this.data.lista = "";
    this.data.id_motivo = "";
    this.data.descripcion = "";
  }

  formControl = new FormControl('', [Validators.required]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-upd-motivo-ingreso',
  templateUrl: './add.component.html',
})
export class UpdMotivoIngresoComponent {

  data: any;
  selected;

  nombre = 'Edita '

  constructor(public dialogRef: MatDialogRef<UpdMotivoIngresoComponent>, @Inject(MAT_DIALOG_DATA) public data2: any) {
    this.data = data2;
  }

  formControl = new FormControl('', [Validators.required
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-del-motivo-ingreso',
  templateUrl: './del.component.html',
})
export class DelMotivoIngresoComponent {
  nombre = 'Elimina ';
  constructor(public dialogRef: MatDialogRef<DelMotivoIngresoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  formControl = new FormControl('', [Validators.required
  ]);


  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}