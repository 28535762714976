import {Component, OnInit} from '@angular/core';
import moment from 'moment';
import {UserService} from '@shared/user.service';
import {Router} from '@angular/router';
import {SignIn} from '../../interfaces/signIn';
import {faCalendar} from '@fortawesome/free-solid-svg-icons/faCalendar';
import routes from '../../../utils/constants/routes.json';
import { AService } from '@services/auth.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  user: SignIn;
  fechaHoy = moment().locale('es').format('L');
  // navItems = CONFIG.navigationItems;
  routes = routes;
  // public loginIcon: string = 'assets/images/logo.png';
  faCalendar = faCalendar;

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AService
  ) {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.userService.getUserLogIn();
  }

  ngOnInit() {
    console.log(this.userService.user.perfil);
  }

  goTo(route: string) {
    this.router.navigate(['/app/' + route]);
  }

  getMenuIcon(name: string): string {
    return '../../../assets/icons/menu/' + name + '.svg';
  }

  canDisplayEntry(entryKey: number): boolean {
    const permissions = this.authService.getPermissions();
    return permissions.includes(entryKey);
  }

  hasVisibleEntries(section: any): boolean {
    return section.items.some(item => this.canDisplayEntry(item.routeKey));
  }

}
