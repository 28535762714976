import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SecureStorage } from '../utils/secure-storage';
import { LocalStorageService } from '@services/local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';
@Injectable({
  providedIn: 'root'
})
export class CargaMasivaService {
  private environmentUrl: any = {};
  httpDocOptions: any

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
    this.environmentUrl = this.localStorageService.getObject(KEYS_STORAGE.url) || {};
    this.httpDocOptions = {
      headers: new HttpHeaders({'Content-Disposition': 'form-data; name=\"file\"'})
    };
  }

  public uploadDocument = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.sedesURL}/uploadFile`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  public uploadDocumentBalances = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.sedesURL}/uploadFileBalance`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  public uploadDocumentPointsNomina = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.sedesURL}/uploadFilePointsNomina`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  public uploadDocumentPuntosPoligonos = (document: any): Observable<any> =>{
    const url = `${this.environmentUrl.geolocationUrl}/uploadFilePuntosPoligonos`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  //Upload Tipos
  public uploadDocumentTipos = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.geolocationUrl}/uploadFileTipos`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  //Upload Listas Control Black
  public uploadListasControlBlack = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFileListaNegra`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  //Upload Listas Control White
  public uploadListasControlWhite = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFileListaBlanca`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  //Upload Listas Control Bloq
  public uploadListasControlBloq = (document: any): Observable<any> =>{
    const url = `${this.environmentUrl.listasControlUrl}/uploadFileLBLOQ`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  //Upload Listas Control Bloq Yastas
  public uploadListasControlBloqYastas = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFileLBLOQ/yastas`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  //Upload Listas Control Gris
  public uploadListasControlGris = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFileLGRIS`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  //Upload Listas Control Gris
  public uploadListasControlGrisYastas = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFileLGRIS/yastas`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  //Upload Listas Control Ofac
  public uploadListasControlOfac = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFileLOFAC`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

   //Upload Listas Control PEP
  public uploadListasControlPep = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFileLPEP`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  //Upload Listas Control PLD y F
  public uploadListasControlPldyf = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFileLPLDYF`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  //Upload Listas Control Ofac
  public uploadListasControlOfacYastas = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFileLOFAC/yastas`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

   //Upload Listas Control PEP
  public uploadListasControlPepYastas = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFileLPEP/yastas`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  //Upload Listas Control PLD y F Yastas
  public uploadListasControlPldyfYastas = (document: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFileLPLDYF/yastas`;
    var fileUpload = document;
    let fd = new FormData;
    fd.set(`file`, fileUpload, fileUpload.name)
    return this.http.post(url, fd);
  }

  public uploadListasControlGeneral = (document: any, lista: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFile${lista.toUpperCase()}`;
    var fileUpload = document;
    let fd = new FormData;
    let cUser = JSON.parse(SecureStorage.decrypt(localStorage.getItem('currentUser')));
    fd.set(`file`, fileUpload, fileUpload.name)
    fd.set(`user`, cUser.nombre)
    return this.http.post(url, fd);
  }

  public uploadListasControlGeneralCompleta = (document: any, lista: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFile${lista.toUpperCase()}/completa`;
    var fileUpload = document;
    let fd = new FormData;
    let cUser = JSON.parse(SecureStorage.decrypt(localStorage.getItem('currentUser')));
    fd.set(`file`, fileUpload, fileUpload.name)
    fd.set(`user`, cUser.nombre)
    return this.http.post(url, fd);
  }

   //Upload Listas Control PEP
   public uploadListasControlGeneralYastas = (document: any, lista: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFile${lista.toUpperCase()}/yastas`;
    var fileUpload = document;
    let fd = new FormData;
    let cUser = JSON.parse(SecureStorage.decrypt(localStorage.getItem('currentUser')));
    fd.set(`file`, fileUpload, fileUpload.name)
    fd.set(`user`, cUser.nombre)
    return this.http.post(url, fd);
  }

  //Upload Listas Control PLD y F Yastas
  public uploadListasControlGeneralCompletaYastas = (document: any, lista: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/uploadFile${lista.toUpperCase()}/yastas/completa`;
    var fileUpload = document;
    let fd = new FormData;
    let cUser = JSON.parse(SecureStorage.decrypt(localStorage.getItem('currentUser')));
    fd.set(`file`, fileUpload,fileUpload.name)
    fd.set(`user`, cUser.nombre)
    return this.http.post(url, fd);
  }
}
