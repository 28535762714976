import { Component, OnInit ,Inject} from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { CargaMasivaService } from '@shared/carga-masiva.service';
import {IColumns, IExportExcel, IScroll, Toolbar, IColumnHiding, ICheckbox, IEditing, IColumnchooser} from '@models/grid.interfaces'
import { ListasControlAut } from '@shared/listas-control-autorizacion.service';

@Component({
  selector: 'app-listas-control-autorizacion',
  templateUrl: './listas-control-autorizacion.component.html',
  styleUrls: ['./listas-control-autorizacion.component.scss']
})
export class ListasControlAutorizacionComponent implements OnInit {

  
  wait: boolean = false;
  cargar: boolean = false;
  waitFind: boolean = false;
  waitFile: boolean = false;

  datosFind: any;
  datosTable: any;
  datoseventFind: any;

  nombresInput : string = '';
  idT: string = '' ;
  id_rol = '';

  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,  
    public cargaMasiva: CargaMasivaService,
    public dataService: ListasControlAut
    
  ) {
    if (!userService.getUserLogIn()) route.navigate(['./login']);
    this.datosTable = [];
    this.datosFind = [];
    this.nombresInput = '';
    this.idT = '' ;

    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: 'Listado de Información' };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: false }; // parametros de para seleccion de columnas

    this.scroll = { mode: 'standard' }; // Parametros de Scroll
    this.columns = [ // Columnas
      {
        caption: 'Id',
        dataField: 'id',
        hiddingPriority: '0'
      },
      {
        caption: 'Usuario',
        dataField: 'usuario',
        hiddingPriority: '0'
      },
      {
        caption: 'Coincidencia %',
        dataField: 'coincidencia',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre',
        dataField: 'nombre',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre Coincidencia',
        dataField: 'nombre_coincidencia',
        hiddingPriority: '0'
      },
      {
        caption: 'RFC',
        dataField: 'rfc',
        hiddingPriority: '0'
      },
      {
        caption: 'Tabla',
        dataField: 'tabla_consultada',
        hiddingPriority: '0'
      },
      {
        caption: 'Nivel Riesgo',
        dataField: 'nivel_riesgo',
        hiddingPriority: '0'
      },      
      {
        caption: 'Estatus',
        dataField: 'estatus',
        hiddingPriority: '0'
      },
    ];

    this.toolbar = [
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Autorizar',
          onClick: this.receiveMessageDoc.bind(this, 'edt')
        },
        visible: false,
        name: 'simple'
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 110,
          text: 'Actualizar',
          onClick: this.receiveMessageDoc.bind(this, 'loadD')
        },
        visible: true
      }
      
    ]   
  }

  ngOnInit() {
    this.wait = true;
    //this.loadData();
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch{ }
      this.snackBar.open(msj, 'close');
    }
  }
  
  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case 'edt':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.upd(d)
          }
          break;
        case 'loadD':
          this.BuscarRegistros();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch{ }
      this.snackBar.open(msj, 'close');
    }
  }

  loadData() {
    this.wait = true;
    this.dataService.getAll().toPromise().then((res) => {
      this.wait = false;    
      this.datosTable = res; 
    })
  }

  BuscarRegistros() {   
    this.wait = false;
    this.cargar = true;
    this.datosTable = false;
    this.dataService.getArgs(this.id_rol).toPromise().then((res) => {
      
      if(res[0]['resultado'] != 0){
        this.wait = false;
        for(var i = 0;i<res.length;i++) { 
          if(res[i]['estatus'] == 1) {
            res[i]['estatus'] = "Autorizado";
          }else{
            res[i]['estatus'] = "No Autorizado";
          }
        }
        this.datosTable = res;      
        
      }
      this.cargar = false;   
    })
  }

  upd(datos) {
    const dialogRef = this.dialog.open(UpdListAutComponent, { data: datos});
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        resDialog['nombre_completo'] = resDialog['nombre'] + " " + resDialog['apellido_paterno'] + " " + resDialog['apellido_materno'];
        this.dataService.put(resDialog).toPromise().then((res) => {
          this.BuscarRegistros();
          this.snackBar.open(`Se ha actualizado correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch{ }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }
}

interface Status {
  value: any;
  viewValue: string;
}

@Component({
  selector: 'app-upd-listas-control-bloq',
  templateUrl: './add.component.html',
})
export class UpdListAutComponent {

  estatus: Status[] = [
    {value: 0, viewValue: 'No Autorizado'},
    {value: 1, viewValue: 'Autorizado'}
  ];
  
  
  nombre = 'Edita '
  sta: Status[] = [
    {value: 0, viewValue: 'No Autorizado'},
    {value: 1, viewValue: 'Autorizado'}
  ];

  constructor(public dialogRef: MatDialogRef<UpdListAutComponent>, @Inject(MAT_DIALOG_DATA) public data: any) 
  { 
    if(data.estatus == "Autorizado"){
      data.estatus = 1

    }
    else{
      data.estatus = 0
    }
  }

  formControl = new FormControl('', [ Validators.required
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
