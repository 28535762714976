/* eslint-disable @angular-eslint/no-output-native */
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-toolbar-dialog',
	templateUrl: './toolbar-dialog.component.html',
	styleUrls: ['./toolbar-dialog.component.scss']
})
export class ToolbarDialogComponent {
	@Input() title = '';
	// tslint:disable-next-line:no-output-native
	// tslint:disable-next-line:no-output-rename
	@Output() close = new EventEmitter<boolean>();
	closeDialog() {
		this.close.emit();
	}
}
