import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SecureStorage } from '../utils/secure-storage';
import { LocalStorageService } from '@services/local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';

@Injectable({
  providedIn: 'root'
})
export class BitacoraConsultasDevService {
  private environmentUrl: any = {};
  httpOptions: any;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
    this.environmentUrl = this.localStorageService.getObject(KEYS_STORAGE.url) || {};

    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', })
    }
  }

  public getBitacoraConsultasDevByRiesgo = (riesgo: string): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/bitacora_consultas_dev/${riesgo}`;
    return this.http.get(url);
  }

  public getGradoRiesgoByParams = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/bitacora_consultas_dev/params`;
    return this.http.post(url, body, this.httpOptions);
  }

  public getGradoRiesgoByParamsRep(body: any): Observable<any> {
    const url = `${this.environmentUrl.listasControlUrl}/bitacora_consultas_rep/params`;
    return this.http.post(url, body, this.httpOptions);
  }

  public getGradoRiesgoByParamsAndNomina = (body: any): Observable<any> => {
    const { nominaPrefix, ...data } = body;
    const url = `${this.environmentUrl.listasControlUrl}/bitacora_consultas_dev_nomina/params`;
    let cUser = JSON.parse(SecureStorage.decrypt(localStorage.getItem('currentUser')));
    data['user'] = cUser.nombre;
    data['nomina'] = (nominaPrefix || '') + cUser.nomina;
  return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }

  public getBitacoraConsultasDevYastasByRiesgo = (riesgo: string): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/bitacora_consultas_dev_yastas/${riesgo}`;
    return this.http.get(url);
  }

  public getRegDevByRiesgo = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/bitacora_consultas_dev/reg`;
    return this.http.post(url, body, this.httpOptions);
  }
}