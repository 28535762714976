import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { CdkStepperNext } from '@angular/cdk/stepper';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  @ViewChild('ngcarousel', { static: true }) ngCarousel: NgbCarousel;
  @ViewChild('Gridlightbox', { static: true }) Gridlightbox: TemplateRef<any>;

  extension: any;
  tipodecarga: string;
  public doc = [];
  path: any;
  cargando: boolean;
  documento: any = {};
  viewer: boolean;
  btnSubmitLabel = 'Subir Archivo(s)';
  disabled: any = false;

  constructor(private httpClient: HttpClient, public dialog: MatDialog, public domSanitizer: DomSanitizer,
    private snackBar: MatSnackBar) {
    this.extension = "PDF,pdf";
    this.tipodecarga = "useForm";
    this.cargando = false;
    const element = document.querySelectorAll('#myCarousel');
    [].forEach.call(element, function (el) {
      el.carousel({
        interval: 5000
      });



    });

    this.doc.push({
      extencion: 'PDF',
      activo: '',
      descripcion: 'Descripción',
      fechaCreacion: '02/05/02',
      idAplicacion: '1',
      idDocumento: '1',
      idModulo: '1',
      idUsuario: '1',
      nombre: 'El principito',
      nombreOriginal: 'El principito',
      path: 'https://vinculacion.gentera.com.mx:9088/ApiIncentivos/api/pdf/00378_Promotor%20CM_feb2020_IncrementoNetoClientes.pdf',
      size: '1',
      tipo: '1',
      titulo: 'Titulo',
      ultimaActualizacion: '02/05/02'
    });


  }

  ngOnInit() {
  }

  onValueChanged(e, fileUploader: DxFileUploaderComponent) {
    this.cargando = true;
    let d = e.value[0];
    // TOFIX: Se quitó esta funcionalidad porque no se estaba usando y daba problema M con Checkmarx
   /*
    if (d) {
      const reader = new FileReader();

      reader.onload = (_event) => {
        this.doc.push({
          extencion: 'pdf',
          descripcion: d.name,
          fechaCreacion: Date.now(),
          nombre: d.name,
          nombreOriginal: d.name,
          path: reader.result,
          titulo: d.name,
          ultimaActualizacion: Date.now()
        });
        reader.readAsDataURL(d);
        setTimeout(() => {
          this.cargando = false;
          this.ngCarousel.select(d.name);
          this.snackBar.open('Archivo cargado', 'Ok');
        }, 1000);

      };
    }
    */
  }

  // Move to specific slide
  navigateToSlide(item) {
    this.ngCarousel.select(item);

  }

  // Move to previous slide
  getToPrev() {
    this.ngCarousel.prev();
  }

  // Move to next slide
  goToNext() {
    this.ngCarousel.next();
  }

  open(Gridlightbox, path) {
    this.path = path;
    this.dialog.open(Gridlightbox)
  }
}
