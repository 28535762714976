import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import {Router} from '@angular/router';
import {UserService} from '@shared/user.service';
import {SignIn} from '../../../interfaces/signIn';
import routes from 'src/utils/constants/routes.json';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { AService } from '@services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('collapsed', style({ height: '0', visibility: 'hidden', overflow: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible', overflow: 'hidden' })),
      transition('collapsed => expanded', [
        animate('0.3s ease')
      ]),
      transition('expanded => collapsed', [
        animate('0.3s ease')
      ])
    ])
  ]
})
export class SidebarComponent implements OnInit {

  @Input() isOpen = false;
  user: SignIn;
  routes = routes;
  faChevron = faChevronDown;
  activeSection: string | null = null;

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AService
  ) {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.userService.getUserLogIn();
  }

  ngOnInit() {
  }

  goTo(route: string) {
    this.router.navigate(['/app/' + route]);
  }


  getMenuIcon(name: string): string {
    return '../../../assets/icons/white_menu/' + name + '.svg';
  }

  canDisplayEntry(entryKey: number): boolean {
    const permissions = this.authService.getPermissions();
    return permissions.includes(entryKey);
  }

  hasVisibleEntries(section: any): boolean {
    return section.items.some(item => this.canDisplayEntry(item.routeKey));
  }

  handleActiveSection(section: string): void {
    if (this.activeSection === section) {
      this.activeSection = null; // Cerrar la sección si ya está abierta
    } else {
      this.activeSection = section; // Abrir la sección si no está abierta
    }
  }
}
