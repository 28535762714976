import { Component, OnInit } from '@angular/core';
import {ConsoleFilter} from '../../../shared/models/console-filters';

@Component({
  selector: 'app-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.scss']
})
export class CrudComponent implements OnInit {

  title: string;
  icon: string;

  public filters: ConsoleFilter;
  public filterOptions

  constructor() { }

  ngOnInit() {
    this.createFilters();
  }

  createFilters() {
    this.filters = {
      searchFunction: this.searchWithFilter,
      filters: [
      ]
    };
  }

  searchWithFilter = (urlSearch: string) => {
   // this.getItems(urlSearch);
  }




}
