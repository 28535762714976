import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';

@Injectable({
  providedIn: 'root'
})
export class ProcesosService {
  private environmentUrl: any = {};
  httpOptions: any;

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
    this.environmentUrl = this.localStorage.getObject(KEYS_STORAGE.url) || {};
    this.httpOptions = {
      headers: new HttpHeaders({  'Content-Type': 'application/json' })
    }
   }

  public getProcesos = (): Observable<any> => {
    const url = `${this.environmentUrl.serviceTechnicalUrl}/allprocess`;
    return this.http.get(url, this.httpOptions);
  }

  public getProcessById = (systemId: any): Observable<any> => {
    const url = `${this.environmentUrl.serviceTechnicalUrl}/process/${systemId}`;
    return this.http.get(url, this.httpOptions)
  }

  public createProcess = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.serviceTechnicalUrl}/process`;
    return this.http.post(url, JSON.stringify(body), this.httpOptions);
  }

  public updateProcess = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.serviceTechnicalUrl}/process`;
    return this.http.put(url, JSON.stringify(body), this.httpOptions);
  }

  public deleteProcess = (body: any):  Observable<any> =>  {
    const { systemId, processId, userNomina } = body;
    const url = `${this.environmentUrl.serviceTechnicalUrl}/process/${systemId}/${processId}/${userNomina}`;
    return this.http.delete(url);
  }
}
