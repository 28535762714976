import {Component, OnInit} from '@angular/core';
import {ICheckbox, IColumnchooser, IColumnHiding, IColumns, IEditing, IExportExcel, IScroll, TiposdeDato, TiposdeFormato, Toolbar} from '@models/grid.interfaces';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ConsultaHitGeolocalizacionService} from '../../shared/services/consulta-hit-geolocalizacion.service';
import {environment} from '../../../environments/environment';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { LocalStorageService } from '@services/local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';
import { UserService } from "@shared/user.service";
import { Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { UnlockGeoComponent } from "./unlock-geo/unlock-geo.component";
import { ListasControlConsultaHits } from '@shared/listas-control-consulta-hits.service';

@Component({
  selector: 'app-consulta-hit-geolocalizacion',
  templateUrl: './consulta-hit-geolocalizacion.component.html',
  styleUrls: ['./consulta-hit-geolocalizacion.component.scss']
})
export class ConsultaHitGeolocalizacionComponent implements OnInit {
  subscription = new Subscription();
  public formGroup: FormGroup;
  loaded = 0;
  hits: any;
  datosStatus: any;
  usuario: any;
  encarga = false;
  datosevent: any;
  datosTableCambio: any;
  columnasTabla: IColumns[];
  datosPorActualizar = [];
  maxDate = new Date();
  toolbar: Toolbar[];
  exportExcelCambio: IExportExcel;
  columnHidingCambio: IColumnHiding;
  CheckboxCambio: ICheckbox;
  EditingCambio: IEditing;
  ColumnchooserCambio: IColumnchooser;
  scrollCambio: IScroll;
  // tslint:disable-next-line:ban-types
  onExportingFun: Function;
  // tslint:disable-next-line:ban-types
  onExportedFun: Function;
  onRowUpdated: any;
  editevent: any;
  cargado = false;
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  valid = false;
  url: any;

  data2: any = {
    grado: '',
    oficina: '',
    bp: ''
  };
  data: any = {};
  // Config tabla
  grados = [
    {id: 1, name: 'Bajo'},
    {id: 2, name: 'Medio'},
    {id: 3, name: 'Alto'},
    {id: 4, name: 'Inaceptable'}
  ];

  constructor(
    private route: Router,
    private formBuilder: FormBuilder, 
    public snackBar: MatSnackBar, 
    public dialog: MatDialog, 
    public service: ConsultaHitGeolocalizacionService,
    private localStorage: LocalStorageService,
    public userService: UserService,
    public consultaHitsService: ListasControlConsultaHits
  ) {
    this.columnHidingCambio = {hide: false}; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.exportExcelCambio = {enabled: true, fileName: 'Listado de Información'};
    this.CheckboxCambio = {checkboxmode: 'multiple'};  // *Activar con multiple*/parametros de para checkbox
    this.EditingCambio = {allowupdate: false, mode: 'popup'}; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.ColumnchooserCambio = {columnchooser: false}; // parametros de para seleccion de columnas
    this.scrollCambio = {mode: 'standard'};
    this.toolbar = [
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          width: 100,
          text: "Cambiar Nivel de Riesgo",
          onClick: this.changeStatus.bind(this)
        },
        visible: false,
        name: "simple",
      },
    ];
    this.columnasTabla = [
      {
        caption: 'ID Actividad',
        dataField: 'id_actividad',
        hiddingPriority: '0',
        allowEditing: false
      },
      {
        caption: 'Nombre Producto',
        dataField: 'producto',
        hiddingPriority: '0',
        allowEditing: false
      },
      {
        caption: 'BP',
        dataField: 'bp',
        hiddingPriority: '0',
        allowEditing: false
      },
      {
        caption: 'Nombre completo',
        dataField: 'nombre_completo',
        hiddingPriority: '0',
        allowEditing: false
      },
      {
        caption: 'RFC',
        dataField: 'rfc',
        hiddingPriority: '0',
        allowEditing: false
      }, {
        caption: 'CURP',
        dataField: 'curp',
        hiddingPriority: '0',
        allowEditing: false
      },
      {
        caption: 'Fecha de Nacimiento',
        dataField: 'fecha_nacimiento',
        dataType: TiposdeDato.date,
        format: TiposdeFormato.dmy,
        hiddingPriority: '0',
        allowEditing: false
      },
      {
        caption: 'Oficina',
        dataField: 'oficina',
        hiddingPriority: '0',
        allowEditing: false
      },
      {
        caption: 'Tipo de Proceso',
        dataField: 'tipo_proceso',
        hiddingPriority: '0',
        allowEditing: false
      },
      {
        caption: 'Solicitud GR',
        dataField: 'solicitud_GR',
        hiddingPriority: '0',
        allowEditing: false
      },
      {
        caption: 'Grado de Riesgo',
        dataField: 'estatus_riesgo',
        hiddingPriority: '0',
        allowEditing: false
      },
      {
        caption: 'Coincidencia Tabla Inicial',
        dataField: 'lc_anterior',
        hiddingPriority: '0',
        allowEditing: false
      },
      {
        caption: 'Fecha de consulta',
        dataField: 'fecha_consulta',
        hiddingPriority: '0',
        allowEditing: false
      },
      {
        caption: 'Estatus',
        dataField: 'estatus',
        hiddingPriority: '0',
        allowEditing: false
      },
      {
        caption: 'Comentarios',
        dataField: 'comentarios',
        hiddingPriority: '0',
        allowEditing: false
      },
    ];
    //this.obtenerData();
  }

  formControl = new FormControl('', [Validators.required]);

  ngOnInit() {
    this.setFormGroup();
    this.loadData();
  }


  obtenerData() {
    this.encarga = true;
    this.service.get().toPromise().then((res) => {
      this.hits = res.data;
      this.encarga = false;
    }).catch((err) => {
      console.log(err);
    });
  }

  buscar() {
    this.hits = [];

    this.service.get(
      {
        fecha_inicio: this.getFormatDate(new Date(this.formGroup.get('fecha_inicio').value)),
        fecha_fin: this.getFormatDate(new Date(this.formGroup.get('fecha_fin').value))
      }
    ).toPromise().then((res) => {
     
      let data = res.data;
      if(Array.isArray(data)){
        this.hits = res.data;
      }
      this.encarga = false;
    }).catch((err) => {
      console.log(err);
    });
  }

  salvar() {
    if (this.datosPorActualizar.length > 0) {
      console.log('En mantenimiento');
    } else {
      this.snackBar.open('No se ha realizado ningun cambio', 'close');
    }
  }

  getUpdating($event) {

  }

  getFormatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }


  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Campo requerido' : '';
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch {
      }
      this.snackBar.open(msj, 'close');
    }
  }

  setFormGroup(): void {
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl('fecha_inicio', this.formBuilder.control('', null));
    this.formGroup.addControl('fecha_fin', this.formBuilder.control('', null));
  }

  changeStatus($event) {
    const hitSelected = this.datosevent[0]
    this.usuario = this.userService.user.nombre;
    const data = { data: hitSelected, status: this.datosStatus };
    const dialogRef = this.dialog.open(UnlockGeoComponent, {
      data,
      restoreFocus: false,
      autoFocus: false,
    });

    this.subscription.add(dialogRef.afterClosed()
    .subscribe(x => {
      if (x.success) {
        this.buscar();
      }
    }));
  }

  getIsAuthentication() {
    if (!this.userService.getUserLogIn()) this.route.navigate(["./login"]);
  }
  
  loadData() {
    this.consultaHitsService
      .getAllNivelRiesgo()
      .toPromise()
      .then((res) => {
        this.datosStatus = res;
        if (this.datosStatus["Code"] == "404") {
          this.datosStatus = null;
        }
      });
  }
}

