import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { CargaMasivaService } from '@shared/carga-masiva.service';
import { IColumns, IExportExcel, IScroll, Toolbar, IColumnHiding, ICheckbox, IEditing, IColumnchooser } from '@models/grid.interfaces'
import { ActividadEconomica } from '@shared/actividad-economica-service';

@Component({
  selector: 'app-actividad-economica',
  templateUrl: './actividad-economica.component.html',
  styleUrls: ['./actividad-economica.component.scss']
})
export class ActividadEconomicaComponent implements OnInit {
  wait: boolean = false;
  waitFind: boolean = false;
  waitFile: boolean = false;

  datosFind: any;
  datosTable: any;
  datoseventFind: any;

  nombresInput: string = '';
  idT: string = '';

  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,
    public dataService: ActividadEconomica
  ) {
    if (!userService.getUserLogIn()) route.navigate(['./login']);
    this.datosTable = [];
    this.datosFind = [];
    this.nombresInput = '';
    this.idT = '';

    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: 'Catalogo actividad economica' };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: false }; // parametros de para seleccion de columnas

    this.scroll = { mode: 'standard' }; // Parametros de Scroll
    this.columns = [ // Columnasactividad_padre: null
      {
        caption: 'Id',
        dataField: 'id_acteconomica',
        hiddingPriority: '0',
        width: -10
      },
      {
        caption: 'Clave',
        dataField: 'clave',
        hiddingPriority: '0',
        width: 100
      },
      {
        caption: 'Descripcion de actividad',
        dataField: 'descripcion_actividad',
        hiddingPriority: '0',
      },
      {
        caption: 'Riesgo',
        dataField: 'riesgo',
        hiddingPriority: '0',
        width: 100
      },
      {
        caption: 'Nivel Riesgo',
        dataField: 'nivel_riesgo',
        hiddingPriority: '0',
        width: 100
      },
      {
        caption: 'Mandt',
        dataField: 'mandt',
        hiddingPriority: '0',
        width: -10
      },
      {
        caption: 'CNBV',
        dataField: 'cnbv',
        hiddingPriority: '0',
        width: -10
      },
      {
        caption: 'Actividad padre',
        dataField: 'actividad_padre',
        hiddingPriority: '0',
        width: -10
      }
    ];

    this.toolbar = [
      /*{
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Agregar',
          onClick: this.receiveMessageDoc.bind(this, 'add')
        },
        visible: true
      },*/
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Editar',
          onClick: this.receiveMessageDoc.bind(this, 'edt')
        },
        visible: false,
        name: 'simple'
      },
      /*{
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Eliminar',
          onClick: this.receiveMessageDoc.bind(this, 'del')
        },
        visible: false,
        name: 'simple'
      },*/
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 110,
          text: 'Actualizar',
          onClick: this.receiveMessageDoc.bind(this, 'loadD')
        },
        visible: true
      }

    ];
  }

  ngOnInit() {
    this.loadData();
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case 'add':
          this.add();
          break;
        case 'edt':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.upd(d)
          }
          break;
        case 'del':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.del(d)
          }
          break;
        case 'loadD':
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  add() {
    let datos = this.datosTable
    const dialogRef = this.dialog.open(AddActividadEconomica, { data: datos });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.dataService.post(resDialog).toPromise().then((res) => {
          this.loadData();
          this.snackBar.open(`Se ha dado de alta correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch { }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  upd(datos) {
    datos['catalog_padre'] = this.datosTable
    const dialogRef = this.dialog.open(UpdActividadEconomica, { data: datos });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        resDialog.catalog_padre = ''
        this.dataService.put(resDialog).toPromise().then((res) => {
          this.loadData();
          this.snackBar.open(`Se ha actualizado correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch { }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  del(datos) {
    const dialogRef = this.dialog.open(DelActividadEconomicaComponent, { data: datos });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.dataService.delete(resDialog).toPromise().then((res) => {
          this.loadData();
          this.snackBar.open(`Se ha dado de eliminado correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch { }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  loadData() {
    this.wait = true;
    this.dataService.getAll().toPromise().then((res) => {
      this.datosTable = res.datos;
      this.wait = false;
    })
  }

}

@Component({
  selector: 'app-add-actividad-economica',
  templateUrl: './add.component.html',
})
export class AddActividadEconomica {
  data: any;
  act_padre: any;
  nombre = 'Agrega';

  constructor(public dialogRef: MatDialogRef<AddActividadEconomica>, @Inject(MAT_DIALOG_DATA) public data2: any) {
    this.data = {}
    this.act_padre = data2
  }

  formControl = new FormControl('', [Validators.required]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-upd-actividad-economica',
  templateUrl: './add.component.html',
})
export class UpdActividadEconomica {
  nombre = 'Edita '
  act_padre: any;
  constructor(public dialogRef: MatDialogRef<UpdActividadEconomica>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.act_padre = data['catalog_padre']
  }

  formControl = new FormControl('', [Validators.required
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-del-actividad-economica',
  templateUrl: './del.component.html',
})
export class DelActividadEconomicaComponent {
  nombre = 'Elimina ';
  constructor(public dialogRef: MatDialogRef<DelActividadEconomicaComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  formControl = new FormControl('', [Validators.required
  ]);


  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
}