import { MatDateFormats, MAT_NATIVE_DATE_FORMATS } from '@angular/material';

export const FormatDateEsp: MatDateFormats = {
    ...MAT_NATIVE_DATE_FORMATS,
    display: {
        ...MAT_NATIVE_DATE_FORMATS.display,
        dateInput:{
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        } as Intl.DateTimeFormatOptions,
    }
};
