import { Injectable } from "@angular/core";
import { User } from "../models/user.model";
import { environment } from "../../../environments/environment";
import { SecureStorage } from "../utils/secure-storage";
import { LocalStorageService } from "@services/local-storage.service";

@Injectable()
export class UserService {
  public login: boolean;
  public user: User;

  constructor(private storageService: LocalStorageService) {
    this.login = false;
  }

  setUserLogIn(us: any) {
    this.user = us;
    this.setUserDat(true);
  }

  getUserLogIn() {
    let log = false;
    if (!this.login) {
      // Si la sesión NO esta activa
      try {
        this.user = JSON.parse(
          SecureStorage.decrypt(localStorage.getItem("currentUser"))
        ); // voy por los datos de la sesión
      } catch {}
      if (!this.user) {
        this.user = new User();
        this.user.dateLogIn = 1;
        return this.setUserDat(log);
      }
    }

    if (
      this.user.dateLogIn + environment.logTimeMinutes * 1000 * 60 >
      Date.now()
    )
      log = true; // reviso si no han pasado los minutos
    else log = false;
    // actualizo la session
    if (log) this.user.dateLogIn = Date.now();
    return this.setUserDat(log);
  }

  setUserLogOut() {
    this.user = new User();
    this.user.dateLogIn = 1;
    this.setUserDat(false);
  }

  private setUserDat(log: boolean) {
    this.login = log;
    if (log == true)
      localStorage.setItem(
        "currentUser",
        SecureStorage.encrypt(JSON.stringify(this.user))
      );
    else {
      this.storageService.removeByKey("currentUser");
      this.storageService.removeByKey("typeUser");
    }
    return this.login;
  }
}
