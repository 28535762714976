import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SecureStorage } from '../utils/secure-storage';
import { LocalStorageService } from '@services/local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';

@Injectable({
  providedIn: 'root'
})
export class MotivoIngreso {
  private environmentUrl: any = {};
  httpOptions: any;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
    this.environmentUrl = this.localStorageService.getObject(KEYS_STORAGE.url) || {};
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', })
    }
  }

  public getAll = (): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/motivo_ingreso/`;
    return this.http.get(url, this.httpOptions);
  }

  public post = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/motivo_ingreso/`;
    let cUser = JSON.parse(SecureStorage.decrypt(localStorage.getItem('currentUser')));
    body['user'] = cUser.nombre;
    return this.http.post(url, JSON.stringify(body), this.httpOptions);
  }

  public put = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/motivo_ingreso/`;
    let cUser = JSON.parse(SecureStorage.decrypt(localStorage.getItem('currentUser')));
    body['user'] = cUser.nombre;
    return this.http.put(url, JSON.stringify(body), this.httpOptions);
  }

  public delete = (id): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/motivo_ingreso/?identificador=${id}`;
    return this.http.delete(url, this.httpOptions);
  }
}