import { TiposdeDato, TiposdeFormato } from "@models/grid.interfaces";

export const COLUMNS = [
  {
    caption: "Id",
    dataField: "id",
    hiddingPriority: "0",
  },
  {
    caption: "Id_PDLyF",
    dataField: "id_lpdlyf",
    hiddingPriority: "0",
  },
  {
    caption: "Identificador",
    dataField: "identificador",
    hiddingPriority: "0",
  },
  {
    caption: "Nombre Completo",
    dataField: "nombre_completo",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha de Nacimiento",
    dataField: "fecha_nacimiento",
    dataType: TiposdeDato.date,
    format: TiposdeFormato.dmy,
    hiddingPriority: "0",
  },
  {
    caption: "CURP",
    dataField: "curp",
    hiddingPriority: "0",
  },
  {
    caption: "RFC",
    dataField: "rfc",
    hiddingPriority: "0",
  },
  {
    caption: "Ciudad",
    dataField: "ciudad",
    hiddingPriority: "0",
  },
  {
    caption: "Tipo",
    dataField: "tipo",
    hiddingPriority: "0",
  },
  {
    caption: "Tipo de Alerta",
    dataField: "tipo_alerta",
    hiddingPriority: "0",
  },
  {
    caption: "Alerta",
    dataField: "alerta",
    hiddingPriority: "0",
  },
];

export const COLUMNS_HITS = [
  // Columnas
  {
    caption: "ID cliente",
    dataField: "bp",
    hiddingPriority: "0",
  },
  {
    caption: "Nombre completo",
    dataField: "nombres",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha de nacimiento",
    dataField: "fecha_nacimiento",
    dataType: TiposdeDato.date,
    format: TiposdeFormato.dmy,
    hiddingPriority: "0",
  },
  {
    caption: "Fecha detección",
    dataField: "fecha_consulta",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
  {
    caption: "Motivo",
    dataField: "motivo",
    hiddingPriority: "0",
  },
  {
    caption: "Oficina de servicios",
    dataField: "serviceOfficeID",
    hiddingPriority: "0",
  },
  {
    caption: "Actividad económica",
    dataField: "descActi",
    hiddingPriority: "0",
  },
  {
    caption: "Municipalidad/Localidad",
    dataField: "localidad",
    hiddingPriority: "0",
  },
  {
    caption: "Entidad federativa",
    dataField: "estado",
    hiddingPriority: "0",
  },
  {
    caption: "Nombre del producto",
    dataField: "descProd",
    hiddingPriority: "0",
  },
  {
    caption: "Estatus Inicial",
    dataField: "EstatusInicial",
    hiddingPriority: "0",
  },
  {
    caption: "Estatus Autorización",
    dataField: "EstatusAutorizacionInicial",
    hiddingPriority: "0",
  },
  {
    caption: "Usuario que modifica",
    dataField: "UsuarioAprobacionInicial",
    hiddingPriority: "0",
  },
  {
    caption: "Fecha de modificación",
    dataField: "FechaAprobacionInicial",
    dataType: TiposdeDato.datetime,
    format: TiposdeFormato.dmyt,
    hiddingPriority: "0",
  },
];

export const TOOLBAR_HITS = [
  {
    location: "after",
    widget: "dxButton",
    locateInMenu: "auto",
    options: {},
    visible: false,
    name: "simple",
  },
];
