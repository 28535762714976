import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import {IColumns, IExportExcel, IScroll, Toolbar, IColumnHiding, ICheckbox, IEditing, IColumnchooser} from '@models/grid.interfaces';
import { NivelRiesgoService } from '@shared/nivel-riesgo.service';

@Component({
  selector: 'app-config-nivel',
  templateUrl: './config-nivel.component.html',
  styleUrls: ['./config-nivel.component.scss']
})
export class ConfigNivelComponent implements OnInit {

  wait: boolean = false;
  waitFind: boolean = false;
  waitFile: boolean = false;  

  datosFind: any;
  datosTable: any;
  datoseventFind: any;

  nombresInput : string = '';
  idT: string = '' ;

  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,  
    public dataService: NivelRiesgoService,
  ) { 
    if (!userService.getUserLogIn()) route.navigate(['./login']);
    this.datosTable = [];
    this.datosFind = [];
    this.nombresInput = '';
    this.idT = '' ;

    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: 'Listado de Información' };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: false }; // parametros de para seleccion de columnas

    this.scroll = { mode: 'standard' }; // Parametros de Scroll
    this.columns = [ // Columnas
      {
        caption: 'Id',
        dataField: 'id',
        hiddingPriority: '0',
        width: -10
      },
      {
        caption: 'ID PLD',
        dataField: 'id_pld',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre',
        dataField: 'nombre',
        hiddingPriority: '0'
      },
      {
        caption: 'Estatus',
        dataField: 'estatus',
        hiddingPriority: '0',
        width: -10
      },
      {
        caption: 'Valor mínimo',
        dataField: 'valor_minimo',
        hiddingPriority: '0'
      },
      {
        caption: 'Valor Maximo',
        dataField: 'valor_maximo',
        hiddingPriority: '0'
      },
      {
        caption: 'Agregado por',
        dataField: 'usuario',
        hiddingPriority: '0'
      },      
      {
        caption: 'Modificado por',
        dataField: 'usuario_modifica',
        hiddingPriority: '0'
      },      
    ];

    this.toolbar = [
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Editar',
          onClick: this.receiveMessageDoc.bind(this, 'edt')
        },
        visible: false,
        name: 'simple'
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 110,
          text: 'Actualizar',
          onClick: this.receiveMessageDoc.bind(this, 'loadD')
        },
        visible: true
      }
      
    ];
  }

  ngOnInit() {
    this.loadData();
  }

  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case 'add':
          this.add();
          break;
        case 'edt':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.upd(d)
          }
          break;
        case 'del':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.del(d)
          }
          break;
        case 'loadD':
          this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch{ }
      this.snackBar.open(msj, 'close');
    }
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch{ }
      this.snackBar.open(msj, 'close');
    }
  }

  loadData() {
    this.wait = true;
    this.dataService.getAll().toPromise().then((res) => {
      this.wait = false;    
      this.datosTable = res; 
    })
  }

  add() {    
    const dialogRef = this.dialog.open(AddNivelRiesgo, {  });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.dataService.post(resDialog).toPromise().then((res) => {
          this.loadData();
          this.snackBar.open(`Se ha dado de alta correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch{ }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  del(datos) {
    const dialogRef = this.dialog.open(DelNivelRiesgo, { data: datos });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.dataService.delete(resDialog).toPromise().then((res) => {
          this.loadData();
          this.snackBar.open(`Se ha dado de eliminado correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch { }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  upd(datos) {
    const dialogRef = this.dialog.open(UpdNivelRiesgo, { data: datos});
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.dataService.put(resDialog).toPromise().then((res) => {
          this.loadData();
          this.snackBar.open(`Se ha dado de actualizado correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = false;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch{ }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

}

interface Status {
  value: string;
  viewValue: string;
}



@Component({
  selector: 'app-add-listas-control-gris',
  templateUrl: './add.component.html',
})
export class AddNivelRiesgo {
  data: any;
  nombre = 'Agrega ';
  validSum = false;
  actEconomica: any;
  odRecursos: any;
  prodServ: any;
  tipoPersona: any;
  ubicacionGeo: any;
  volOper: any;

  status: Status[] = [
    {value: '0', viewValue: 'Desactivado'},
    {value: '1', viewValue: 'Activado'}
  ];
  
  constructor(public dialogRef: MatDialogRef<AddNivelRiesgo> , @Inject(MAT_DIALOG_DATA) public data2: any ) {
    this.data = {}
   }

  formControl = new FormControl('', [Validators.required]);
  
  sumPonderacion(){
    let min = this.data.ponderacion_min ? parseFloat(this.data.ponderacion_min): 0
    let max = this.data.ponderacion_max ? parseFloat(this.data.ponderacion_max): 0
    if((max + min) > 100 && this.data.ponderacion_min && this.data.ponderacion_max) this.validSum = true 
    else this.validSum = false;
  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }  

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-upd-sedes-balances',
  templateUrl: './add.component.html',
})
export class UpdNivelRiesgo {
  nombre = 'Edita '
  dataProducto : any;
  dataOficinas : any;
  dataServicios : any;
  dataDatosSel : any;
  validSum = false;

  sta: Status[] = [
    {value: '0', viewValue: 'Inactivo'},
    {value: '1', viewValue: 'Activo'}
  ];


  constructor(public dialogRef: MatDialogRef<UpdNivelRiesgo>, @Inject(MAT_DIALOG_DATA) public data: any) 
  { 
    if(data.status == "Activo"){
       data.status = this.sta[1].value
    }
    else{
        data.status = this.sta[0].value
    }
    
  }

  formControl = new FormControl('', [ Validators.required
  ]);

  sumPonderacion(){
    let min = this.data.ponderacion_min ? parseFloat(this.data.ponderacion_min): 0
    let max = this.data.ponderacion_max ? parseFloat(this.data.ponderacion_max): 0
    if((max + min) > 100 && this.data.ponderacion_min && this.data.ponderacion_max) this.validSum = true 
    else this.validSum = false;
  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}

@Component({
  selector: 'del-giro-actividad',
  templateUrl: './del.component.html',
})
export class DelNivelRiesgo {
  nombre = 'Elimina ';
  constructor(public dialogRef: MatDialogRef<DelNivelRiesgo>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  formControl = new FormControl('', [Validators.required
  ]);


  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}