import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';
import { KEYS_STORAGE } from '@constants/KeysStorage';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private httpOptions: any
  private environmentUrl: any = {};

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
    this.environmentUrl = this.localStorage.getObject(KEYS_STORAGE.url) || {};
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }

  public postAuth = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.authUrl}/Autenticacion`;
    return this.http.post(url, JSON.stringify(body), this.httpOptions);
  }

  public postAuthSrv = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/AutenticacionSRV`;
    return this.http.post(url, JSON.stringify(body), this.httpOptions);
  }

  public postPerfil = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/perfilamiento`;
    return this.http.post(url, JSON.stringify(body), this.httpOptions);
  }

  public postPerfilExterno = (body: any): Observable<any> => {
    const url = `${this.environmentUrl.listasControlUrl}/perfilamientoexterno`;
    return this.http.post(url, JSON.stringify(body), this.httpOptions);
  }

  getPermissions(profile: string): Observable<any> {
    const url = `${this.environmentUrl.listasControlUrl}/api/v1-0/Perfilamiento/${profile}`;
    return this.http.get(url);
  }
}
