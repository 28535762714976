import * as moment from 'moment';

const formatDMY = "DD-MM-YYYY";
const formatYMD = "YYYY-MM-DD";
const formatDMYT = "DD-MM-YYYY HH:mm:ss";
const formatYMDT = "YYYY-MM-DD HH:mm:ss";
const formatComplete = "ddd, DD MMM YYYY HH:mm:ss [GMT]";

export const formatDateTime = (value: string, defaultFormat = formatYMDT, defaultValue = '') => {
  let dateTime = defaultValue;
  if (moment(value, formatDMYT, true).isValid()) {
    dateTime = moment(value, formatDMYT).format(defaultFormat);
  } else if (moment(value, formatYMDT, true).isValid()) {
    dateTime = moment(value, formatYMDT).format(defaultFormat);
  } else if (moment(value, formatComplete, true).isValid()) {
    dateTime = moment(value, formatComplete).format(defaultFormat);
  }
  return dateTime;
}

export const formatDate = (value: string, defaultFormat = formatYMD, defaultValue = '') => {
  let date = defaultValue;
  if (moment(value, formatDMY, true).isValid()) {
    date = moment(value, formatDMY).format(defaultFormat);
  } else if (moment(value, formatYMD, true).isValid()) {
    date = moment(value, formatYMD).format(defaultFormat);
  }
  return date;
}