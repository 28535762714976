import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { IColumns, IExportExcel, IScroll, Toolbar, IColumnHiding, ICheckbox, IEditing, IColumnchooser, TiposdeDato, TiposdeFormato } from '@models/grid.interfaces';
import { GradoRiesgoService } from '@shared/grado-riesgo.service';

@Component({
  selector: 'app-grado-riesgo',
  templateUrl: './grado-riesgo.component.html',
  styleUrls: ['./grado-riesgo.component.scss']
})
export class GradoRiesgoComponent implements OnInit {
  wait: boolean = false;
  waitFind: boolean = false;
  waitFile: boolean = false;
  listaProdServ: any;
  datosFind: any;
  datosTable: any;
  datoseventFind: any;
  nombresInput: string = '';
  idT: string = '';
  totalSum: any = 0;


  tipo_persona_a
  tipo_persona_b
  actividad_economica_a
  actividad_economica_b
  ubicacion_geografica_a
  ubicacion_geografica_b
  productos_servicios_a
  productos_servicios_b
  volumen_operacion_a
  volumen_operacion_b
  origen_destino_a
  origen_destino_b
  alto_a
  alto_b
  mediano_a
  mediano_b
  bajo_a
  bajo_b





  /* *************** Parametros del Grid de datos ***************** */
  columns: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,
    public dataService: GradoRiesgoService,
  ) {
    if (!userService.getUserLogIn()) route.navigate(['./login']);
    this.datosTable = [];
    this.datosFind = [];
    this.nombresInput = '';
    this.idT = '';

    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: false, fileName: 'Listado de Información' };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: 'none' };  // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: false }; // parametros de para seleccion de columnas

    this.scroll = { mode: 'standard' }; // Parametros de Scroll
    this.columns = [ // Columnas
      {
        caption: 'Id',
        dataField: 'id',
        hiddingPriority: '1'
      },
      {
        caption: 'Factor riesgo',
        dataField: 'factores_riesgo',
        hiddingPriority: '0'
      },
      {
        caption: 'Ponderación(%)',
        dataField: 'ponderacion_porcentaje',
        hiddingPriority: '0'
      },
      {
        caption: 'Usuario',
        dataField: 'usuariomodificacion',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha',
        dataField: 'fechamodificacion',
        dataType: TiposdeDato.datetime,
        format: TiposdeFormato.dmyt,
        hiddingPriority: '0'
      },
      {
        caption: 'Comentario',
        dataField: 'comentario',
        hiddingPriority: '0'
      }
    ];

    this.toolbar = [
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Editar',
          onClick: this.receiveMessageDoc.bind(this, 'actualizar')
        },
        visible: true
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Historico',
          onClick: this.receiveMessageDoc.bind(this, 'historico')
        },
        visible: true
      }
    ];
  }

  ngOnInit() {
    this.loadData();
  }

  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case 'actualizar':
          this.add();
          break;
        case 'historico':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            // this.upd(d)
          }
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  loadData() {
    this.wait = true;
    this.dataService.getAll().toPromise().then((res) => {
      this.wait = false;
      this.datosTable = res;
      for (let element of res) {
        this.totalSum = this.totalSum + parseFloat(element.ponderacion_porcentaje)
      }
    })
  }

  add() {
    let datos = { sum: this.totalSum }
    const dialogRef = this.dialog.open(AddFactoresRiesgo, { data: this.datosTable });
    dialogRef.afterClosed().subscribe(resDialog => {
      let usuarioLogged = this.userService.user.nombre
      if (resDialog) {
        let promisesArray = [];
        resDialog.factores.map(item => {
          promisesArray.push(new Promise((resolve, reject) => {
            item.usuario = usuarioLogged
            item.comentario = resDialog.comentarios
            this.dataService.put(item).toPromise().then(() => {
              resolve(true)
            }).catch(() => {
              reject(false)
            })
          }))
        });
        Promise.all(promisesArray).then(() => {
          this.loadData();
          this.snackBar.open(`Se han actualizado correctamente los factores`, 'ok', { duration: 2500 });
        }).catch(() => {
          this.wait = false;
          this.snackBar.open('Ocurrio un error al guardar los factores', 'close');
        })
      }
    });
  }

  // upd(datos) {
  //   datos['sum'] = this.totalSum - parseFloat(datos.ponderacion_porcentaje);
  //   const dialogRef = this.dialog.open(UpdFactoresRiesgo, { data: datos });
  //   dialogRef.afterClosed().subscribe(resDialog => {
  //     if (resDialog) {
  //       this.dataService.put(resDialog).toPromise().then((res) => {
  //         this.loadData();
  //         this.snackBar.open(`Se ha dado de actualizado correctamente`, 'ok', { duration: 2500 });
  //       }).catch((err) => {
  //         this.wait = false;
  //         let msj = err.message;
  //         try {
  //           msj = err.error.error.message + '\n' + err.error.text;
  //         } catch { }
  //         this.snackBar.open(msj, 'close');
  //       });
  //     }
  //   });
  // }

}

interface Status {
  value: string;
  viewValue: string;
}



@Component({
  selector: 'app-add-listas-control-gris',
  templateUrl: './add.component.html',
})
export class AddFactoresRiesgo {
  data: any;
  nombre = 'Agrega ';
  validSum = true;
  actEconomica: any;
  odRecursos: any;
  prodServ: any;
  tipoPersona: any;
  ubicacionGeo: any;
  volOper: any;
  sum: number;
  comentarios;

  status: Status[] = [
    { value: '0', viewValue: 'Desactivado' },
    { value: '1', viewValue: 'Activado' }
  ];

  constructor(public dialogRef: MatDialogRef<AddFactoresRiesgo>, @Inject(MAT_DIALOG_DATA) public data2: any) {
    this.sum = data2.reduce((sum, current) => sum + Number(current.ponderacion_porcentaje), 0);
    this.data = {};
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  validarDatos() {
    if (this.data2.reduce((sum, current) => sum + Number(current.ponderacion_porcentaje), 0) == 100) {
      this.validSum = true;
      this.dialogRef.close({ factores: this.data2, comentarios: this.comentarios });
    } else {
      this.validSum = false;
    }
  }
}

@Component({
  selector: 'app-upd-sedes-balances',
  templateUrl: './add.component.html',
})
export class UpdFactoresRiesgo {
  nombre = 'Edita '
  dataProducto: any;
  dataOficinas: any;
  dataServicios: any;
  dataDatosSel: any;
  validSum = false;
  sum = 0;
  comentarios;

  sta: Status[] = [
    { value: '0', viewValue: 'Inactivo' },
    { value: '1', viewValue: 'Activo' }
  ];


  constructor(public dialogRef: MatDialogRef<UpdFactoresRiesgo>, @Inject(MAT_DIALOG_DATA) public data2: any) {
    if (data2.status == "Activo") {
      data2.status = this.sta[1].value
    }
    else {
      data2.status = this.sta[0].value
    }
    this.sum = data2['sum'];
  }

  // formControl = new FormControl('', [Validators.required
  // ]);

  sumPonderacion() {
    let val = this.data2.ponderacion_porcentaje ? parseFloat(this.data2.ponderacion_porcentaje) : 0;
    let total = this.sum + val
    this.validSum = total > 100 ? true : false;
  }

  validarDatos() {
    if (this.data2.reduce((sum, current) => sum + Number(current.ponderacion_porcentaje), 0) == 100) {
      this.validSum = true;
      this.dialogRef.close({ factores: this.data2, comentarios: this.comentarios });
    } else {
      this.validSum = false;
    }
  }

  getErrorMessage() {
    // return this.formControl.hasError('required') ? 'Required field' : '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'del-giro-actividad',
  templateUrl: './del.component.html',
})
export class DelFactoresRiesgo {
  nombre = 'Elimina ';
  constructor(public dialogRef: MatDialogRef<DelFactoresRiesgo>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  formControl = new FormControl('', [Validators.required
  ]);


  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}