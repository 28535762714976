import { Component, OnInit, Inject } from '@angular/core';
import { IColumns, IExportExcel, IScroll, Toolbar, IColumnHiding, ICheckbox, IEditing, IColumnchooser } from '@models/grid.interfaces';
import { MatSnackBar } from '@angular/material';
import { UserService } from '@shared/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { CargaMasivaService } from '@shared/carga-masiva.service';
import { ListasControlConsultaHits } from '@shared/listas-control-consulta-hits.service';


@Component({
  selector: 'app-listas-control-consulta-hits',
  templateUrl: './listas-control-consulta-hits.component.html',
  styleUrls: ['./listas-control-consulta-hits.component.scss']
})
export class ListasControlConsultaHitsComponent implements OnInit {

  wait: boolean = true;
  datosHits: any;
  datosLC: any;
  datosNivelRiesgo: any;
  datosStatus: any;
  listaActEconomica: any;
  listaTipoPersona: any;
  listaProdServ: any;
  listaODRecursos: any;
  listaUbicacionGeo: any;
  listaVolOper: any;
  getErrorMessage: any;

  columns: IColumns[];
  toolbar: Toolbar[];
  exportExcel: IExportExcel;
  columnHiding: IColumnHiding;
  Checkbox: ICheckbox;
  Editing: IEditing;
  Columnchooser: IColumnchooser;
  scroll: IScroll;
  datosevent: any;
  onExportingFun: Function;
  onExportedFun: Function;

  select_datosLC: any;


  constructor(public dialog: MatDialog,
    private route: Router,
    public snackBar: MatSnackBar,
    public _route: ActivatedRoute,
    public userService: UserService,
    public cargaMasiva: CargaMasivaService,
    public consultaHitsService: ListasControlConsultaHits) {
    this.datosHits = [];
    this.wait = true;
    this.getIsAuthentication();
    this.paramsOfGridDataInitial();
  }

  ngOnInit() {
    this.wait = true;
    this.datosHits = null;
    this.datosLC = [];
    this.datosNivelRiesgo = [];
    this.getAllNameLC();
    this.getAllNivelRiesgo();
    this.getAllFactoresRiesgo();
    this.getAllStatus();
  }

  getIsAuthentication() {
    if (!this.userService.getUserLogIn()) this.route.navigate(['./login']);

  }

  paramsOfGridDataInitial() {
    //Grid de Datos para mostrar Hits
    /* *************** Parametros del Grid de datos ***************** */
    this.exportExcel = { enabled: true, fileName: 'Listado de Información' };
    this.columnHiding = { hide: true }; // Parametros de columnas responsivas en caso de no usar hidding priority
    this.Checkbox = { checkboxmode: 'multiple' };  // *Activar con multiple*/parametros de para checkbox
    this.Editing = { allowupdate: false }; // parametros de para editar grid *cambiar a batch para editar varias celdas a la vez*/
    this.Columnchooser = { columnchooser: true }; // parametros de para seleccion de columnas

    this.scroll = { mode: 'standard' }; // Parametros de Scroll
    this.columns = [ // Columnas
      {
        caption: 'Id',
        dataField: 'id',
        hiddingPriority: '0'
      },
      {
        caption: 'Sistema',
        dataField: 'sistema',
        hiddingPriority: '0'
      },
      {
        caption: 'Tabla Consultada',
        dataField: 'tabla_consultada',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha de Consulta',
        dataField: 'fecha_consulta',
        hiddingPriority: '0'
      },
      {
        caption: 'Nombre Completo',
        dataField: 'nombres',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha Nacimiento',
        dataField: 'fecha_nacimiento',
        hiddingPriority: '0'
      },
      {
        caption: 'CURP',
        dataField: 'curp',
        hiddingPriority: '0'
      },
      {
        caption: 'RFC',
        dataField: 'rfc',
        hiddingPriority: '0'
      },
      {
        caption: 'Nivel Riesgo',
        dataField: 'nivelRiesgo',
        hiddingPriority: '0'
      },
      {
        caption: 'Riesgo',
        dataField: 'estatusRiesgo',
        hiddingPriority: '0'
      },
      {
        caption: 'Estatus Inicial',
        dataField: 'EstatusInicial',
        hiddingPriority: '0'
      },
      {
        caption: 'Usuario de Aprobacion Inicial',
        dataField: 'UsuarioAprobacionInicial',
        hiddingPriority: '0'
      },
      {
        caption: 'Fecha Aprobacion Inicial',
        dataField: 'FechaAprobacionInicial',
        hiddingPriority: '0'
      },

      {
        caption: 'Estatus Semestral',
        dataField: 'EstatusSemestral',
        hiddingPriority: '0'
      }
    ];

    this.toolbar = [
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 100,
          text: 'Cambiar Nivel de Riesgo',
          onClick: this.receiveMessageDoc.bind(this, 'change_status')
        },
        visible: false,
        name: 'simple'
      },
      {
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          width: 90,
          text: 'Autorizar',
          onClick: this.receiveMessageDoc.bind(this, 'autorizar')
        },
        visible: false,
        name: 'simple'
      },
    ];
  }

  receiveMessageDoc($event) {
    try {
      switch ($event) {
        case 'change_status':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            this.upd_nivel_riesgo(d, this.datosNivelRiesgo)
          }
          break;
        case 'autorizar':
          if (this.datosevent && this.datosevent.length > 0) {
            let d = this.datosevent[0];
            
            this.aut_registro(d)
          }
          break;
        case 'loadD':
          //this.loadData();
          break;
      }
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  getAllNameLC() {
    this.wait = true;
    this.datosLC = null;
    this.consultaHitsService.getAllNameLC().toPromise().then((data: any) => {
      this.wait = false;
      this.datosLC = data;
    }).catch((err) => {
      this.wait = true;
      this.datosLC = null;
    });
  }

  getAllStatus() {
    this.wait = true;
    this.datosStatus = null;
    this.consultaHitsService.getStatus().toPromise().then((data: any) => {
      this.wait = false;
      this.datosStatus = data;
    }).catch((err) => {
      this.wait = true;
      this.datosStatus = null;
    });
  }

  getAllFactoresRiesgo() {
    this.listaTipoPersona = null;
    this.consultaHitsService.gettipo_persona().toPromise().then((data: any) => {
      this.listaTipoPersona = data;
    }).catch((err) => {
      this.listaTipoPersona = null;
    });

    this.listaProdServ = null;
    this.consultaHitsService.getProd_serv().toPromise().then((data: any) => {
      this.listaProdServ = data;
    }).catch((err) => {
      this.listaProdServ = null;
    });

    this.listaODRecursos = null;
    this.consultaHitsService.getOdRecursos().toPromise().then((data: any) => {
      this.listaODRecursos = data;
    }).catch((err) => {
      this.listaODRecursos = null;
    });

    this.listaUbicacionGeo = null;
    this.consultaHitsService.getUbicacionGeo().toPromise().then((data: any) => {
      this.listaUbicacionGeo = data;
    }).catch((err) => {
      this.listaUbicacionGeo = null;
    });

    this.listaVolOper = null;
    this.consultaHitsService.getVolOperacion().toPromise().then((data: any) => {
      this.listaVolOper = data;
    }).catch((err) => {
      this.listaVolOper = null;
    });

    this.listaActEconomica = null;
    this.consultaHitsService.getActEconomica().toPromise().then((data: any) => {
      this.listaActEconomica = data;
    }).catch((err) => {
      this.listaActEconomica = null;
    });

  }

  getAllNivelRiesgo() {
    this.datosNivelRiesgo = null;
    this.consultaHitsService.getAllNivelRiesgo().toPromise().then((data: any) => {
      this.datosNivelRiesgo = data;
    }).catch((err) => {
      this.datosNivelRiesgo = null;
    });
  }

  loadListaHits(lista: string) {
    this.wait = true;
    this.consultaHitsService.getHits(lista).toPromise().then((res) => {
      this.wait = false;
      this.datosHits = res;
      if (this.datosHits['Code'] == "404") {
        this.datosHits = null;
      }
    })
  }

  datosMessage($event) {
    try {
      this.datosevent = $event.data;
    } catch (err) {
      let msj = err.message;
      try {
        msj = err.error.error.message + '\n' + err.error.text;
      } catch { }
      this.snackBar.open(msj, 'close');
    }
  }

  upd_nivel_riesgo(datos, lnivel_riesgo) {
    datos = [datos, lnivel_riesgo, this.listaActEconomica, this.listaODRecursos, this.listaProdServ, this.listaTipoPersona, this.listaUbicacionGeo, this.listaVolOper, this.userService.user];
    const dialogRef = this.dialog.open(UpdListHitsComponent, { data: datos });
    dialogRef.afterClosed().subscribe(resDialog => {
      if (resDialog) {
        this.consultaHitsService.postUpdNivelRiesgo(resDialog).toPromise().then((res) => {
          this.loadListaHits(this.select_datosLC);
          this.snackBar.open(`Se ha actualizado correctamente`, 'ok', { duration: 2500 });
        }).catch((err) => {
          this.wait = true;
          let msj = err.message;
          try {
            msj = err.error.error.message + '\n' + err.error.text;
          } catch { }
          this.snackBar.open(msj, 'close');
        });
      }
    });
  }

  aut_registro(datos) {
    datos = [datos, this.datosStatus, this.userService.user];
    const dialogRef = this.dialog.open(AutListHitsComponent, { data: datos });
    dialogRef.afterClosed().subscribe(resDialog => {
      /*this.dataAut.idAutIniNuevo = this.dataAut.idAutorizacionInicial;
        this.dataAut.idAutIniSemes = this.dataAut.idAutorizacionSemestral;*/

      if (resDialog) {
        
        if (resDialog['IdEstatusInicial'] != resDialog['idAutIniNuevo']) {
          //Actulizamos aprobaciones inicial
          for (var rs of this.datosStatus) {
            if (rs.idStatus == resDialog['idAutIniNuevo']) {
              resDialog['EstatusAutorizacionInicial'] = rs.status;
            }
          }
          this.consultaHitsService.putUpdAprobacionesInicial(resDialog).toPromise().then((res) => {
            this.loadListaHits(this.select_datosLC);
            this.snackBar.open(`Se ha actualizado correctamente`, 'ok', { duration: 2500 });
          }).catch((err) => {
            this.wait = true;
            let msj = err.message;
            try {
              msj = err.error.error.message + '\n' + err.error.text;
            } catch { }
            this.snackBar.open(msj, 'close');
          });
        }
        if (resDialog['IdEstatusSemestral'] != resDialog['idAutSemesNuevo']) {
          //Actulizamos aprobaciones semestral
          for (var rs of this.datosStatus) {
            if (rs.idStatus == resDialog['idAutSemesNuevo']) {
              resDialog['EstatusAutorizacionSemestral'] = rs.status;
            }
          }
          this.consultaHitsService.putUpdAprobacionesSemestral(resDialog).toPromise().then((res) => {
            this.loadListaHits(this.select_datosLC);
            this.snackBar.open(`Se ha actualizado correctamente`, 'ok', { duration: 2500 });
          }).catch((err) => {
            this.wait = true;
            let msj = err.message;
            try {
              msj = err.error.error.message + '\n' + err.error.text;
            } catch { }
            this.snackBar.open(msj, 'close');
          });
        }
      }
    });
  }

}


@Component({
  selector: 'app-upd-listas-control-bloq',
  templateUrl: './update.component.html',
})
export class UpdListHitsComponent {

  nombre = 'Edita ';
  col: any = "primary";
  dataNivelRiesgo: any;
  param_consulta: any;
  min: any;
  max: any;
  dataUpd: any;
  actEconomica: any;
  odRecursos: any;
  prodServ: any;
  tipoPersona: any;
  ubicacionGeo: any;
  volOper: any;
  p_actEconomica: any;
  p_odRecursos: any;
  p_prodServ: any;
  p_tipoPersona: any;
  p_ubicacionGeo: any;
  p_volOper: any;
  cantidad: any;
  vertical: any;



  constructor(public dialogRef: MatDialogRef<UpdListHitsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    //datos = [ this.listaTipoPersona, this.listaUbicacionGeo, this.listaVolOper];
    this.dataUpd = data[0];
    this.dataNivelRiesgo = data[1];
    this.param_consulta = JSON.parse(data[0]['param_consul']);
    this.actEconomica = data[2];
    this.odRecursos = data[3];
    this.prodServ = data[4];
    this.tipoPersona = data[5];
    this.ubicacionGeo = data[6];
    this.volOper = data[7];
    this.dataUpd.option = 0;
    this.dataUpd.user = data[8].nombre;

    for (var actE of this.actEconomica) {
      if (this.param_consulta.actividadEconomica == actE.clave) {
        this.dataUpd.actEconomica = actE.clave;
      }
    }

    for (var odR of this.odRecursos) {
      if (this.param_consulta.origenRecursos == odR.id) {
        this.dataUpd.origenRecursos = odR.id;
      }
    }

    for (var prodS of this.prodServ) {
      if (this.param_consulta.productosServicios == prodS.id) {
        this.dataUpd.productosServicios = prodS.id;
      }
    }

    for (var tp of this.tipoPersona) {
      if (this.param_consulta.tipoPersona == tp.id) {
        this.dataUpd.tipoPersona = tp.id;
      }
    }

    for (var ubiG of this.ubicacionGeo) {
      if (this.param_consulta.ubicacionGeografica == ubiG.id) {
        this.dataUpd.ubicacionGeografica = ubiG.id;
      }
      if (ubiG.descripcion.includes(this.param_consulta.ubicacionGeografica)) {
        this.dataUpd.ubicacionGeografica = ubiG.id;
      }
    }
    //this.dataUpd.volumenOperacion = formatCurrency(this.param_consulta.volumenOperacion, 'en-US', getCurrencySymbol('USD', 'wide'))
    this.dataUpd.volumenOperacion = this.param_consulta.volumenOperacion;

    this.dataUpd.paramConsulta = '{"actividadEconomica":"' + this.dataUpd.actEconomica + '","ubicacionGeografica":"' + this.dataUpd.ubicacionGeografica
      + '","tipoPersona":"' + this.dataUpd.tipoPersona + '","origenRecursos":"' + this.dataUpd.origenRecursos + '","volumenOperacion":"' + this.dataUpd.volumenOperacion
      + '","productosServicios":"' + this.dataUpd.productosServicios + '"}';

    this.getValMinMax();
    this.get_valores_vol();
    this.dataUpd.nivelRiesgoAntes = data[0].nivelRiesgo;
    this.dataUpd.nivelRiesgo2 = data[0].nivelRiesgo;


  }

  formControl = new FormControl('', [Validators.required
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }

  getGradoRiesgo(grado: any) {
    for (var nivel of this.dataNivelRiesgo) {
      if (grado >= nivel['valor_minimo'] && grado <= nivel['valor_maximo']) {
        this.dataUpd['estatusRiesgo'] = nivel['nombre'];
        return nivel['nombre'];
      }
    }
  }

  getValMinMax() {
    var lista = [];
    for (var num of this.dataNivelRiesgo) {
      lista.push(num['valor_minimo']);
      lista.push(num['valor_maximo']);
    }
    this.max = Math.max.apply(null, lista);
    this.min = Math.min.apply(null, lista);
  }


  get_valores_vol() {
    let volumen = this.dataUpd.volumenOperacion;
    let valor_riesgo = 0;
    for (var r of this.volOper) {
      let txt = r['propuesto'];
      let cantidades = txt.match(/(\$+([0-9]*[,]*[0-9]*[.]*[0-9]*)*)/g);
      let lc = [];
      for (var cant of cantidades) {
        lc.push(cant.replace('$', ""));
      }
      let signos = txt.match(/[><=]+[=]*/g);
      if (lc.length == signos.length) {
        let lvalue_bool = [];
        for (var i = 0; i < lc.length; i++) {
          let sig = signos[i];
          if (signos[i] == '=') {
            sig = '>=';
          }
          if (signos[i] == '=>') {
            sig = '>=';
          }
          if (signos[i] == '=<') {
            sig = '<=';
          }
          lvalue_bool.push(eval(parseFloat(volumen) + sig + parseFloat(lc[i].replace(',', ''))));
        }

        if (lvalue_bool.length > 0) {
          let val_bandera = lvalue_bool[0];
          for (var i = 1; i < lvalue_bool.length; i++) {
            val_bandera = val_bandera && lvalue_bool[i];
          }
          if (val_bandera) {
            valor_riesgo = r['valor_riesgo'];
            return valor_riesgo;
          }
        }
      }
    }

    return valor_riesgo;
  }

  calcula_nuevo_total_riesgo() {
    //Volumen
    let valor_vol = this.get_valores_vol();
    //Tipo Persona
    let valor_tipo = this.Get_valor_id(this.tipoPersona, this.dataUpd.tipoPersona);
    //Actividad Economica
    let valor_actEco = this.Get_valor_clave(this.actEconomica, this.dataUpd.actEconomica);
    //Ubicacion
    let valor_ubicacion = this.Get_valor_id(this.ubicacionGeo, this.dataUpd.ubicacionGeografica);
    //Ubicacion
    let valor_odRecursos = this.Get_valor_id(this.odRecursos, this.dataUpd.origenRecursos);
    //Ubicacion
    let valor_prodServ = this.Get_valor_id(this.prodServ, this.dataUpd.productosServicios);
    let total = 0;
    total += valor_tipo * 5 / 100;
    total += valor_actEco * 25 / 100;
    total += valor_ubicacion * 20 / 100;
    total += valor_prodServ * 15 / 100;
    total += valor_odRecursos * 15 / 100;
    total += valor_vol * 20 / 100;
    this.dataUpd.nivelRiesgo2 = total;
    this.dataUpd.paramConsulta = '{"actividadEconomica":"' + this.dataUpd.actEconomica + '","ubicacionGeografica":"' + this.dataUpd.ubicacionGeografica
      + '","tipoPersona":"' + this.dataUpd.tipoPersona + '","origenRecursos":"' + this.dataUpd.origenRecursos + '","volumenOperacion":"' + this.dataUpd.volumenOperacion
      + '","productosServicios":"' + this.dataUpd.productosServicios + '"}';
    return (total);

  }

  Get_valor_id(records: any, p_id) {
    let valor = 0
    for (var r of records) {
      if (r['id'] == p_id) {
        valor = r['valor_riesgo']
        return valor;
      }
    }
    return valor;

  }

  Get_valor_clave(records, p_clave) {
    let valor = 0
    for (var r of records) {
      if (r['clave'] == p_clave) {
        valor = r['valor_riesgo']
        return valor;
      }
    }
    return valor;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-aut-listas-control-bloq',
  templateUrl: './aut.component.html',
})
export class AutListHitsComponent {
  dataAut: any;
  dataStatus: any;
  nombre = 'Autorización ';
  option = 0;
  inicial = false;
  semes = false;

  constructor(public dialogRef: MatDialogRef<AutListHitsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dataAut = data[0];
    this.dataStatus = data[1];
    this.dataAut.user = data[2].nombre;
    this.dataAut.estatusAutIni = null;
    this.dataAut.estatusAutSemes = null;


    if (this.dataAut.IdEstatusSemestral != null) {
      this.semes = true;
      this.dataAut.idAutSemesNuevo = this.dataAut.idAutorizacionSemestral;
      if (this.dataAut.idAutorizacionSemestral == null) {
        this.dataAut.idAutSemesNuevo = this.dataAut.IdEstatusSemestral;
      }
    } else {
      this.dataAut.idAutSemesNuevo = this.dataAut.IdEstatusSemestral;

    }

    if (this.dataAut.IdEstatusInicial != null) {
      this.inicial = true;
      this.dataAut.idAutIniNuevo = this.dataAut.idAutorizacionInicial;
      if (this.dataAut.idAutorizacionInicial == null) {
        this.dataAut.idAutIniNuevo = this.dataAut.IdEstatusInicial;
      }
    } else {
      this.dataAut.idAutIniNuevo = this.dataAut.IdEstatusInicial;
    }

  }

  formControl = new FormControl('', [Validators.required]);


  onNoClick(): void {
    this.dialogRef.close();
  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }

}
